import authTypes from "../types/auth";
// import { config } from "../../config";

export const initialState = {
  logindata: "",
  signUpUser: null,
  signUpUserSuccess: null,
  signUpUserFailure: null,
  acceptInvitation: null,
  acceptInvitationSuccess: null,
  acceptInvitationFailure: null,
  forgetpassword: null,
  forgetPasswordSuccess: null,
  forgetPasswordFailure: null,
  resetpassword: null,
  resetpasswordSuccess: null,
  resetpasswordFailure: null,
  recruiterList: [],
  candidatelist: [],
  addrecruiter: {},
  editrecruiter: {},
  addcandidate: {},
  editcandidate: {},

  apiError: "",
  isauthLoading: false,
  token: "",
  codingTheme: {
    theme: "vs-dark",
    themeId: 1,
  },
  codingLang: {
    cid: 10,
    lang: "java",
    langId: 10,
  },
  resumeUrl: "",
  resumeName: "",
  resumeSize: 0,
  resumeScore: 0,
  resumeUrlError: {},
  leve: null,
  candidatedetails: {},
  candidatedetailsFailure: null,
  recruiterdetails: {},
  recruiterdetailsFailure: null,
  userExists: false,
  candidateDeactive: null,
  candidateActive: null,
  recruiterDeactive: null,
  recruiteractive: null,
  recruiterfilter: {
    addedBy: [],
    status: ["active", "inactive", "invited"],
    recruiterType: [
      { key: "Contributor", value: "Contributor" },
      { key: "Lead", value: "Lead" },
      { key: "Team Admin", value: "TeamAdmin" },
    ],
  },
  recruiterlistTotal: 0,
  candidateListTotal: [],
  candidateListMyTotal: 0,
  candidateListAllTotal: 0,
  recruiterdashboard: [],
  candidatefilter: {
    createdBy: [],
    degree: ["Bachelors", "Masters", "PhD", "Other"],
    skills: [],
    status: ["active", "inactive"],
    yoe: ["0-3 Years", "4-6 Years", "7 Years & Above", "Unknown"],
  },
  uploadresume: [],
  searchByEmail: [],
  resumeremove: [],
  downloadresume: [],
  areaOfStrengthsAndImprovements: {},
  candidateDPUrl: null,
  fetchedResumeDetails: null,
  uploadprofile: [],
  logoDetails: { logo: "", displayName: "" },
  checkInvitationData: {},
  checkValidationData: {},
  checkResetPasswordTokenData: {},
  createUserPasswordSuccessData: {},
  createPasswordSuccessData: {},
  acceptValidation: null,
  acceptValidationSuccess: null,
  acceptValidationFailure: null,
  resendInvitation: null,
  resendInvitationSuccess: null,
  resendInvitationFailure: null,
  // checkEntityStatusData: {},
  checkSubscriptionStatusCandidateData: {},
  checkSubscriptionStatusInviteData: {},
  checkSubscriptionStatusRoleData: {},
  checkSubscriptionStatusUserData: {},
  changePassword: null,
  changePasswordSuccess: false,
  changePasswordFailure: null,
  signUpPlans: null,
  signUpPlansSuccess: null,
  signUpPlansFailure: null,
  createPaymentLink: null,
  createPaymentLinkSuccess: {},
  createPaymentLinkFailure: null,
  managementDetails: null,
  managementDetailsSuccess: {},
  managementDetailsFailure: null,
  updateOrgDetails: null,
  updateOrgDetailsSuccess: false,
  updateOrgDetailsFailure: null,
  updateUserDetails: null,
  updateUserDetailsSuccess: false,
  updateUserDetailsFailure: null,
  resendEmailValidation: null,
  resendEmailValidationSuccess: null,
  resendEmailValidationFailure: null,
  creatCheckoutSession: null,
  creatCheckoutSessionSuccess: null,
  creatCheckoutSessionFailure: null,
  createBillingPortal: null,
  createBillingPortalSuccess: null,
  createBillingPortalFailure: null,
  deleteInvitedUser: null,
  deleteInvitedUserSuccess: null,
  deleteInvitedUserFailure: null,
  BillingPagePlans: null,
  BillingPagePlansSuccess: null,
  BillingPagePlansFailure: null,
  apiBillingPortalForInvoiceHistory: null,
  apiBillingPortalForInvoiceHistorySuccess: null,
  apiBillingPortalForInvoiceHistoryFailure: null,
  invoiceHistoryList: null,
  invoiceHistoryListSuccess: null,
  invoiceHistoryListFailure: null,
  getIpAddress: null,
  getIpAddressSuccess: null,
  getIpAddressFailure: null,
  skillsConfig: {},
  getConfigSkills: {},
  getConfigSkillsFailure: {},
  bookademo: null,
  bookademoSuccess: null,
  bookademoFailure: null,
  editAndAddResume: null,
  editAndAddResumeSuccess: null,
  editAndAddResumeFailure: null,
  addCompletedResume: null,
  addCompletedResumeSuccess: null,
  addCompletedResumeFailure: null,

  uploadBulkResume: null,
  uploadBulkResumeSuccess: [],
  uploadBulkResumeFailure: null,
  getPendingResumeList: null,
  getPendingResumeListSuccess: null,
  getPendingResumeListFailure: null,
  deleteResumeDoc: null,
  deleteResumeDocSuccess: null,
  deleteResumeDocFailure: null,
  matchCandidates: null,
  matchCandidatesSuccess: null,
  matchCandidatesFailure: null,
  closeInvite: null,
  closeInviteSuccess: null,
  closeInviteFailure: null,
  candidateRoleListForNewInvite: null,
  candidateRoleListForNewInviteSuccess: null,
  candidateRoleListForNewInviteFailure: null,
  getCandidateWeSummary: null,
  getCandidateWeSummarySuccess: null,
  getCandidateWeSummaryFailure: null,
  candidateMatchingListForJobrole: null,
  candidateMatchingListForJobroleSuccess: null,
  candidateMatchingListForJobroleFailure: null,
  checkCandidateInviteForRole: null,
  checkCandidateInviteForRoleSuccess: null,
  checkCandidateInviteForRoleFailure: null,
  getMaintenanceDetails: null,
  getMaintenanceDetailsSuccess: null,
  getMaintenanceDetailsFailure: null,
  createTicket: null,
  createTicketSuccess: null,
  createTicketFailure: null,
  raiseIssue: null,
  raiseIssueSuccess: null,
  raiseIssueFailure: null,
  validateFeatureAccess: null,
  validateFeatureAccessSuccess: null,
  validateFeatureAccessFailure: null,
};

function auth(state = initialState, action) {
  switch (action.type) {
    case authTypes.LOGIN_USER:
      return { ...state, isauthLoading: true, logindata: action.payload };

    case authTypes.LOGIN_USER_SUCCESS:
      return { ...state, isauthLoading: false, logindata: action.payload.token };

    case authTypes.LOGO_SUCCESS:
      return { ...state, logoDetails: action.payload };

    case authTypes.SIGNUP_USER:
      return { ...state, isauthLoading: true, signUpUser: action.payload };

    case authTypes.SIGNUP_USER_SUCCESS:
      return { ...state, isauthLoading: false, signUpUserSuccess: action.payload };

    case authTypes.SIGNUP_USER_FAILURE:
      return { ...state, isauthLoading: false, signUpUserFailure: action.payload };

    case authTypes.ACCEPT_INVITATION:
      return { ...state, isauthLoading: true, acceptInvitation: action.payload };

    case authTypes.ACCEPT_INVITATION_SUCCESS:
      return { ...state, isauthLoading: false, acceptInvitationSuccess: action.payload };

    case authTypes.ACCEPT_INVITATION_FAILURE:
      return { ...state, isauthLoading: false, acceptInvitationFailure: action.payload };

    case authTypes.FORGET_PASSWORD:
      return { ...state, isauthLoading: true, forgetpassword: action.payload };

    case authTypes.FORGET_PASSWORD_SUCCESS:
      return { ...state, isauthLoading: false, forgetPasswordSuccess: action.payload };

    case authTypes.FORGET_PASSWORD_FAILURE:
      return { ...state, isauthLoading: false, forgetPasswordFailure: action.payload };

    case authTypes.RESET_PASSWORD:
      return { ...state, isauthLoading: true, resetpassword: action.payload };

    case authTypes.RESET_PASSWORD_SUCCESS:
      return { ...state, isauthLoading: false, resetpasswordSuccess: action.payload };

    case authTypes.RESET_PASSWORD_FAILURE:
      return { ...state, isauthLoading: false, resetpasswordFailure: action.payload };

    case authTypes.VALIDATE_EMAIL:
      return { ...state, isauthLoading: true, validateEmail: action.payload };

    case authTypes.VALIDATE_EMAIL_SUCCESS:
      return { ...state, isauthLoading: false, validateEmailSuccess: action.payload };

    case authTypes.VALIDATE_EMAIL_FAILURE:
      return { ...state, isauthLoading: false, validateEmailFailure: action.payload };

    case authTypes.ACCEPT_VALIDATION:
      return { ...state, isauthLoading: true, acceptValidation: action.payload };

    case authTypes.ACCEPT_VALIDATION_SUCCESS:
      return { ...state, isauthLoading: false, acceptValidationSuccess: action.payload };

    case authTypes.ACCEPT_VALIDATION_FAILURE:
      return { ...state, isauthLoading: false, acceptValidationFailure: action.payload };

    case authTypes.RESEND_INVITATION:
      return { ...state, isauthLoading: true, resendInvitation: action.payload };

    case authTypes.RESEND_INVITATION_SUCCESS:
      return { ...state, isauthLoading: false, resendInvitationSuccess: action.payload };

    case authTypes.RESEND_INVITATION_FAILURE:
      return { ...state, isauthLoading: false, resendInvitationFailure: action.payload };

    case authTypes.CLEAR_RESEND_INVITATION_SUCCESS:
      return { ...state, resendInvitationSuccess: null };

    case authTypes.RECRUITURMENT_LIST:
      return {
        ...state,
        isauthLoading: true,
        recruiterList: action.payload,
        isRecruiterLoading: true,
      };

    case authTypes.RECRUITURMENT_LIST_SUCCESS:
      let recruiterfilter = { ...state.recruiterfilter };

      let recruiterCreatedBy = action.payload.reduce((acc, val) => {
        if (val.createdBy && Object.hasOwn(val, "createdBy")) {
          if (acc.findIndex((i) => i.displayName.toLowerCase() === val.createdBy.displayName.toLowerCase()) === -1) {
            acc = [...acc, val.createdBy];
          }
        }
        return acc.sort();
      }, []);

      recruiterfilter["addedBy"] = recruiterCreatedBy;
      // eslint-disable-next-line
      action.payload.map((i) => {
        i["createdByDisplayName"] = i.createdBy.displayName;
      });

      return {
        ...state,
        isauthLoading: false,
        recruiterList: action.payload,
        addrecruiter: {},
        editrecruiter: {},
        recruiterDeactive: null,
        recruiteractive: null,
        recruiterlistTotal: action.payload,
        recruiterdetails: [],
        searchByEmail: {},
        recruiterfilter,
        isRecruiterLoading: false,
      };

    case authTypes.RECRUITER_LIST_TOTAL:
      return {
        ...state,
        isauthLoading: false,
        recruiterlistTotal: action.payload,
      };

    case authTypes.RECRUITER_DETAILS:
      return {
        ...state,
        isauthLoading: true,
        recruiterdetails: action.payload,
      };

    case authTypes.RECRUITER_DETAILS_SUCCESS:
      return {
        ...state,
        isauthLoading: false,
        recruiterdetails: action.payload,
        addrecruiter: {},
        editrecruiter: {},
      };

    case authTypes.RECRUITER_DETAILS_FAILURE:
      return {
        ...state,
        isauthLoading: false,
        recruiterdetailsFailure: action.payload,
      };

    case authTypes.RECRUITER_DASHBOARD:
      return {
        ...state,
        isauthLoading: true,
        recruiterdashboard: action.payload,
      };

    case authTypes.RECRUITER_DASHBOARD_SUCCESS:
      return {
        ...state,
        isauthLoading: false,
        recruiterdashboard: action.payload,
      };
    case authTypes.RECRUITURMENT_LIST_RESET:
      return {
        ...state,
        isauthLoading: false,
        recruiterList: null,
      };

    case authTypes.RECRUITER_FILTER:
      return {
        ...state,
        isauthLoading: true,
        recruiterfilter: action.payload,
      };

    case authTypes.RECRUITER_FILTER_SUCCESS:
      return {
        ...state,
        isauthLoading: false,
        recruiterfilter: action.payload,
      };

    case authTypes.REMOVE_RESUME:
      return {
        ...state,
        isauthLoading: true,
        resumeremove: action.payload,
      };

    case authTypes.REMOVE_RESUME_SUCCESS:
      return {
        ...state,
        isauthLoading: false,
        resumeremove: action.payload,
        uploadresume: [],
        fetchedResumeDetails: [],
      };

    case authTypes.DOWNLOAD_RESUME:
      return {
        ...state,
        isauthLoading: true,
        downloadresume: action.payload,
      };

    case authTypes.DOWNLOAD_RESUME_SUCCESS:
      return {
        ...state,
        isauthLoading: false,
        downloadresume: action.payload,
      };

    case authTypes.ADD_RECRUITURMENT:
      return {
        ...state,
        isauthLoading: true,
        addrecruiter: action.payload,
      };

    case authTypes.ADD_RECRUITURMENT_SUCCESS:
      return {
        ...state,
        isauthLoading: false,
        addrecruiter: action.payload,
      };

    case authTypes.EDIT_RECRUITER:
      return {
        ...state,
        isauthLoading: true,
        editrecruiter: action.payload,
      };

    case authTypes.EDIT_RECRUITER_SUCCESS:
      return {
        ...state,
        isauthLoading: false,
        editrecruiter: action.payload,
      };

    case authTypes.DELETE_RECRUTER:
      return {
        ...state,
        isauthLoading: true,
        recruiterDeactive: action.payload,
      };

    case authTypes.DELETE_RECRUTER_SUCCESS:
      return {
        ...state,
        isauthLoading: false,
        recruiterDeactive: action.payload,
      };

    case authTypes.ACTIVATE_RECRUITER:
      return {
        ...state,
        isauthLoading: true,
        recruiteractive: action.payload,
      };

    case authTypes.ACTIVATE_RECRUITER_SUCCESS:
      return {
        ...state,
        isauthLoading: false,
        recruiteractive: action.payload,
      };

    case authTypes.CANDIDATE_LIST:
      return {
        ...state,
        isauthLoading: true,
        // candidatelist: action.payload,
        fetchedResumeDetails: [],
        isCandidateLoading: true,
      };

    case authTypes.CANDIDATE_LIST_SUCCESS:
      let candidatefilter = { ...state.candidatefilter };
      let skillsConfig = { ...state.skillsConfig };
      // let skillsArray = action.payload.reduce((acc,val) => {
      //   if(val.skills && Object.hasOwn(val, 'skills')) {
      //     acc = [...new Set([...acc,...val.skills])]
      //   }
      //   return acc.sort();
      // },[])

      let skills = [];

      for (let skill in skillsConfig) {
        if (skillsConfig[skill].isCandidateSkill) {
          skills.push({ id: skill, displayName: skillsConfig[skill]?.fullName });
        }
      }

      // skills = [...new Set(skills.map(i => i.displayName.toLowerCase().trim()))]

      // candidatefilter['skills'] = [...new Set(skillsArray.map(i=>i.toLowerCase().trim())),'unknown'];
      candidatefilter.skills = [...skills.sort((a, b) => a?.displayName?.localeCompare(b?.displayName))];

      // FK this code was changing short name to full name in api
      // // Map skills array with fullname using config
      // action.payload.map((item) => {
      //   let newSkillsArray = [];
      //   for (let skill of item.skillsArray) {
      //     if (skill in skillsConfig) {
      //       newSkillsArray.push(skillsConfig[skill].fullName)
      //     }
      //     else {
      //       newSkillsArray.push(skill);
      //     }
      //   }

      //   item.skillsArray = [...newSkillsArray];
      // })

      let createdBy = action.payload.reduce((acc, val) => {
        if (val.createdBy && Object.hasOwn(val, "createdBy")) {
          if (acc.findIndex((i) => i.displayName.toLowerCase() === val.createdBy.displayName.toLowerCase()) === -1) {
            acc = [...acc, val.createdBy];
          }
        }
        return acc.sort();
      }, []);

      candidatefilter["createdBy"] = createdBy;

      return {
        ...state,
        isauthLoading: false,
        candidatelist: action.payload,
        editcandidate: {},
        addcandidate: {},
        candidateDeactive: null,
        candidateActive: null,
        candidatefilter,
        candidateListTotal: action.payload,
        resumeremove: [],
        downloadresume: [],
        candidatedetails: [],
        searchByEmail: {},
        uploadresume: [],
        fetchedResumeDetails: [],
        isCandidateLoading: false,
      };

    case authTypes.CANDIDATE_LIST_RESET:
      return {
        ...state,
        isauthLoading: false,
        candidatelist: null,
      };

    case authTypes.CANDIDATE_TOTAL_COUNT:
      return {
        ...state,
        isauthLoading: false,
        candidateListTotal: action.payload,
      };

    case authTypes.CANDIDATE_MY_COUNT:
      return {
        ...state,
        isauthLoading: false,
        candidateListMyTotal: action.payload,
      };

    case authTypes.CANDIDATE_ALL_COUNT:
      return {
        ...state,
        isauthLoading: false,
        candidateListAllTotal: action.payload,
      };

    case authTypes.CANDIDATE_DETAILS:
      return {
        ...state,
        isauthLoading: true,
        candidatedetails: action.payload,
      };

    case authTypes.CANDIDATE_DETAILS_SUCCESS:
      return {
        ...state,
        isauthLoading: false,
        candidatedetails: action.payload,
        candidateDeactive: null,
        candidateActive: null,
        resumeremove: [],
        downloadresume: [],
        editcandidate: {},
        addcandidate: {},
      };

    case authTypes.CANDIDATE_DETAILS_FAILURE:
      return {
        ...state,
        isauthLoading: false,
        candidatedetailsFailure: action.payload,
      };

    case authTypes.CANDIDATE_UPLOAD_RESUME:
      return {
        ...state,
        isauthLoading: true,
        isResumeUploadRunning: true,
        uploadresume: action.payload,
        fetchedResumeDetails: [],
      };

    case authTypes.CANDIDATE_UPLOAD_RESUME_SUCCESS:
      return {
        ...state,
        isauthLoading: false,
        isResumeUploadRunning: false,
        uploadresume: action.payload,
        fetchedResumeDetails: [action.payload],
      };

    case authTypes.CANDIDATE_SEARCH_BY_EMAIL:
      return {
        ...state,
        isauthLoading: true,
        searchByEmail: action.payload,
      };

    case authTypes.CANDIDATE_SEARCH_BY_EMAIL_SUCCESS:
      return {
        ...state,
        isauthLoading: false,
        searchByEmail: action.payload,
      };

    case authTypes.CANDIDATE_FILTER:
      return {
        ...state,
        isauthLoading: true,
        // candidatefilter: action.payload,
      };

    case authTypes.CANDIDATE_FILTER_SUCCESS:
      return {
        ...state,
        isauthLoading: false,
        // candidatefilter: action.payload,
        downloadresume: [],
      };

    case authTypes.ADD_CANDIDATE:
      return {
        ...state,
        isauthLoading: true,
        addcandidate: action.payload,
      };

    case authTypes.ADD_CANDIDATE_SUCCESS:
      return {
        ...state,
        isauthLoading: false,
        addcandidate: action.payload,
        uploadresume: [],
        resumeremove: [],
        downloadresume: [],
        fetchedResumeDetails: [],
      };

    case authTypes.EDIT_CANDIDATE:
      return {
        ...state,
        isauthLoading: true,
        editcandidate: action.payload,
      };

    case authTypes.EDIT_CANDIDATE_SUCCESS:
      return {
        ...state,
        isauthLoading: false,
        editcandidate: action.payload,
        uploadresume: [],
        resumeremove: [],
        downloadresume: [],
        candidatedetails: action.payload.data,
      };

    case authTypes.DELETE_CANDIDATE:
      return {
        ...state,
        isauthLoading: true,
        candidateDeactive: action.payload,
      };

    case authTypes.DELETE_CANDIDATE_SUCCESS:
      return {
        ...state,
        isauthLoading: false,
        candidateDeactive: action.payload,
      };

    case authTypes.ACTIVATE_CANDIDATE:
      return {
        ...state,
        isauthLoading: true,
        candidateActive: action.payload,
      };

    case authTypes.ACTIVATE_CANDIDATE_SUCCESS:
      return {
        ...state,
        isauthLoading: false,
        candidateActive: action.payload,
      };

    case authTypes.SET_CODING_THEME:
      return { ...state, codingTheme: action.payload };

    case authTypes.SET_CODING_LANG:
      return { ...state, codingLang: action.payload };

    case authTypes.API_ERROR:
      return {
        ...state,
        isauthLoading: false,
        apiError: action.payload,
      };

    case authTypes.RESUME_UPLOAD_REQUESTED:
      return { ...state, isauthLoading: false, resumeRequest: action.payload };

    case authTypes.RESUME_UPLOAD:
      return {
        ...state,
        isauthLoading: true,
        resumeUrl: action.payload.location,
        resumeName: action.payload.originalname,
        resumeSize: action.payload.size,
        resumeScore: action.payload.score,
      };

    case authTypes.DELETE_RESUME:
      return {
        ...state,
        resumeName: "",
      };

    case authTypes.RESUME_UPLOAD_FAILURE:
      return { ...state, isauthLoading: false, resumeUrlError: action.payload };

    case authTypes.DELETE_RESUME_REQUESTED:
      return { ...state, isauthLoading: true, deleteRequest: action.payload };
    case authTypes.DELETE_RESUME_SUCCESS:
      return { ...state, isauthLoading: false, deleteRequest: action.payload, resumeRequest: {} };

    case authTypes.UPDATE_LEVEL:
      return { ...state, isauthLoading: false, level: action.payload };

    case authTypes.DELETE_RESUME_SUCCESS: // FK cleanup duplicate july 2023
      return {
        ...state,
        isauthLoading: false,
        resumeRequest: {},
        resumeScore: 10,
      };
    case authTypes.UPLOAD_PROFILE:
      return {
        ...state,
        isauthLoading: true,
        uploadprofile: action.payload,
      };
    case authTypes.UPLOAD_PROFILE_SUCCESS:
      return {
        ...state,
        isauthLoading: false,
        uploadprofile: action.payload,
      };

    case authTypes.CANDIDATE_DP:
      return {
        ...state,
        isauthLoading: true,
        candidateDPUrl: action.payload,
      };

    case authTypes.CANDIDATE_DPURL_SUCCESS:
      return {
        ...state,
        isauthLoading: false,
        candidateDPUrl: action.payload,
      };

    case authTypes.CANDIDATE_DPURL_RESET:
      return {
        ...state,
        candidateDPUrl: null,
      };

    case authTypes.USER_EXISTS:
      return {
        ...state,
        userExists: action.payload,
      };

    case authTypes.GET_AOSI:
      return { ...state, isauthLoading: true, areaOfStrengthsAndImprovements: action.payload };

    case authTypes.GET_AOSI_SUCCESS:
      return {
        ...state,
        isauthLoading: false,
        areaOfStrengthsAndImprovements: action?.payload,
      };

    case authTypes.CANDIDATE_FETCH_RESUME_DETAILS:
      return {
        ...state,
        isauthLoading: true,
        isResumeUploadRunning: true,
        fetchedResumeDetails: action.payload,
      };

    case authTypes.CANDIDATE_FETCH_RESUME_DETAILS_SUCCESS:
      return {
        ...state,
        isauthLoading: false,
        isResumeUploadRunning: false,
        fetchedResumeDetails: action.payload,
      };

    case authTypes.CLEAR_STATE:
      return {
        ...state,
        ...initialState,
      };

    case authTypes.CANDIDATE_RESUME_SCAN_DETAILS:
      return {
        ...state,
        isauthLoading: true,
        isResumeUploadRunning: true,
        uploadresume: action.payload,
      };

    case authTypes.CANDIDATE_RESUME_SCAN_DETAILS_SUCCESS:
      return {
        ...state,
        isauthLoading: false,
        isResumeUploadRunning: false,
        uploadresume: action.payload,
      };

    case authTypes.CHECK_INVITATION_STATUS_SUCCESS:
      return {
        ...state,
        checkInvitationData: action.payload,
      };

    case authTypes.CHECK_RESET_PASSWORD_TOKEN_SUCCESS:
      return {
        ...state,
        checkResetPasswordTokenData: action.payload,
      };

    case authTypes.CHECK_VALIDATION_STATUS_SUCCESS:
      return {
        ...state,
        checkValidationData: action.payload,
      };

    case authTypes.CREATE_USER_PASSWORD:
      return {
        ...state,
        isauthLoading: true,
        createPassword: action.payload,
      };

    case authTypes.CREATE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        isauthLoading: false,
        createUserPasswordSuccessData: action.payload,
      };
    case authTypes.CREATE_PASSWORD:
      return {
        ...state,
        isauthLoading: true,
        createPassword: action.payload,
      };

    case authTypes.CREATE_PASSWORD_SUCCESS:
      return {
        ...state,
        isauthLoading: false,
        createPasswordSuccessData: action.payload,
      };

    // case authTypes.CHECK_ENTITY_STATUS_SUCCESS:
    // return {
    //   ...state,
    //   checkEntityStatusData: action.payload
    // }

    case authTypes.CHECK_SUBSCRIPTION_STATUS_CANDIDATE_SUCCESS:
      return {
        ...state,
        checkSubscriptionStatusCandidateData: action.payload,
      };

    case authTypes.CHECK_SUBSCRIPTION_STATUS_INVITE_SUCCESS:
      return {
        ...state,
        checkSubscriptionStatusInviteData: action.payload,
      };

    case authTypes.CHECK_SUBSCRIPTION_STATUS_ROLE_SUCCESS:
      return {
        ...state,
        checkSubscriptionStatusRoleData: action.payload,
      };

    case authTypes.CHECK_SUBSCRIPTION_STATUS_USER_SUCCESS:
      return {
        ...state,
        checkSubscriptionStatusUserData: action.payload,
      };

    case authTypes.CHANGE_PASSWORD:
      return { ...state, isauthLoading: true, changePassword: action.payload };

    case authTypes.CHANGE_PASSWORD_SUCCESS:
      return { ...state, isauthLoading: false, changePasswordSuccess: true };

    case authTypes.CHANGE_PASSWORD_FAILURE:
      return { ...state, isauthLoading: false, changePasswordSuccess: false, changePasswordFailure: action.payload };

    case authTypes.SIGNUP_PLANS:
      return { ...state, isauthLoading: true, signUpPlans: action.payload };

    case authTypes.SIGNUP_PLANS_SUCCESS:
      return { ...state, isauthLoading: false, signUpPlansSuccess: action.payload };

    case authTypes.SIGNUP_PLANS_FAILURE:
      return { ...state, isauthLoading: false, signUpPlansFailure: action.payload };

    case authTypes.RESET_CHANGE_PASSWORD_SUCCESS:
      return { ...state, changePasswordSuccess: false };

    case authTypes.CREATE_PAYMENT_LINK:
      return { ...state, isauthLoading: true, createPaymentLink: action.payload };

    case authTypes.CREATE_PAYMENT_LINK_SUCCESS:
      return { ...state, isauthLoading: false, createPaymentLinkSuccess: action.payload };

    case authTypes.CREATE_PAYMENT_LINK_FAILURE:
      return { ...state, isauthLoading: false, createPaymentLinkFailure: action.payload };

    case authTypes.MANAGEMENT_DETAILS:
      return { ...state, isauthLoading: true, managementDetails: action.payload };

    case authTypes.MANAGEMENT_DETAILS_SUCCESS:
      return { ...state, isauthLoading: false, managementDetailsSuccess: action.payload };

    case authTypes.MANAGEMENT_DETAILS_FAILURE:
      return { ...state, isauthLoading: false, managementDetailsFailure: action.payload };

    case authTypes.UPDATE_ORG_DETAILS:
      return { ...state, isauthLoading: true, updateOrgDetails: action.payload };

    case authTypes.UPDATE_ORG_DETAILS_SUCCESS:
      return { ...state, isauthLoading: false, updateOrgDetailsSuccess: true };

    case authTypes.UPDATE_ORG_DETAILS_FAILURE:
      return {
        ...state,
        isauthLoading: false,
        updateOrgDetailsSuccess: false,
        updateOrgDetailsFailure: action.payload,
      };

    case authTypes.RESET_ORG_DETAILS_SUCCESS:
      return { ...state, updateOrgDetailsSuccess: false };

    case authTypes.UPDATE_USER_DETAILS:
      return { ...state, isauthLoading: true, updateUserDetails: action.payload };

    case authTypes.UPDATE_USER_DETAILS_SUCCESS:
      return { ...state, isauthLoading: false, updateUserDetailsSuccess: true };

    case authTypes.UPDATE_USER_DETAILS_FAILURE:
      return {
        ...state,
        isauthLoading: false,
        updateUserDetailsSuccess: false,
        updateUserDetailsFailure: action.payload,
      };

    case authTypes.RESET_USER_DETAILS_SUCCESS:
      return { ...state, updateUserDetailsSuccess: false };

    case authTypes.RESEND_EMAIL_VALIDATION:
      return { ...state, isauthLoading: true, resendEmailValidation: action.payload };

    case authTypes.RESEND_EMAIL_VALIDATION_SUCCESS:
      return { ...state, isauthLoading: false, resendEmailValidationSuccess: action.payload };

    case authTypes.RESEND_EMAIL_VALIDATION_FAILURE:
      return { ...state, isauthLoading: false, resendEmailValidationFailure: action.payload };

    case authTypes.CREATE_CHECKOUT_SESSION:
      return { ...state, isauthLoading: true, creatCheckoutSession: action.payload };

    case authTypes.CREATE_CHECKOUT_SESSION_SUCCESS:
      return { ...state, isauthLoading: false, creatCheckoutSessionSuccess: action.payload };

    case authTypes.CREATE_CHECKOUT_SESSION_FAILURE:
      return { ...state, isauthLoading: false, creatCheckoutSessionFailure: action.payload };

    case authTypes.CREATE_BILLING_PORTAL:
      return { ...state, isauthLoading: true, createBillingPortal: action.payload };

    case authTypes.CREATE_BILLING_PORTAL_SUCCESS:
      return { ...state, isauthLoading: false, createBillingPortalSuccess: action.payload };

    case authTypes.CREATE_BILLING_PORTAL_FAILURE:
      return { ...state, isauthLoading: false, createBillingPortalFailure: action.payload };

    case authTypes.DELETE_INVITED_USER:
      return { ...state, isauthLoading: true, deleteInvitedUser: action.payload };

    case authTypes.DELETE_INVITED_USER_SUCCESS:
      return { ...state, isauthLoading: false, deleteInvitedUserSuccess: action.payload };

    case authTypes.DELETE_INVITED_USER_FAILURE:
      return { ...state, isauthLoading: false, deleteInvitedUserFailure: action.payload };

    case authTypes.BILLING_PAGE_PLANS:
      return { ...state, isauthLoading: true, BillingPagePlans: action.payload };

    case authTypes.BILLING_PAGE_PLANS_SUCCESS:
      return { ...state, isauthLoading: false, BillingPagePlansSuccess: action.payload };

    case authTypes.BILLING_PAGE_PLANS_FAILURE:
      return { ...state, isauthLoading: false, BillingPagePlansFailure: action.payload };

    case authTypes.API_BILLING_PORTAL_FOR_INVOICE_HISTORY:
      return { ...state, isauthLoading: true, apiBillingPortalForInvoiceHistory: action.payload };

    case authTypes.API_BILLING_PORTAL_FOR_INVOICE_HISTORY_SUCCESS:
      return { ...state, isauthLoading: false, apiBillingPortalForInvoiceHistorySuccess: action.payload };

    case authTypes.API_BILLING_PORTAL_FOR_INVOICE_HISTORY_FAILURE:
      return { ...state, isauthLoading: false, apiBillingPortalForInvoiceHistoryFailure: action.payload };

    case authTypes.INVOICE_HISTORY_LIST:
      return { ...state, isauthLoading: true, invoiceHistoryList: action.payload };

    case authTypes.INVOICE_HISTORY_LIST_SUCCESS:
      return { ...state, isauthLoading: false, invoiceHistoryListSuccess: action.payload };

    case authTypes.INVOICE_HISTORY_LIST_FAILURE:
      return { ...state, isauthLoading: false, invoiceHistoryListFailure: action.payload };

    case authTypes.GET_IP_ADDRESS:
      return { ...state, isauthLoading: true, getIpAddress: action.payload };

    case authTypes.GET_IP_ADDRESS_SUCCESS:
      return { ...state, isauthLoading: false, getIpAddressSuccess: action.payload };

    case authTypes.GET_IP_ADDRESS_FAILURE:
      return { ...state, isauthLoading: false, getIpAddressFailure: action.payload };

    case authTypes.GET_CONFIG_SKILLS:
      return { ...state, getConfigSkills: action.payload };

    case authTypes.SET_SKILLS_CONFIG:
      let candidateFilterSkills = [];

      for (let skill in action.payload) {
        if (action.payload[skill].isCandidateSkill) {
          candidateFilterSkills.push({ id: skill, displayName: action.payload[skill]?.fullName });
        }
      }

      candidateFilterSkills = [...candidateFilterSkills.sort((a, b) => a?.displayName?.localeCompare(b?.displayName))];
      return {
        ...state,
        skillsConfig: action.payload,
        candidatefilter: {
          ...state.candidatefilter,
          skills: [...candidateFilterSkills],
        },
      };

    case authTypes.GET_CONFIG_SKILLS_FAILURE:
      return { ...state, getConfigSkillsFailure: action.payload };

    case authTypes.BOOK_A_DEMO:
      return { ...state, isauthLoading: true, bookademo: action.payload };

    case authTypes.BOOK_A_DEMO_SUCCESS:
      return { ...state, isauthLoading: false, bookademoSuccess: action.payload };

    case authTypes.BOOK_A_DEMO_FAILURE:
      return { ...state, isauthLoading: false, bookademoFailure: action.payload };

    case authTypes.EDIT_AND_ADD_RESUME:
      return { ...state, isauthLoading: true, editAndAddResume: action.payload };

    case authTypes.EDIT_AND_ADD_RESUME_SUCCESS:
      return { ...state, isauthLoading: false, editAndAddResumeSuccess: action.payload };

    case authTypes.EDIT_AND_ADD_RESUME_FAILURE:
      return { ...state, isauthLoading: false, editAndAddResumeFailure: action.payload };

    case authTypes.ADD_COMPLETED_RESUME:
      return { ...state, isauthLoading: true, addCompletedResume: action.payload };

    case authTypes.ADD_COMPLETED_RESUME_SUCCESS:
      return { ...state, isauthLoading: false, addCompletedResumeSuccess: action.payload };

    case authTypes.ADD_COMPLETED_RESUME_FAILURE:
      return { ...state, isauthLoading: false, addCompletedResumeFailure: action.payload };

    case authTypes.GET_PENDING_RESUME_LIST:
      return { ...state, isauthLoading: true, getPendingResumeList: action.payload };

    case authTypes.GET_PENDING_RESUME_LIST_SUCCESS:
      return { ...state, isauthLoading: false, getPendingResumeListSuccess: action.payload };

    case authTypes.GET_PENDING_RESUME_LIST_FAILURE:
      return { ...state, isauthLoading: false, getPendingResumeListFailure: action.payload };

    case authTypes.UPLOAD_BULK_RESUME:
      return { ...state, isauthLoading: true, uploadBulkResume: action.payload };

    case authTypes.UPLOAD_BULK_RESUME_SUCCESS:
      // let data = state.uploadBulkResumeSuccess
      // data.push(action.payload)
      console.log(action.payload);
      return { ...state, isauthLoading: false, uploadBulkResumeSuccess: action.payload };

    case authTypes.CLEAR_UPLOAD_BULK_RESUME_SUCCESS:
      return { ...state, uploadBulkResumeSuccess: [] };

    case authTypes.UPLOAD_BULK_RESUME_FAILURE:
      return { ...state, isauthLoading: false, uploadBulkResumeFailure: action.payload };

    case authTypes.DELETE_RESUME_DOC:
      return { ...state, isauthLoading: true, deleteResumeDoc: action.payload };

    case authTypes.DELETE_RESUME_DOC_SUCCESS:
      return { ...state, isauthLoading: false, deleteResumeDocSuccess: action.payload };

    case authTypes.DELETE_RESUME_DOC_FAILURE:
      return { ...state, isauthLoading: false, deleteResumeDocFailure: action.payload };

    case authTypes.MATCH_CANDIDATES:
      return { ...state, isauthLoading: true, matchCandidates: action.payload };

    case authTypes.MATCH_CANDIDATES_SUCCESS:
      return { ...state, isauthLoading: false, matchCandidatesSuccess: action.payload };

    case authTypes.MATCH_CANDIDATES_FAILURE:
      return { ...state, isauthLoading: false, matchCandidatesFailure: action.payload };

    case authTypes.CLOSE_INVITE:
      return { ...state, isauthLoading: true, closeInvite: action.payload };

    case authTypes.CLOSE_INVITE_SUCCESS:
      return { ...state, isauthLoading: false, closeInviteSuccess: action.payload };

    case authTypes.CLOSE_INVITE_FAILURE:
      return { ...state, isauthLoading: false, closeInviteFailure: action.payload };

    case authTypes.CANDIDATE_ROLE_LIST_FOR_NEW_INVITE:
      return { ...state, isauthLoading: true, candidateRoleListForNewInvite: action.payload };

    case authTypes.CANDIDATE_ROLE_LIST_FOR_NEW_INVITE_SUCCESS:
      return { ...state, isauthLoading: false, candidateRoleListForNewInviteSuccess: action.payload };

    case authTypes.CANDIDATE_ROLE_LIST_FOR_NEW_INVITE_FAILURE:
      return { ...state, isauthLoading: false, candidateRoleListForNewInviteFailure: action.payload };

    case authTypes.GET_CANDIDATE_WE_SUMMARY:
      // setting isauthLoading to false to avoid loader on page load
      return { ...state, isauthLoading: false, getCandidateWeSummary: action.payload };

    case authTypes.GET_CANDIDATE_WE_SUMMARY_SUCCESS:
      return { ...state, isauthLoading: false, getCandidateWeSummarySuccess: action.payload };

    case authTypes.GET_CANDIDATE_WE_SUMMARY_FAILURE:
      return { ...state, isauthLoading: false, getCandidateWeSummaryFailure: action.payload };

    case authTypes.CANDIDATE_MATCHING_LIST_FOR_JOB_ROLE:
      return { ...state, isauthLoading: false, candidateMatchingListForJobrole: action.payload };

    case authTypes.CANDIDATE_MATCHING_LIST_FOR_JOB_ROLE_SUCCESS:
      return { ...state, isauthLoading: false, candidateMatchingListForJobroleSuccess: action.payload };

    case authTypes.CANDIDATE_MATCHING_LIST_FOR_JOB_ROLE_FAILURE:
      return { ...state, isauthLoading: false, candidateMatchingListForJobroleFailure: action.payload };

    case authTypes.CHECK_CANDIDATE_INVITE_FOR_ROLE:
      return { ...state, isauthLoading: false, checkCandidateInviteForRole: action.payload };

    case authTypes.CHECK_CANDIDATE_INVITE_FOR_ROLE_SUCCESS:
      return { ...state, isauthLoading: false, checkCandidateInviteForRoleSuccess: action.payload };

    case authTypes.CHECK_CANDIDATE_INVITE_FOR_ROLE_FAILURE:
      return { ...state, isauthLoading: false, checkCandidateInviteForRoleFailure: action.payload };

    case authTypes.GET_MAINTENANCE_DETAILS:
      return { ...state, isauthLoading: true, getMaintenanceDetails: action.payload };

    case authTypes.GET_MAINTENANCE_DETAILS_SUCCESS:
      return { ...state, isauthLoading: false, getMaintenanceDetailsSuccess: action.payload };

    case authTypes.GET_MAINTENANCE_DETAILS_FAILURE:
      return { ...state, isauthLoading: false, getMaintenanceDetailsFailure: action.payload };

    case authTypes.CREATE_TICKET:
      return { ...state, isauthLoading: true, createTicket: action.payload };

    case authTypes.CREATE_TICKET_SUCCESS:
      return { ...state, isauthLoading: false, createTicketSuccess: action.payload };

    case authTypes.CREATE_TICKET_FAILURE:
      return { ...state, isauthLoading: false, createTicketFailure: action.payload };

    case authTypes.CLEAR_RAISE_ISSUE_SUCCESS:
      return { ...state, raiseIssueSuccess: null };

    case authTypes.RAISE_ISSUE:
      return { ...state, isauthLoading: true, raiseIssue: action.payload };

    case authTypes.RAISE_ISSUE_SUCCESS:
      return { ...state, isauthLoading: false, raiseIssueSuccess: action.payload };

    case authTypes.RAISE_ISSUE_FAILURE:
      return { ...state, isauthLoading: false, raiseIssueFailure: action.payload };

    case authTypes.VALIDATE_FEATURE_ACCESS:
      return { ...state, isauthLoading: true, validateFeatureAccess: action.payload };

    case authTypes.VALIDATE_FEATURE_ACCESS_SUCCESS:
      return { ...state, isauthLoading: false, validateFeatureAccessSuccess: action.payload };

    case authTypes.VALIDATE_FEATURE_ACCESS_FAILURE:
      return { ...state, isauthLoading: false, validateFeatureAccessFailure: action.payload };

    default:
      return { ...state };
  }
}

export default auth;
