import React, { useState, useEffect } from "react";
import Plot from "../../pages/manageAccounts/analytics/charts/Plot";
// import chart from "../../../../redux/actions/chart";
import { chartConfig } from "../../pages/manageAccounts/analytics/chartConfig";

const ScatterPlot = ({
  chartRoleId,
  filter,
  firstFilter,
  xDate,
  yDate,
  customDateFilter,
  differentiator,
  differentiatorFilter,
  quadrantFilter,
  customDifferentiatorData,
  chartData,
}) => {
  const [targetScore, setTargetScore] = useState(590);
  const [mainData, setMainData] = useState(chartData || []);
  const [data, setData] = useState(chartData || []);

  const currentDate = new Date();
  currentDate.setHours(23, 59, 59, 999);

  // filter data based on date
  useEffect(() => {
    let filterData = [...mainData];
    filterData = dateFilter(filterData);
    filterData = rankingFilter(filterData);
    setData(filterData);
  }, [firstFilter, filter, differentiatorFilter, customDateFilter, yDate, xDate, customDifferentiatorData]);

  // filter data based on differentiator
  function dateFilter(dataForFilter) {
    let filteredData = [...dataForFilter];

    switch (firstFilter) {
      case "Last 60 Days":
        const sixtyDaysAgo = new Date();
        sixtyDaysAgo.setDate(currentDate.getDate() - 60);
        sixtyDaysAgo.setHours(0, 0, 1, 0);

        filteredData = dataForFilter?.filter((item) => {
          const updatedAtDate = new Date(item.createdAt);
          return updatedAtDate >= sixtyDaysAgo && updatedAtDate <= currentDate;
        });

        return filteredData;

      case "Last 90 Days":
        const ninetyDaysAgo = new Date();
        ninetyDaysAgo.setDate(currentDate.getDate() - 90);
        ninetyDaysAgo.setHours(0, 0, 1, 0);

        filteredData = dataForFilter?.filter((item) => {
          const updatedAtDate = new Date(item.createdAt);
          return updatedAtDate >= ninetyDaysAgo && updatedAtDate <= currentDate;
        });

        return filteredData;

      case "all":
        return [...dataForFilter];

      case "Last 30 Days":
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(currentDate.getDate() - 30);
        thirtyDaysAgo.setHours(0, 0, 1, 0);

        filteredData = dataForFilter?.filter((item) => {
          const updatedAtDate = new Date(item.createdAt);
          return updatedAtDate >= thirtyDaysAgo && updatedAtDate <= currentDate;
        });

        return filteredData;

      case "Last 7 Days":
        const oneWeekAgo = new Date(currentDate - 7 * 24 * 60 * 60 * 1000);
        return (filteredData = dataForFilter?.filter((item) => {
          const updatedAtDate = new Date(item.createdAt);
          return updatedAtDate >= oneWeekAgo && updatedAtDate <= currentDate;
        }));
      // return filteredData;

      case "custom date":
        if (xDate != null && yDate != null) {
          filteredData = dataForFilter?.filter((item) => {
            const updatedAtDate = new Date(item.createdAt);
            return updatedAtDate >= xDate && updatedAtDate <= yDate;
          });
          return filteredData; // Set the same data in setData state
        } else {
          return [...dataForFilter];
        }

      case "clear":
        return [...dataForFilter];

      default:
        return [...dataForFilter];
    }
  }

  /**
   * Ranking Filter
   */
  function rankingFilter(dataForFilter) {
    let filteredData = [...dataForFilter];

    if (filter === "all") {
      return [...dataForFilter];
    } else {
      filteredData = [...dataForFilter]?.sort((a, b) => b.inviteScore - a.inviteScore);
      filteredData = filteredData?.slice(0, filter);

      return filteredData;
    }
  }

  // set target score
  useEffect(() => {
    if (data && data?.length !== 0) {
      setTargetScore(data[0].targetScore);
    }
  }, [data]);

  // set data and colors
  useEffect(() => {
    if (chartData?.length) {
      let colorPalette = chartConfig.customColors;
      let storeData = chartData;

      for (let i = 0; i < storeData?.length; i++) {
        // Add the color as a key named 'color' to each object
        storeData[i].color = colorPalette[i % colorPalette.length];
      }
      setData(storeData);
      setMainData(storeData);
      // setFilteredData(storeData);
    }
  }, [chartData]);

  const tVal = targetScore / 300;
  const targetTextX = 0.94;
  const targetTextY = ((tVal - targetTextX) * 3) / 5 - 0.016;
  const xValues = [];
  const yValues = [];
  const names = [];
  const customHoverText = [];
  // const colorsArr = [];

  // hover text
  data?.forEach((item) => {
    const profileScore = item.profile_score;
    const skillScore = item.skill_score;
    xValues.push(profileScore);
    yValues.push(skillScore);
    names.push(item.candidate.user.firstName[0] + item.candidate.user.lastName[0]);

    const hoverText = `<b>${item.candidate.user.firstName} ${item.candidate.user.lastName}</b>
    <br>ID: <b>${item.candidate.user.uid}</b> 
    <br>Score: <b>${item.inviteScore}</b>
    <br>Invite ID: <b>${item.inviteId}</b>
    ${item.note ? "<br>" + item.note : ""}`;
    customHoverText.push(hoverText);
  });

  // colors array
  const colorArray = data.map((item) => item.color);

  //transperant color for hover
  const transparency = 1;
  const lightColor = colorArray.map((hexColor) => {
    const r = parseInt(hexColor?.slice(1, 3), 16);
    const g = parseInt(hexColor?.slice(3, 5), 16);
    const b = parseInt(hexColor?.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${transparency})`;
  });

  let size = [];
  let modifiedColors;
  let opacity = [];

  // all the skills from skillNameScore are moved outside the object
  data?.forEach((item) => {
    // skillNameScore
    if (item.hasOwnProperty("skillNameScore")) {
      const { skillNameScore } = item;
      Object?.assign(item, skillNameScore);
    }
  });

  // update custom scores
  function updateCustomScores(customDifferentiatorData) {
    data.forEach((obj, index) => {
      let customScore = 0;
      customDifferentiatorData?.forEach((skill) => {
        if (skill.weightage) {
          customScore += obj.skillNameScore[skill.name] * skill.value;
        } else {
          customScore += obj[skill.name] * skill.value;
        }
      });
      // console.log(customScore);
      data[index]["customScore"] = customScore / 100;
      // console.log(customScore, "customScore");
    });
  }

  // update custom scores
  useEffect(() => {
    if (differentiatorFilter === "customScore") {
      updateCustomScores(customDifferentiatorData);
    }
  }, [differentiatorFilter, customDifferentiatorData]);

  // differentiator filter
  data?.length > 0 &&
    (() => {
      // const diffVar = mapping[differentiatorFilter];
      const diffVar = differentiatorFilter;
      let diffValues = [];

      data?.forEach(function (item) {
        let value = item[diffVar] ? item[diffVar] : 0;
        item.differntiator = value;
        // item.differentiator = item[diffVar]? item[diffVar] : 0
        diffValues.push(value);
      });

      let minDifferentiator = Math.min(...diffValues);
      let maxDifferentiator = Math.max(...diffValues);

      let maxRadius = 45;
      let minRadius = 10;

      let currentSize;

      data?.forEach((item) => {
        currentSize =
          maxDifferentiator === minDifferentiator
            ? 20
            : Math.round(
                maxRadius -
                  ((maxRadius - minRadius) / (maxDifferentiator - minDifferentiator)) *
                    (maxDifferentiator - item.differntiator)
              );
        size.push(currentSize);

        let currentOpacity = Math.round(
          (100 * (maxRadius - 1 * minRadius - 0 * currentSize)) / (maxRadius - minRadius)
        );
        opacity.push(currentOpacity);
      });
    })();

  // modify colors based on opacity
  modifiedColors = colorArray.map((color, index) => {
    let opacityValue = opacity[index];
    if (opacityValue < 10) {
      // opacityValue = "0" + opacityValue + "%";
      opacityValue = "0" + opacityValue;
    } else if (opacityValue === 100) {
      // opacityValue = opacityValue.toString();
      opacityValue = "";
    } else {
      // opacityValue = opacityValue.toString() + "%";
      opacityValue = opacityValue.toString();
    }

    let modifiedColor = color + opacityValue.toString(16).toUpperCase(); // Add opacity to the color
    return modifiedColor;
  });
  if (differentiatorFilter === "None") {
    size = 20;
    modifiedColors = colorArray;
  }

  // Define trace options
  const trace = {
    x: xValues,
    y: yValues,
    text: names,
    textposition: "right",
    mode: "markers+text",
    marker: {
      size: differentiator ? size : 20,
      color: differentiator ? modifiedColors : colorArray,
      // color: colorArray,
    },
    customdata: customHoverText,
    hovertemplate: "%{customdata}",
    name: "",
    hoverlabel: {
      bgcolor: lightColor,
    },
  };

  // Define allLayout options
  const allLayout = {
    hovermode: "closest",
    autosize: true,
    margin: {
      l: 35,
      r: 35,
      b: 35,
      t: 35,
      pad: 4,
    },
    font: {
      family: "Helvetica",
      // color: "#787878",
      // color: "black",
      size: 18,
      // weight: "bold"
      // face: "Bold",
    },
    xaxis: {
      range: [-0.065, 1.065],
      title: {
        text: "Professional Drive  ⟶",
        font: {
          color: "black",
        },
      },
      gridcolor: "rgba(0, 0, 0, 0.0)",
      zeroline: false,
      showticks: false, // Hide the x-axis ticks
      showticklabels: false, // Hide the x-axis tick labels
      fixedrange: true,
    },
    yaxis: {
      fixedrange: true,
      range: [-0.065, 1.065],
      title: {
        text: "Technical Acumen  ⟶",
        font: {
          color: "black",
        },
      },
      gridcolor: "rgba(0, 0, 0, 0.0)",
      zeroline: false,
      showticks: false, // Hide the y-axis ticks
      showticklabels: false, // Hide the y-axis tick labels
    },
    shapes: [
      {
        type: "line",
        x0: 0.5,
        x1: 0.5,
        y0: -0.045,
        y1: 1.045,
        line: {
          color: "gray",
          width: 1,
        },
      },
      {
        type: "line",
        x0: -0.045,
        x1: 1.045,
        y0: 0.5,
        y1: 0.5,
        line: {
          color: "gray",
          width: 1,
        },
      },
      {
        type: "rect",
        x0: -0.065,
        x1: 1.065,
        y0: -0.065,
        y1: 1.065,
        line: {
          color: "black",
          width: 2,
        },
      },
      {
        type: "line",
        x0: tVal - (5 / 3) * 1.045,
        y0: 1.045,
        x1: 1.045,
        y1: ((tVal - 1.045) * 3) / 5,
        line: {
          color: "rgba(0.01,0.01,0.01,0.2)", // Line color
          width: 1,
          // dash: "dash", // Dashed line style
        },
      },
      {
        type: "path", // Type set to 'polygon' for the shaded triangle
        path: `M 1.045 ${((tVal - 1.045) * 3) / 5} L  ${tVal - (5 / 3) * 1.045} 1.045 L 1.045 1.045 Z `, // Define a path for a triangle
        fillcolor: "rgba(0.25, 0.25, 0.25, 0.07)", // Shaded area color (blue with opacity)
        line: {
          color: "rgba(0,0,0,0.0)", // Border color of the shaded area
          width: 0, // Don't draw a line
        },
      },
    ],
    aspectratio: {
      x: 1,
      y: 1,
    },
    showgrid: false,
    annotations: [
      {
        x: 0.5,
        y: 0.53,
        text: chartData?.length ? "" : "No data to show", // Conditional text
        showarrow: false,
        xanchor: "center",
        yanchor: "middle",
        font: {
          size: 25,
          color: "rgba(0.01,0.01,0.01,0.4)",
          weight: "bold",
        },
      },

      {
        x: 0.015, // X-coordinate for the label in the lower-left quadrant
        y: -0.025, // Y-coordinate for the label in the lower-left quadrant
        text: "Learners",
        showarrow: false,
        font: {
          size: 16, // Font size
          color: chartConfig.QColors[0],
          // weight: "bold", // Font weight
        },
      },
      {
        x: 0.01, // X-coordinate for the label in the upper-left quadrant
        y: 1.025, // Y-coordinate for the label in the upper-left quadrant
        text: "Experts",
        showarrow: false,
        font: {
          size: 16, // Font size
          color: chartConfig.QColors[1],
          weight: "bold", // Font weight
        },
      },
      {
        x: 0.96, // X-coordinate for the label in the lower-right quadrant
        y: -0.025, // Y-coordinate for the label in the lower-right quadrant
        text: "Professionals",
        showarrow: false,
        font: {
          size: 16, // Font size
          color: chartConfig.QColors[2], // Font color
          weight: "bold", // Font weight
        },
      },
      {
        x: 0.975, // X-coordinate for the label in the upper-right quadrant
        y: 1.025, // Y-coordinate for the label in the upper-right quadrant
        text: "Performers",
        showarrow: false,
        font: {
          size: 16, // Font size
          color: chartConfig.QColors[3], // Font color
          weight: "bold", // Font weight
        },
      },
      {
        x: targetTextX,
        y: targetTextY,
        text: "Target Score (<b>" + targetScore + "</b>)",
        showarrow: false,
        textangle: 30, // Set the angle of the text (in degrees)
        font: {
          size: 14, // Font size
          color: "rgba(0.01,0.01,0.01,0.4)", // Font color
          weight: "bold", // Font weight
        },
      },
    ],
  };

  // Define performersLayout options
  const performersLayout = {
    hovermode: "closest",
    autosize: true,
    margin: {
      l: 35,
      r: 35,
      b: 35,
      t: 35,
      pad: 4,
    },
    font: {
      family: "Helvetica",
      // color: "#787878",
      // color: "black",
      size: 18,
      // weight: "bold"
      // face: "Bold",
    },
    xaxis: {
      range: [0.5 - 0.01, 1.055],
      // range: [0.5, 1],
      title: {
        text: "Professional Drive  ⟶",
        font: {
          color: "black",
        },
      },
      gridcolor: "rgba(0, 0, 0, 0.0)",
      zeroline: false,
      showticks: false, // Hide the x-axis ticks
      showticklabels: false, // Hide the x-axis tick labels
      fixedrange: true,
    },
    yaxis: {
      fixedrange: true,
      range: [0.5 - 0.01, 1.055],
      // range: [0.5, 1.065],
      title: {
        text: "Technical Acumen  ⟶",
        font: {
          color: "black",
        },
      },
      gridcolor: "rgba(0, 0, 0, 0.0)",
      zeroline: false,
      showticks: false, // Hide the y-axis ticks
      showticklabels: false, // Hide the y-axis tick labels
    },
    shapes: [
      // {
      //   type: "line",
      //   x0: 0.5,
      //   x1: 0.5,
      //   y0: -0.045,
      //   y1: 1.045,
      //   line: {
      //     color: "gray",
      //     width: 1,
      //   },
      // },
      // {
      //   type: "line",
      //   x0: -0.045,
      //   x1: 1.045,
      //   y0: 0.5,
      //   y1: 0.5,
      //   line: {
      //     color: "gray",
      //     width: 1,
      //   },
      // },
      {
        type: "rect",
        x0: 0.5 - 0.01,
        x1: 1.055,
        y0: 0.5 - 0.01,
        y1: 1.055,
        line: {
          color: "black",
          width: 2,
        },
      },
      {
        type: "line",
        x0: 0.5,
        y0: ((tVal - 0.5) * 3) / 5,
        x1: 1.045,
        y1: ((tVal - 1.045) * 3) / 5,
        line: {
          color: "rgba(0.01,0.01,0.01,0.2)", // Line color
          width: 1,
          // dash: "dash", // Dashed line style
        },
      },
      {
        type: "path", // Type set to 'polygon' for the shaded triangle
        // path: `M 1.045 ${((tVal - 1.045) * 3) / 5} L  ${tVal - (5 / 3) * 1.045} 1.045 L 1.045 1.045 Z `, // Define a path for a triangle
        path: `M 1.045 ${((tVal - 1.045) * 3) / 5} L  0.5 ${((tVal - 0.5) * 3) / 5} L 0.5 1.045 L 1.045 1.045 Z `, // Define a path for a triangle
        fillcolor: "rgba(0.25, 0.25, 0.25, 0.07)", // Shaded area color (blue with opacity)
        line: {
          color: "rgba(0,0,0,0.0)", // Border color of the shaded area
          width: 0, // Don't draw a line
        },
      },
    ],
    aspectratio: {
      x: 1,
      y: 1,
    },
    showgrid: false,
    annotations: [
      {
        x: 0.5,
        y: 0.53,
        text: chartData?.length ? "" : "No data to show", // Conditional text
        showarrow: false,
        xanchor: "center",
        yanchor: "middle",
        font: {
          size: 25,
          color: "rgba(0.01,0.01,0.01,0.4)",
          weight: "bold",
        },
      },
      {
        x: 0.985, // X-coordinate for the label in the upper-right quadrant
        y: 1.025, // Y-coordinate for the label in the upper-right quadrant
        text: "Performers",
        showarrow: false,
        font: {
          size: 20, // Font size
          color: chartConfig.QColors[3], // Font color
          weight: "bold", // Font weight
        },
      },
      {
        x: targetTextX + 0.008,
        y: targetTextY,
        text: "Target Score (<b>" + targetScore + "</b>)",
        showarrow: false,
        textangle: 30, // Set the angle of the text (in degrees)
        font: {
          size: 14, // Font size
          color: "rgba(0.01,0.01,0.01,0.4)", // Font color
          weight: "bold", // Font weight
        },
      },
    ],
  };

  // Define expertsLayout options
  const expertsLayout = {
    hovermode: "closest",
    autosize: true,
    margin: {
      l: 35,
      r: 35,
      b: 35,
      t: 35,
      pad: 4,
    },
    font: {
      family: "Helvetica",
      // color: "#787878",
      // color: "black",
      size: 18,
      // weight: "bold"
      // face: "Bold",
    },
    xaxis: {
      range: [-0.055, 0.51],
      title: {
        text: "Professional Drive  ⟶",
        font: {
          color: "black",
        },
      },
      gridcolor: "rgba(0, 0, 0, 0.0)",
      zeroline: false,
      showticks: false, // Hide the x-axis ticks
      showticklabels: false, // Hide the x-axis tick labels
      fixedrange: true,
    },
    yaxis: {
      fixedrange: true,
      range: [0.49, 1.055],
      title: {
        text: "Technical Acumen  ⟶",
        font: {
          color: "black",
        },
      },
      gridcolor: "rgba(0, 0, 0, 0.0)",
      zeroline: false,
      showticks: false, // Hide the y-axis ticks
      showticklabels: false, // Hide the y-axis tick labels
    },
    shapes: [
      // {
      //   type: "line",
      //   x0: 0.5,
      //   x1: 0.5,
      //   y0: -0.045,
      //   y1: 1.045,
      //   line: {
      //     color: "gray",
      //     width: 1,
      //   },
      // },
      // {
      //   type: "line",
      //   x0: -0.045,
      //   x1: 1.045,
      //   y0: 0.5,
      //   y1: 0.5,
      //   line: {
      //     color: "gray",
      //     width: 1,
      //   },
      // },
      {
        type: "rect",
        x0: -0.055,
        x1: 0.51,
        y0: 0.49,
        y1: 1.055,
        line: {
          color: "black",
          width: 2,
        },
      },
      {
        type: "line",
        x0: tVal - (5 / 3) * 1.045,
        y0: 1.045,
        x1: 0.5,
        y1: ((tVal - 0.5) * 3) / 5,
        line: {
          color: "rgba(0.01,0.01,0.01,0.2)", // Line color
          width: 1,
          // dash: "dash", // Dashed line style
        },
      },
      {
        type: "path", // Type set to 'polygon' for the shaded triangle
        path: `M 0.5 ${((tVal - 0.5) * 3) / 5} L  ${tVal - (5 / 3) * 1.045} 1.045 L 0.5 1.045 Z `, // Define a path for a triangle
        fillcolor: "rgba(0.25, 0.25, 0.25, 0.07)", // Shaded area color (blue with opacity)
        line: {
          color: "rgba(0,0,0,0.0)", // Border color of the shaded area
          width: 0, // Don't draw a line
        },
      },
    ],
    aspectratio: {
      x: 1,
      y: 1,
    },
    showgrid: false,
    annotations: [
      {
        x: 0.5,
        y: 0.53,
        text: chartData?.length ? "" : "No data to show", // Conditional text
        showarrow: false,
        xanchor: "center",
        yanchor: "middle",
        font: {
          size: 25,
          color: "rgba(0.01,0.01,0.01,0.4)",
          weight: "bold",
        },
      },
      {
        x: 0, // X-coordinate for the label in the upper-left quadrant
        y: 1.025, // Y-coordinate for the label in the upper-left quadrant
        text: "Experts",
        showarrow: false,
        font: {
          size: 20, // Font size
          color: chartConfig.QColors[1],
          weight: "bold", // Font weight
        },
      },
      {
        x: targetTextX,
        y: targetTextY,
        // text: "Target Score (<b>" + targetScore + "2" + "</b>)",
        text: `Target Score (<b>${targetScore}2</b>)`,
        showarrow: false,
        textangle: 30, // Set the angle of the text (in degrees)
        font: {
          size: 14, // Font size
          color: "rgba(0.01,0.01,0.01,0.4)", // Font color
          weight: "bold", // Font weight
        },
      },
    ],
  };

  // Define professionalsLayout options
  const professionalsLayout = {
    hovermode: "closest",
    autosize: true,
    margin: {
      l: 35,
      r: 35,
      b: 35,
      t: 35,
      pad: 4,
    },
    font: {
      family: "Helvetica",
      // color: "#787878",
      // color: "black",
      size: 18,
      // weight: "bold"
      // face: "Bold",
    },
    xaxis: {
      range: [0.49, 1.055],
      title: {
        text: "Professional Drive  ⟶",
        font: {
          color: "black",
        },
      },
      gridcolor: "rgba(0, 0, 0, 0.0)",
      zeroline: false,
      showticks: false, // Hide the x-axis ticks
      showticklabels: false, // Hide the x-axis tick labels
      fixedrange: true,
    },
    yaxis: {
      fixedrange: true,
      range: [-0.055, 0.51],
      title: {
        text: "Technical Acumen  ⟶",
        font: {
          color: "black",
        },
      },
      gridcolor: "rgba(0, 0, 0, 0.0)",
      zeroline: false,
      showticks: false, // Hide the y-axis ticks
      showticklabels: false, // Hide the y-axis tick labels
    },
    shapes: [
      // {
      //   type: "line",
      //   x0: 0.5,
      //   x1: 0.5,
      //   y0: -0.045,
      //   y1: 1.045,
      //   line: {
      //     color: "gray",
      //     width: 1,
      //   },
      // },
      // {
      //   type: "line",
      //   x0: -0.045,
      //   x1: 1.045,
      //   y0: 0.5,
      //   y1: 0.5,
      //   line: {
      //     color: "gray",
      //     width: 1,
      //   },
      // },
      {
        type: "rect",
        x0: 0.49,
        x1: 1.055,
        y0: -0.055,
        y1: 0.51,
        line: {
          color: "black",
          width: 2,
        },
      },
      {
        type: "line",
        x0: tVal - (5 / 3) * 1.045,
        y0: 1.045,
        x1: 1.045,
        y1: ((tVal - 1.045) * 3) / 5,
        line: {
          color: "rgba(0.01,0.01,0.01,0.2)", // Line color
          width: 1,
          // dash: "dash", // Dashed line style
        },
      },
      {
        type: "path", // Type set to 'polygon' for the shaded triangle
        path: `M 1.045 ${((tVal - 1.045) * 3) / 5} L  ${tVal - (5 / 3) * 1.045} 1.045 L 1.045 1.045 Z `, // Define a path for a triangle
        fillcolor: "rgba(0.25, 0.25, 0.25, 0.07)", // Shaded area color (blue with opacity)
        line: {
          color: "rgba(0,0,0,0.0)", // Border color of the shaded area
          width: 0, // Don't draw a line
        },
      },
    ],
    aspectratio: {
      x: 1,
      y: 1,
    },
    showgrid: false,
    annotations: [
      {
        x: 0.5,
        y: 0.53,
        text: chartData?.length ? "" : "No data to show", // Conditional text
        showarrow: false,
        xanchor: "center",
        yanchor: "middle",
        font: {
          size: 25,
          color: "rgba(0.01,0.01,0.01,0.4)",
          weight: "bold",
        },
      },

      {
        x: 0.98, // X-coordinate for the label in the lower-right quadrant
        y: -0.025, // Y-coordinate for the label in the lower-right quadrant
        text: "Professionals",
        showarrow: false,
        font: {
          size: 20, // Font size
          color: chartConfig.QColors[2], // Font color
          weight: "bold", // Font weight
        },
      },
      {
        x: targetTextX + 0.01,
        y: targetTextY,
        text: "Target Score (<b>" + targetScore + "</b>)",
        showarrow: false,
        textangle: 30, // Set the angle of the text (in degrees)
        font: {
          size: 14, // Font size
          color: "rgba(0.01,0.01,0.01,0.4)", // Font color
          weight: "bold", // Font weight
        },
      },
    ],
  };

  // Define learnersLayout options
  const learnersLayout = {
    hovermode: "closest",
    autosize: true,
    margin: {
      l: 35,
      r: 35,
      b: 35,
      t: 35,
      pad: 4,
    },
    font: {
      family: "Helvetica",
      // color: "#787878",
      // color: "black",
      size: 18,
      // weight: "bold"
      // face: "Bold",
    },
    xaxis: {
      range: [-0.055, 0.51],
      title: {
        text: "Professional Drive  ⟶",
        font: {
          color: "black",
        },
      },
      gridcolor: "rgba(0, 0, 0, 0.0)",
      zeroline: false,
      showticks: false, // Hide the x-axis ticks
      showticklabels: false, // Hide the x-axis tick labels
      fixedrange: true,
    },
    yaxis: {
      fixedrange: true,
      range: [-0.055, 0.51],
      title: {
        text: "Technical Acumen  ⟶",
        font: {
          color: "black",
        },
      },
      gridcolor: "rgba(0, 0, 0, 0.0)",
      zeroline: false,
      showticks: false, // Hide the y-axis ticks
      showticklabels: false, // Hide the y-axis tick labels
    },
    shapes: [
      // {
      //   type: "line",
      //   x0: 0.5,
      //   x1: 0.5,
      //   y0: -0.045,
      //   y1: 1.045,
      //   line: {
      //     color: "gray",
      //     width: 1,
      //   },
      // },
      // {
      //   type: "line",
      //   x0: -0.045,
      //   x1: 1.045,
      //   y0: 0.5,
      //   y1: 0.5,
      //   line: {
      //     color: "gray",
      //     width: 1,
      //   },
      // },
      {
        type: "rect",
        x0: -0.055,
        x1: 0.51,
        y0: -0.055,
        y1: 0.51,
        line: {
          color: "black",
          width: 2,
        },
      },
    ],
    aspectratio: {
      x: 1,
      y: 1,
    },
    showgrid: false,
    annotations: [
      {
        x: 0.5,
        y: 0.53,
        text: chartData?.length ? "" : "No data to show", // Conditional text
        showarrow: false,
        xanchor: "center",
        yanchor: "middle",
        font: {
          size: 25,
          color: "rgba(0.01,0.01,0.01,0.4)",
          weight: "bold",
        },
      },

      {
        x: 0, // X-coordinate for the label in the lower-left quadrant
        y: -0.025, // Y-coordinate for the label in the lower-left quadrant
        text: "Learners",
        showarrow: false,
        font: {
          size: 20, // Font size
          color: chartConfig.QColors[0],
          // weight: "bold", // Font weight
        },
      },
      {
        x: targetTextX,
        y: targetTextY,
        // text: "Target Score (<b>" + targetScore + "4" + "</b>)",
        text: `Target Score (<b>${targetScore}4</b>)`,
        showarrow: false,
        textangle: 30, // Set the angle of the text (in degrees)
        font: {
          size: 14, // Font size
          color: "rgba(0.01,0.01,0.01,0.4)", // Font color
          weight: "bold", // Font weight
        },
      },
    ],
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Plot
        data={[trace]}
        layout={
          quadrantFilter === "all"
            ? allLayout
            : quadrantFilter === "Performers"
            ? performersLayout
            : quadrantFilter === "Experts"
            ? expertsLayout
            : quadrantFilter === "Professionals"
            ? professionalsLayout
            : quadrantFilter === "Learners"
            ? learnersLayout
            : allLayout
        }
        style={{ width: "100%", height: "100%" }}
        containerId="ScatterPlot"
        config={{
          displayModeBar: false,
          displaylogo: false,
          modeBarButtonsToRemove: chartConfig.modeBarButtonsToRemove,
          responsive: true,
        }}
      />
    </div>
  );
};

export default ScatterPlot;
