import React, { useEffect, useState } from "react";
import ReusableDialog from "../../../components/controls/ReusableDialog";
import { Box, Typography, Skeleton, Grid, Link } from "@mui/material";
import SearchBar from "../../../components/controls/search";
import { useDispatch, useSelector } from "react-redux";
import role from "../../../redux/actions/role";
import { useNavigate, useParams } from "react-router-dom";

const sectionMapper = {
  Presentation: "presentation",
  Coding: "coding",
  Technical: "L2",
};

const SelectTestModal = ({ open, setOpen, testList, sectionType, loading, dimension, selectedTest }) => {
  const [selectedTestId, setSelectedTestId] = useState(null);
  const [initialTestId, setInitialTestId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const authState = useSelector((state) => state.auth);
  const roleState = useSelector((state) => state.role);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [loadingApi, setLoadingApi] = useState(false);
  const navigate = useNavigate();

  const handleSelectTest = (testId) => {
    setSelectedTestId(testId);
  };

  useEffect(() => {
    if (selectedTest) {
      const testId = selectedTest.isVideoBehavioral ? -1 : selectedTest.testId || 0;
      setSelectedTestId(testId);
      setInitialTestId(testId);
    }
  }, [selectedTest]);

  const filteredTests = [
    ...(sectionType === "Presentation" && testList?.standardTests?.presentation?.isVideoBehavioral
      ? [
          {
            testId: -1,
            title: "Standard Video Test",
            description: testList?.standardTests?.presentation?.description || "",
            timeDuration: testList?.standardTests?.presentation?.timeDuration,
            questionCount: testList?.standardTests?.presentation?.questionCount,
            skills: testList?.standardTests?.presentation?.skills,
          },
        ]
      : []),
    ...(sectionType === "Presentation" && !testList?.standardTests?.presentation?.isVideoBehavioral
      ? [
          {
            testId: 0,
            title: "Standard MCQ Test",
            description: testList?.standardTests?.presentation?.description || "",
            timeDuration: testList?.standardTests?.presentation?.timeDuration,
            questionCount: testList?.standardTests?.presentation?.questionCount,
            skills: testList?.standardTests?.presentation?.skills,
          },
        ]
      : []),
    ...(sectionType !== "Presentation"
      ? [
          {
            testId: 0,
            title: "Standard Test",
            description: testList.standardTests[sectionType.toLowerCase()]?.description || "",
            timeDuration: testList.standardTests[sectionType.toLowerCase()]?.timeDuration,
            questionCount: testList.standardTests[sectionType.toLowerCase()]?.questionCount,
            skills: testList.standardTests[sectionType.toLowerCase()]?.skills,
          },
        ]
      : []),
    ...testList?.customTests,
  ].filter((test) => test.title.toLowerCase().includes(searchTerm.toLowerCase()));

  const getSkillFullNames = (skills) => {
    return skills
      .map((skill) => authState?.skillsConfig[skill]?.fullName || (dimension && dimension[skill]) || skill)
      .join(", ");
  };

  const handleSave = () => {
    setLoadingApi(true);
    const payload = {
      roleID: id,
      data: {
        type: selectedTestId === -1 ? "videoBehavior" : sectionMapper[sectionType],
        testId: selectedTestId,
        testType: selectedTestId === 0 || selectedTestId === -1 ? "standard" : "custom",
      },
    };
    dispatch(role.updateCustomTest(payload));
  };

  useEffect(() => {
    if (roleState?.updateCustomTestSuccess?.message) {
      setOpen(false);
      dispatch(role.getTestingRequirements({ id }));
      setLoadingApi(false);
    }
  }, [roleState?.updateCustomTestSuccess]);

  const handleClose = () => {
    setOpen(false);
    setSelectedTestId(initialTestId);
  };

  return (
    <ReusableDialog
      open={open}
      setOpen={setOpen}
      title={`Select a Test for the ${sectionType === "Presentation" ? "Behavioral" : sectionType} Section`}
      fullWidth={true}
      maxWidth={"md"}
      onClose={handleClose}
      content={
        loading ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Skeleton variant="rectangular" width="40%" height={50} animation="wave" />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="rectangular" width="100%" height={300} animation="wave" />
            </Grid>
          </Grid>
        ) : (
          <Box>
            {/* <TextField
              variant="outlined"
              placeholder="Search Test"
              fullWidth
              size="small"
              sx={{ mb: 2 }}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
             */}
            <SearchBar
              placeholdertitle={"Search Test"}
              onChange={(e) => setSearchTerm(e.target.value)}
              value={searchTerm}
              //   width={"100%"}
            />
            {filteredTests.map((test) => (
              <Box
                key={test.testId}
                onClick={() => handleSelectTest(test.testId)}
                sx={{
                  p: 2,
                  my: 1,
                  borderRadius: 2,
                  border: selectedTestId === test.testId ? "2px solid #1976d2 !important" : "1px solid transparent",
                  cursor: "pointer",
                  "&:hover": {
                    border: "1px solid #1976d2",
                  },
                }}
              >
                <Typography variant="h6">{test.title}</Typography>
                <Typography variant="body2" color="textSecondary" sx={{ display: "flex", alignItems: "center" }}>
                  {test.questionCount ? test.questionCount + " Questions" : ""}{" "}
                  {test.questionCount && test.timeDuration ? (
                    <span
                      style={{
                        fontSize: "8px",
                        margin: "0px 5px",
                      }}
                    >
                      &#x23FA;
                    </span>
                  ) : (
                    ""
                  )}{" "}
                  {test.timeDuration ? test.timeDuration + " Minutes" : ""}
                </Typography>
                {test.skills && test.testId !== -1 && (
                  <Typography variant="body2">
                    Objective Test evaluating {test.skills.length} skills: {getSkillFullNames(test.skills)}
                  </Typography>
                )}
                {test.skills && test.testId === -1 && (
                  <Typography variant="body2">
                    Video interview-based test evaluating {test.skills.length} skills: {getSkillFullNames(test.skills)}
                  </Typography>
                )}
                {test.description && (
                  <Typography color="textSecondary" sx={{ fontSize: 13 }}>
                    {test.description}
                  </Typography>
                )}
              </Box>
            ))}
            {testList?.customTests.length === 0 && (
              <Box sx={{ p: 2 }}>
                <Typography variant="body2" color="textSecondary" display={"contents"}>
                  Your account does not have any custom tests available. Raise a{" "}
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() =>
                      navigate("/accountSettings", {
                        state: { selectedSection: "support" },
                      })
                    }
                  >
                    support ticket
                  </Link>{" "}
                  if you need a custom test for this section.
                </Typography>
              </Box>
            )}
          </Box>
        )
      }
      actions={[
        {
          text: "Cancel",
          color: "btnCls1",
          variant: "outlined",
          onClick: handleClose,
        },
        {
          text: "Save",
          color: selectedTestId === initialTestId || loadingApi ? "disablebtn1" : "btn1",
          onClick: handleSave,
          disabled: selectedTestId === initialTestId || loadingApi,
        },
      ]}
    />
  );
};

export default SelectTestModal;
