import React, { useEffect, useState } from "react";
import ReusableDialog from "../../../../components/controls/ReusableDialog";
import { Box, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import chart from "../../../../redux/actions/chart";
import { useParams } from "react-router-dom";
import CandidateComparison from "../../../../components/chart/CandidateComparison";
import Skeleton from "@mui/material/Skeleton";
import { Grid } from "@mui/material";
import Stack from "@mui/material/Stack";

const RDComparisonChart = ({ open, handleClose, inviteIds }) => {
  const dispatch = useDispatch();
  const { linkId, id } = useParams();
  const chartStore = useSelector((state) => state.chart);

  const [chartData, setChartData] = useState(null);
  const [skill, setSkill] = useState(null);
  const [skillWeightage, setSkillWeightage] = useState(null);
  const [sectionToTest, setSectionToTest] = useState(null);

  useEffect(() => {
    const filteredInviteIds = inviteIds?.filter((inviteId) => inviteId);
    const inviteCount = filteredInviteIds?.length;

    if (inviteCount > 0) {
      const payload = {
        linkId,
        data: {
          roleId: id,
          inviteIds: filteredInviteIds,
        },
      };
      dispatch(chart.getRDComparisonChart(payload));
    } else {
      const payload = {
        linkId,
        data: {
          roleId: id,
        },
      };
      dispatch(chart.getRDComparisonChart(payload));
    }
  }, [inviteIds]);

  // get data for chart
  useEffect(() => {
    if (chartStore?.getRDComparisonChartSuccess) {
      setChartData(chartStore?.getRDComparisonChartSuccess);
    }
  }, [chartStore?.getRDComparisonChartSuccess]);

  // set data for chart
  useEffect(() => {
    if (chartData) {
      const skills = chartData?.roleDetails?.skillDetails?.map((item) => item.skill);
      const skillWeightages = chartData?.roleDetails?.skillDetails?.map((item) => item.weightage);
      const sectionToTest = chartData?.roleDetails?.testingRequirement;
      setSkill(skills);
      setSkillWeightage(skillWeightages);
      setSectionToTest(sectionToTest);
    }
  }, [chartData]);

  // close modal
  const handleModalClose = () => {
    handleClose();
    setChartData(null);
    dispatch(chart.clearRDComparisonChartSuccess());
  };

  return (
    <>
      <ReusableDialog
        fullWidth={true}
        maxWidth="lg"
        open={open}
        handleClose={handleModalClose}
        title={
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h6">Comparison Chart</Typography>
            <IconButton
              // className={classes.closeButton}
              onClick={handleModalClose}
              // onClick={handleModalClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        }
        content={
          <Box px={5}>
            {chartData && skill && skillWeightage && sectionToTest ? (
              <CandidateComparison
                sectionToTest={sectionToTest}
                skill={skill}
                skillWeightage={skillWeightage}
                datefilter={false}
                chartData={chartData?.data}
              />
            ) : (
              <Grid container spacing={1}>
                <Grid item xs={12} md={8}>
                  <Skeleton sx={{ bgcolor: "grey.300" }} variant="rectangular" width="95%" height="700px" />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Stack spacing={5}>
                    <Skeleton variant="rectangular" width={"100%"} height={60} />
                    <Skeleton variant="rectangular" width={"100%"} height={60} />
                    <Skeleton variant="rectangular" width={"100%"} height={60} />
                  </Stack>
                  <Stack spacing={1} mt={5}>
                    <Skeleton variant="text" sx={{ fontSize: "18px" }} />
                    <Skeleton variant="text" sx={{ fontSize: "18px" }} />
                    <Skeleton variant="text" sx={{ fontSize: "18px" }} />
                    <Skeleton variant="text" sx={{ fontSize: "18px", width: "50%" }} />
                  </Stack>
                  <Stack spacing={1} mt={2}>
                    <Skeleton variant="text" sx={{ fontSize: "18px" }} />
                    <Skeleton variant="text" sx={{ fontSize: "18px" }} />
                    <Skeleton variant="text" sx={{ fontSize: "18px" }} />
                    <Skeleton variant="text" sx={{ fontSize: "18px", width: "50%" }} />
                  </Stack>
                  <Stack spacing={1} mt={2}>
                    <Skeleton variant="text" sx={{ fontSize: "18px" }} />
                    <Skeleton variant="text" sx={{ fontSize: "18px" }} />
                    <Skeleton variant="text" sx={{ fontSize: "18px" }} />
                    <Skeleton variant="text" sx={{ fontSize: "18px", width: "50%" }} />
                  </Stack>
                </Grid>
              </Grid>
            )}
          </Box>
        }
      />
    </>
  );
};

export default RDComparisonChart;
