import React, { useEffect, useState } from "react";
import { Card, Grid, Link, Skeleton, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ResumeProcessingQueue from "./ResumeProcessingQueue";
import { useDispatch, useSelector } from "react-redux";
import auth from "../../../redux/actions/auth";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import PhotoOutlinedIcon from "@mui/icons-material/PhotoOutlined";
import Button from "../../../components/controls/Button";
import { Button as MuiButton } from "@mui/material";
import CustomBreadcrumbs from "../../../components/controls/CustomBreadcrumbs";
import { theme } from "../../../styles/theme";

const useStyles = makeStyles((theme) => ({
  card: {
    boxShadow: "none !important",
    // borderRadius: theme.shape.borderRadius,
    overflow: "hidden",
    marginBottom: theme.spacing(3),
  },
  cardBody: {
    padding: theme.spacing(3),
  },

  kbDataBox: {
    width: "100%",
    flex: 1,
  },
  kbModalDataTitle: {
    marginBottom: theme.spacing(1),
  },
  kbDataTitle: {
    marginBottom: 0,
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeight600,
  },
  fileUploadBox: {
    border: `1px dashed gray`,
    backgroundColor: "#f0f2f7",
    borderRadius: theme.shape.borderRadius,
    minHeight: theme.spacing(15),
    position: "relative",
    overflow: "hidden",
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginBottom: "20px",
  },
  fileUploadInput: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: 0,
    left: 0,
    opacity: 0,
    cursor: "pointer",
  },
  fileLink: {
    // color: theme.palette.primary.main,
    textDecoration: "underline",
    marginLeft: theme.spacing(1),
    color: "gray",
  },
  fileAtcBox: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    justifyContent: "space-between",
  },
  fileData: {
    width: theme.spacing(13),
    height: theme.spacing(8.5),
    backgroundSize: "cover",
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: theme.typography.pxToRem(30),
    color: theme.palette.text.secondary,
    padding: theme.spacing(0.5),
  },
  fileDetail: {
    flex: 1,
    width: `calc(100% - ${theme.spacing(15 + 2)}px)`,
  },
  fileDetailH6: {
    wordBreak: "break-all",
    fontSize: "16px !important",
    fontWeight: theme.typography.fontWeight500,
    lineHeight: theme.typography.pxToRem(20),
    marginBottom: theme.spacing(1),
  },
  fileDetailP: {
    fontSize: "14px !important",
    color: theme.palette.text.secondary,
    lineHeight: "initial",
    fontWeight: theme.typography.fontWeightRegular,
    marginBottom: theme.spacing(1),
  },
  fileActions: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  fileActionBtn: {
    fontSize: "14px !important",
    color: theme.palette.text.secondary,
    lineHeight: theme.typography.pxToRem(20),
    fontWeight: theme.typography.fontWeightRegular,
    marginBottom: 0,
    padding: 0,
    backgroundColor: "transparent",
    border: "none",
    textDecoration: "underline",
    marginRight: theme.spacing(2),
    cursor: "pointer",
  },
  roletype1: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "18px !important",
    lineHeight: "24px !important",
    color: " #2F2F2F",
    // textAlign:'center',
    paddingTop: "10px",
  },
}));

const BulkResumes = () => {
  const classes = useStyles();
  const [selectedfile, setSelectedFile] = useState([]);
  const [Files, setFiles] = useState([]);
  const userId = localStorage.getItem("userid");
  const dispatch = useDispatch();
  const authStore = useSelector((state) => state.auth);
  const [dataFlag, setDataFlag] = useState(false);
  const [processingResumes, setProcessingResumes] = useState([]);

  //Filters
  const [filteredFiles, setFilteredFiles] = useState([...processingResumes]);
  const [filter, setFilter] = useState("all");

  useEffect(() => {
    setFilteredFiles(filterFiles());
  }, [processingResumes, filter]);

  const handleButtonClick = (newFilter) => {
    setFilter(newFilter);
  };

  const filterFiles = () => {
    switch (filter) {
      case "readyToAdd":
        return processingResumes.filter((item) => item.status === "complete");
      case "incomplete":
        return processingResumes.filter((item) => item.status === "incomplete");
      case "editRequired":
        return processingResumes.filter(
          (item) =>
            item.status !== "complete" &&
            item.status !== "incomplete" &&
            item.status !== "pending" &&
            item.status !== "processing"
        );
      default:
        return processingResumes;
    }
  };

  useEffect(() => {
    const fetchData = () => {
      let payload = {
        userId,
      };
      dispatch(auth.getPendingResumeList(payload));
    };

    fetchData();

    const intervalId = setInterval(fetchData, 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, [userId, dispatch]);

  useEffect(() => {
    if (authStore.getPendingResumeListSuccess) {
      setProcessingResumes(authStore.getPendingResumeListSuccess);
    }
  }, [authStore?.getPendingResumeListSuccess]);

  const [processingQueue, setProcessingQueue] = useState([]);
  const [fileUploadInProcess, setFileUploadInProcess] = useState(false);

  // Delay function
  function delay(ms) {
    return new Promise((next) => {
      setTimeout(() => {
        next();
      }, ms);
    });
  }

  async function uploadFilesInQueue(files) {
    setFileUploadInProcess(true);
    for (let item of files) {
      let payload = {
        recruiterId: userId,
        resumeData: [
          {
            fileName: item?.fileName,
            fileData: item?.fileData,
            filetype: item?.filetype,
          },
        ],
      };

      if (!item.status) {
        dispatch(auth.uploadBulkResume(payload));
      }

      await delay(5000); // delay
    }
    setFileUploadInProcess(false);
  }

  useEffect(() => {
    if (processingQueue.length !== 0 && fileUploadInProcess === false) {
      let processingQueueClone = [...processingQueue];
      let takeOneQueue = processingQueueClone.splice(0, 1);
      uploadFilesInQueue(takeOneQueue[0]);
      setProcessingQueue(processingQueueClone);
    }
  }, [processingQueue, fileUploadInProcess]);

  useEffect(() => {
    if (authStore?.uploadBulkResumeSuccess) {
      if (authStore?.uploadBulkResumeSuccess.length !== 0) {
        // eslint-disable-next-line
        authStore?.uploadBulkResumeSuccess.map((file) => {
          if (!file.error && file.status) {
            let fileData = {
              originalFileName: file.fileName,
              status: "pending",
            };

            let fileCopy = [...Files];

            fileCopy = fileCopy.filter((i) => i.fileName !== file.fileName);
            setFiles(fileCopy);
            setProcessingResumes([...processingResumes, fileData]);
          }

          if (file.error && file.message) {
            let fileCopy = [...Files];
            // eslint-disable-next-line
            fileCopy.map((i) => {
              if (i.fileName === file.fileName) {
                i["status"] = file.message;
                i["resError"] = file.error;
              }
            });
            setFiles(fileCopy);
          }
        });
        dispatch(auth.clearUploadBulkResumeSuccess());
      }
    }
  }, [authStore?.uploadBulkResumeSuccess]);

  useEffect(() => {
    if (Files.length !== 0 && !fileUploadInProcess) {
      setTimeout(() => {
        let files = Files.filter((i) => !i.status);
        setFiles(files);
      }, 30000);
    }
  }, [fileUploadInProcess]);

  const filesizes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const InputChange = (e) => {
    let images = [];
    for (let i = 0; i < e.target.files.length; i++) {
      images.push(e.target.files[i]);
      let reader = new FileReader();
      let file = e.target.files[i];

      reader.onloadend = () => {
        setSelectedFile((preValue) => {
          return [
            ...preValue,
            {
              id: Date.now(),
              fileName: e.target.files[i].name,
              filetype: e.target.files[i].type,
              fileData: reader.result,
              datetime: e.target.files[i].lastModifiedDate.toLocaleString("en-IN"),
              filesize: filesizes(e.target.files[i].size),
            },
          ];
        });
      };
      if (e.target.files[i]) {
        reader.readAsDataURL(file);
      }
    }
  };

  const DeleteSelectFile = (index) => {
    const result = [...selectedfile];
    result.splice(index, 1);
    setSelectedFile([...result]);
  };

  const FileUploadSubmit = async (e) => {
    e.preventDefault();
    e.target.reset();
    if (selectedfile.length > 0) {
      const filesToUpload = selectedfile.filter((file) => {
        return !(file.filesize.endsWith("MB") && parseFloat(file.filesize) > 5);
      });

      const filesNotToUpload = selectedfile.filter((file) => {
        return file.filesize.endsWith("MB") && parseFloat(file.filesize) > 5;
      });

      if (filesToUpload.length > 0) {
        setFiles([...Files, ...filesToUpload]);

        let newFiles = [...processingQueue];
        newFiles.push([...filesToUpload]);
        setProcessingQueue(newFiles);
      } else {
        alert("All selected files are larger than 5MB and cannot be uploaded.");
      }

      setSelectedFile(filesNotToUpload);
    } else {
      alert("Please select file");
    }
  };

  useEffect(() => {
    // validate feature access
    dispatch(auth.validateFeatureAccess("allowImportResumes"));

    const timeout = setTimeout(() => {
      setDataFlag(true);
    }, 3000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className={classes.kbDataBox}>
      <CustomBreadcrumbs
        links={[
          { name: "Home", path: "/home" },
          { name: "Candidates", path: "/candidatelist" },
          { name: "Import Resumes" },
        ]}
      />
      <Grid container justifyContent="center">
        <Grid item xs={12} md={10}>
          <Card className={classes.card}>
            <Card className={classes.cardBody}>
              <Typography className={classes.roletype1}>Import Resumes</Typography>
              {authStore?.validateFeatureAccessSuccess?.data.access === false ? (
                <Grid item xs={12}>
                  <Typography sx={{ color: "red", fontSize: "14px", textAlign: "left", mt: 2 }}>
                    This feature is not available in your subscription plan. To access this feature contact{" "}
                    <a
                      style={{ textDecoration: "none", color: theme.palette.btnPrimary.main }}
                      href="mailto:support@techscore.ai"
                    >
                      support@techscore.ai
                    </a>
                    .
                  </Typography>
                </Grid>
              ) : null}
              <p
                style={{
                  borderBottom: "1px solid #C4C4C4",
                  paddingTop: "5px",
                }}
              />
              <div className={classes.kbDataBox}>
                <div className={classes.kbModalDataTitle}>
                  <Typography className={classes.viewText} sx={{ fontWeight: "bold", marginBottom: "20px" }}>
                    Upload Multiple Resumes
                  </Typography>
                </div>
                <form onSubmit={FileUploadSubmit}>
                  <div className={classes.fileUploadBox}>
                    <input
                      type="file"
                      id="fileupload"
                      className={classes.fileUploadInput}
                      onChange={InputChange}
                      multiple
                    />
                    <span>
                      Drag and drop or
                      <span className={classes.fileLink}>Choose your files</span>
                    </span>
                    <span>File size max. 5MB</span>
                  </div>
                  <div>
                    {selectedfile.map((data, index) => {
                      const { fileName, datetime, filesize, filetype } = data;
                      const isFileSizeTooLarge = filesize.endsWith("MB") && parseFloat(filesize) > 5;
                      return (
                        <div className={classes.fileAtcBox} key={index}>
                          <div className={classes.fileData}>
                            {/* <img src={fileData} alt="" /> */}
                            {filetype === "application/pdf" ||
                            filetype === "text/plain" ||
                            filetype === "application/msword" ||
                            filetype === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                              <DescriptionOutlinedIcon style={{ width: "50%", height: "50%" }} />
                            ) : (
                              <PhotoOutlinedIcon style={{ width: "50%", height: "50%" }} />
                            )}
                          </div>
                          <div className={classes.fileDetail}>
                            <Typography variant="h6" className={classes.fileDetailH6}>
                              {fileName}
                            </Typography>
                            <Typography className={classes.fileDetailP}>
                              <span style={{ marginRight: "20px", color: isFileSizeTooLarge ? "red" : "inherit" }}>
                                {filesize}
                              </span>
                              <span className={classes.ml2}>Modified on {datetime}</span>
                            </Typography>
                            <div className={classes.fileActions}>
                              <button
                                type="button"
                                className={classes.fileActionBtn}
                                onClick={() => DeleteSelectFile(index)}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  {selectedfile.length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        marginTop: "40px",
                        marginBottom: "20px",
                      }}
                    >
                      <Button
                        variant="contained"
                        text={`Upload ${selectedfile?.length} ${selectedfile?.length === 1 ? "File" : "Files"}`}
                        color="btn1"
                        width="175px"
                        type="submit"
                        disabled={selectedfile?.every((file) => {
                          return file?.filesize?.endsWith("MB") && parseFloat(file?.filesize) > 5;
                        })} // disable button if all files are larger than 5MB
                        sx={{ fontSize: "16px !important", height: "35px !important" }}
                      />
                      <Typography
                        style={{
                          marginLeft: "30px",
                          color: "#1789fc",
                          width: "150px",
                          fontSize: "16px",
                          cursor: "pointer",
                        }}
                      >
                        <Link
                          style={{ textDecoration: "none", fontWeight: "500" }}
                          onClick={() => {
                            setSelectedFile([]);
                          }}
                        >
                          Remove All
                        </Link>
                      </Typography>
                    </div>
                  )}
                </form>
                {Files.length > 0 && (
                  <>
                    <Typography className={classes.viewText} sx={{ fontWeight: "bold", marginBottom: "5px" }}>
                      Do not close the window. Resume upload in progress…
                    </Typography>
                    <Typography
                      className={classes.viewText}
                      sx={{ marginBottom: "20px", fontSize: "15px", color: "#5e5e62" }}
                    >
                      This list will update automatically. Any file that cannot be uploaded will be removed.
                    </Typography>
                  </>
                )}
                {Files.length > 0 && (
                  <div>
                    {Files.map((data, index) => {
                      const { fileName, fileData, datetime, filesize, filetype, status, resError } = data;
                      return (
                        <div className={classes.fileAtcBox} key={index}>
                          <div className={classes.fileData}>
                            {filetype === "application/pdf" ||
                            filetype === "text/plain" ||
                            filetype === "application/msword" ||
                            filetype === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                              <DescriptionOutlinedIcon style={{ width: "50%", height: "50%" }} />
                            ) : (
                              <PhotoOutlinedIcon style={{ width: "50%", height: "50%" }} />
                            )}
                          </div>
                          <div className={classes.fileDetail}>
                            <Typography variant="h6" className={classes.fileDetailH6}>
                              {fileName}
                            </Typography>
                            <Typography className={classes.fileDetailP}>
                              <span style={{ marginRight: "20px" }}>{filesize}</span>
                              <span className={classes.ml3}>Modified on {datetime}</span>
                            </Typography>
                            <div className={classes.fileActions}>
                              <a href={fileData} className={classes.fileActionBtn} style={{ textDecoration: "none" }}>
                                {status ? (
                                  <span style={{ color: resError ? "red" : "#b0b0b0" }}>{status}</span>
                                ) : (
                                  "Queued"
                                )}
                              </a>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                <p
                  style={{
                    borderBottom: "1px solid #C4C4C4",
                    paddingTop: "5px",
                  }}
                />
                <div className={classes.kbModalDataTitle}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <Typography className={classes.viewText} sx={{ fontWeight: "bold" }}>
                      Resumes Processing
                    </Typography>
                    <div className={classes.viewText}>
                      <div>
                        <MuiButton
                          size="medium"
                          sx={{
                            color: filter === "all" ? "primary" : "gray",
                            textTransform: "capitalize",
                            fontWeight: filter === "all" ? `bolder` : "normal",
                            fontSize: "15px",
                          }}
                          onClick={() => handleButtonClick("all")}
                        >
                          All Files
                        </MuiButton>
                        <MuiButton
                          size="medium"
                          sx={{
                            color: filter === "readyToAdd" ? "primary" : "gray",
                            textTransform: "capitalize",
                            fontWeight: filter === "readyToAdd" ? `bolder` : "normal",
                            fontSize: "15px",
                          }}
                          onClick={() => handleButtonClick("readyToAdd")}
                        >
                          Ready to Add
                        </MuiButton>
                        <MuiButton
                          size="medium"
                          sx={{
                            color: filter === "incomplete" ? "primary" : "gray",
                            textTransform: "capitalize",
                            fontWeight: filter === "incomplete" ? `bolder` : "normal",
                            fontSize: "15px",
                          }}
                          onClick={() => handleButtonClick("incomplete")}
                        >
                          Incomplete
                        </MuiButton>
                        <MuiButton
                          size="medium"
                          sx={{
                            color: filter === "editRequired" ? "primary" : "gray",
                            textTransform: "capitalize",
                            fontWeight: filter === "editRequired" ? `bolder` : "normal",
                            fontSize: "15px",
                          }}
                          onClick={() => handleButtonClick("editRequired")}
                        >
                          Edit Required
                        </MuiButton>
                      </div>
                    </div>
                  </div>
                  {/* dataFlag */}
                  {filteredFiles.length > 0 ? (
                    <ResumeProcessingQueue processingResumes={filteredFiles || []} />
                  ) : !dataFlag ? (
                    <>
                      <div
                        style={{
                          marginBottom: "30px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "40px",
                        }}
                      >
                        <Skeleton variant="rounded" height={180} style={{ flex: 1, borderRadius: "5px" }} />
                      </div>
                    </>
                  ) : (
                    <Typography
                      sx={{
                        margin: "auto",
                        display: "flex",
                        justifyContent: "center",
                        height: "100px",
                        alignItems: "center",
                      }}
                    >
                      {filter === "editRequired" || filter === "incomplete" || filter === "readyToAdd"
                        ? "Nothing in the queue."
                        : "All work done! Nothing in the queue."}
                    </Typography>
                  )}

                  <p
                    style={{
                      borderBottom: "1px solid #C4C4C4",
                      paddingTop: "5px",
                      marginTop: "40px",
                    }}
                  />
                </div>
              </div>
            </Card>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default BulkResumes;
