import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Typography, Link, Card, useMediaQuery } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { useNavigate } from "react-router-dom";
import { theme } from "../styles/theme";
import role from "../redux/actions/role";
import moment from "moment";
import Sections from "../components/section/Sections";
import SimpleSnackbar from "../components/controls/ToastMessage";
import ReactiveModel from "../components/controls/ReactiveModel";

const useStyles = makeStyles({
  scoreCard: {
    backgroundColor: `${theme.palette.primary.light} !important`,
    height: "100%",
  },
  scoreCard1: {
    backgroundColor: `#F8F8F8 !important`,
    height: "100%",
  },
  sectionDisable: {
    backgroundColor: `${theme.palette.black.gray} !important`,
    height: "100%",
  },
  primaryText: {
    color: theme.palette.primary.main,
    fontWeight: "500 !important",
    fontSize: "14px !important",
  },
  disablePrimaryText: {
    color: theme.palette.black.light,
    fontWeight: "500 !important",
    fontSize: "14px !important",
  },
  link: {
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    // fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0em",
    textalign: "left",
    textDecoration: "none !important",
    "& .css-1ps4owl-MuiTypography-root-MuiLink-root": {
      textDecoration: "none !important",
      color: "#1789FC !important",
    },
  },
  disabledCardLink: {
    cursor: "default",
    pointerEvents: "none",
    //color: "#787878 !important",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0em",
    textalign: "left",
  },
  disabledLink: {
    color: "#787878 !important",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0em",
    textalign: "left",
    textDecoration: "none !important",
    // cursor: 'none',
  },
  scoresCalculatedByCardTitle: {
    marginBottom: "12px !important",
  },
  sectionTitle: {
    //marginBottom: 6,
    padding: "4px",
    // fontSize: "16px",
    // lineHeight: "24px",
    [theme.breakpoints.down("sm")]: {
      // fontSize: "24px",
      // lineHeight: "32px"
      marginBottom: 2,
      padding: "2px",
    },
  },
  grayText: {
    color: "#787878",
  },
  grayText1: {
    color: "#C4C4C4",
  },
  subTitle: {
    marginBottom: "6px",
    padding: "4px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "3px",
      padding: "3px",
    },
  },
  incompleteTxt: {
    fontWeight: "normal",
  },
  linkCursor: {
    cursor: "pointer",
  },
  sectionChildContainer: {
    display: "flex",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
  },
  roundedCircle: {
    height: "12px",
    width: "12px",
    margin: "2px 3px 2px 14px",
    border: "1px solid #C4C4C4",
    borderRadius: "50%",
  },
  roundedText: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    color: "#787878",
  },
  sectionMainContainer: {
    height: "20px",
    display: "flex",
    margin: "0px 0px 14px -10px",
    [theme.breakpoints.down("sm")]: {
      margin: "0px 0px 14px -10px",
    },
  },
  roletype: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "14px !important",
    lineHeight: "24px !important",
    color: " #787878",
    // textAlign:'center',
    paddingTop: "0px",
  },
  textSty: theme.typography.b2Regular,
  linksty: theme.typography.link2,
  mediumsty: theme.typography.b2Medium,
  h4sty: theme.typography.h4,
});

function QuizSection(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useNavigate();
  const authState = useSelector((state) => state.auth);
  const roleState = useSelector((state) => state.role);
  // const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));
  const [snacktext, setsnacktext] = useState("");
  const [open, setOpen] = useState(false);
  const [reset, setReset] = useState(false);
  const [testvalue, setTestvalue] = useState("");

  const ResetTest = (value) => {
    setReset(true);
    setTestvalue(value);
  };
  const ResetTestsection = () => {
    let payload = {
      section: testvalue,
      inviteId: props.id,
    };
    dispatch(role.restTestInvite(payload));
  };
  useEffect(() => {
    if (roleState?.resetTest?.status === 0) {
      setOpen(true);
      setsnacktext(roleState?.resetTest?.data);
    }
    // eslint-disable-next-line
  }, [roleState?.resetTest?.status]);

  const handleLink = (link) => {
    history(link);
  };

  const combinevalues = roleState?.candidateDashboardDetails?.selectedTestingRequirement?.reduce(
    (accumulator, select, index) => {
      return accumulator.concat({
        testingRequired: select,
      });
    },
    []
  );

  const getSortedSkills = useMemo(() => {
    const list =
      authState?.areaOfStrengthsAndImprovements?.Technical?.length > 0
        ? authState?.areaOfStrengthsAndImprovements?.Technical
        : roleState?.candidateDashboardDetails?.jobRole?.skillDetails;

    if (roleState?.candidateDashboardDetails?.jobRole?.skillDetails.length > 0) {
      let skillDtls = [...roleState?.candidateDashboardDetails?.jobRole?.skillDetails];
      list.map((i) => {
        let skillName = i["Topic"] || i["skill"];
        let skill = skillDtls.filter((sk) => sk.skill?.toLowerCase() === skillName?.toLowerCase());
        // console.log("testing--->", skill);
        i["weightage"] = i["Weightage"] || skill[0]?.weightage;
        i["skill"] = authState?.skillsConfig[skillName]?.fullName || skillName;
        return i;
      });
    }

    return list?.sort(function (a, b) {
      return b.weightage - a.weightage || a.skill?.localeCompare(b.skill);
    });
  }, [
    roleState?.candidateDashboardDetails?.jobRole?.skillDetails,
    authState?.areaOfStrengthsAndImprovements?.Technical,
    authState?.skillsConfig,
  ]);

  // FK cleanup comments july 2023
  // const getSortedSkills = useMemo( () => {
  //   // authState?.areaOfStrengthsAndImprovements?.Technical?.length > 0 ? authState?.areaOfStrengthsAndImprovements?.Technical :
  //   const list = roleState?.candidateDashboardDetails?.jobRole?.skillDetails
  //   console.log(list)
  //   return list?.sort(function(a, b) {
  //     return b.weightage - a.weightage  ||  a.skill?.localeCompare(b.skill);
  //   });
  // }, [roleState?.candidateDashboardDetails?.jobRole?.skillDetails]); //, authState?.areaOfStrengthsAndImprovements?.Technical

  return (
    <>
      <div className={classes.scoresCalculatedBy} style={{ marginTop: "20px" }}>
        <div className={classes.sectionMainContainer}>
          <div className={classes.sectionChildContainer}>
            <span className={classes.roundedCircle} style={{ background: "#6aa84f" }}></span>
            <span className={classes.roundedText}>Strong</span>
          </div>
          <div className={classes.sectionChildContainer}>
            <span className={classes.roundedCircle} style={{ background: "#FAC06A" }}></span>
            <span className={classes.roundedText}>To Improve</span>
          </div>
          <div className={classes.sectionChildContainer}>
            <span className={classes.roundedCircle} style={{ background: "#DD2E2E" }}></span>
            <span className={classes.roundedText}>Concern</span>
          </div>
          <div className={classes.sectionChildContainer}>
            <span className={classes.roundedCircle} style={{ background: "white" }}></span>
            <span className={classes.roundedText}>Not Started / To Be Assessed</span>
          </div>
        </div>

        <ImageList rowHeight={"auto"} cols={isNotMobile ? 2 : 1} gap={16}>
          {/* ********************* Profile section ********************* */}
          <ImageListItem>
            <Card
              className={
                props.disabled === true
                  ? `${classes.sectionDisable}`
                  : combinevalues?.[0]?.testingRequired === "Profile" ||
                    combinevalues?.[1]?.testingRequired === "Profile" ||
                    combinevalues?.[2]?.testingRequired === "Profile" ||
                    combinevalues?.[3]?.testingRequired === "Profile"
                  ? classes.scoreCard
                  : classes.scoreCard1
              }
              elevation={0}
            >
              <CardContent>
                <Typography
                  variant2="b2Medium"
                  className={`${classes.scoresCalculatedByCardTitle} ${classes.mediumsty}`}
                  align="left"
                >
                  Profile Section:{" "}
                  {roleState?.candidateDashboardDetails &&
                    authState?.areaOfStrengthsAndImprovements?.sessionStatus?.profile !== "" &&
                    authState?.areaOfStrengthsAndImprovements?.sessionStatus?.profile.status === "scored" &&
                    roleState?.candidateDashboardDetails?.L3ProfileUpdatedDate && (
                      <span
                        className={
                          props.disabled === true ||
                          (combinevalues?.[0]?.testingRequired !== "Profile" &&
                            combinevalues?.[1]?.testingRequired !== "Profile" &&
                            combinevalues?.[2]?.testingRequired !== "Profile" &&
                            combinevalues?.[3]?.testingRequired !== "Profile")
                            ? `${classes.disablePrimaryText}`
                            : `${classes.primaryText}`
                        }
                      >
                        Last Updated{" "}
                        {moment(roleState?.candidateDashboardDetails?.L3ProfileUpdatedDate).format("MMM DD, YYYY")}
                      </span>
                    )}
                  {(authState?.areaOfStrengthsAndImprovements?.sessionStatus?.profile === "" ||
                    authState?.areaOfStrengthsAndImprovements?.sessionStatus?.profile.status !== "scored") &&
                    (combinevalues?.[0]?.testingRequired === "Profile" ||
                      combinevalues?.[1]?.testingRequired === "Profile" ||
                      combinevalues?.[2]?.testingRequired === "Profile" ||
                      combinevalues?.[3]?.testingRequired === "Profile") && (
                      <>
                        <span className={classes.roletype}>
                          {authState?.areaOfStrengthsAndImprovements?.sessionStatus?.profile !== "" &&
                          authState?.areaOfStrengthsAndImprovements?.sessionStatus?.profile.status === "submitted" ? (
                            <span key="L2" className={classes.primaryText}>
                              Submitted (scoring in progress)
                            </span>
                          ) : authState?.areaOfStrengthsAndImprovements?.sessionStatus?.profile !== "" &&
                            authState?.areaOfStrengthsAndImprovements?.sessionStatus?.profile.status === "started" ? (
                            <span key="L2" className={classes.primaryText}>
                              Started
                            </span>
                          ) : (
                            "Pending"
                          )}
                        </span>
                      </>
                    )}
                  {(authState?.areaOfStrengthsAndImprovements?.sessionStatus?.profile === "" ||
                    authState?.areaOfStrengthsAndImprovements?.sessionStatus?.profile.status !== "scored") &&
                    combinevalues?.[0]?.testingRequired !== "Profile" &&
                    combinevalues?.[1]?.testingRequired !== "Profile" &&
                    combinevalues?.[2]?.testingRequired !== "Profile" &&
                    combinevalues?.[3]?.testingRequired !== "Profile" && (
                      <span className={classes.roletype}>Not Required</span>
                    )}
                </Typography>
                <Typography
                  variant2="b2Regular"
                  className={`${
                    combinevalues?.[0]?.testingRequired === "Profile" ||
                    combinevalues?.[1]?.testingRequired === "Profile" ||
                    combinevalues?.[2]?.testingRequired === "Profile" ||
                    combinevalues?.[3]?.testingRequired === "Profile"
                      ? classes.grayText
                      : classes.grayText1
                  } ${classes.textSty}`}
                  align="left"
                >
                  This section evaluates the overall digital profile of a candidate, including the resume, professional
                  network, endorsements, recommendations, online contributions & achievements.{" "}
                  {roleState?.candidateDashboardDetails?.L3ProfileUpdatedDate &&
                    (combinevalues?.[0]?.testingRequired === "Profile" ||
                      combinevalues?.[1]?.testingRequired === "Profile" ||
                      combinevalues?.[2]?.testingRequired === "Profile" ||
                      combinevalues?.[3]?.testingRequired === "Profile") && (
                      <>
                        {authState?.areaOfStrengthsAndImprovements?.sessionStatus?.profile !== "" &&
                        authState?.areaOfStrengthsAndImprovements?.sessionStatus?.profile.status !== "started" &&
                        authState?.areaOfStrengthsAndImprovements?.sessionStatus?.profile.status !== "submitted" ? (
                          <span variant2="link2" className={`${classes.link} ${classes.linksty}`}>
                            {props.disabled === true ? (
                              <Link></Link>
                            ) : (
                              <Link
                                className={`${classes.link} ${classes.linkCursor}`}
                                onClick={() => handleLink("/L3ProfileOpeningScreen")}
                                data-rt-div-updateprofile="updateprofile"
                              >
                                Update Section
                              </Link>
                            )}
                          </span>
                        ) : (
                          <span variant2="link2">
                            <Link className={classes.disabledLink}>Update Section</Link>
                          </span>
                        )}
                      </>
                    )}
                  {!roleState?.candidateDashboardDetails?.L3ProfileUpdatedDate &&
                    (combinevalues?.[0]?.testingRequired === "Profile" ||
                      combinevalues?.[1]?.testingRequired === "Profile" ||
                      combinevalues?.[2]?.testingRequired === "Profile" ||
                      combinevalues?.[3]?.testingRequired === "Profile") && (
                      <>
                        {authState?.areaOfStrengthsAndImprovements?.sessionStatus?.profile === "" ? (
                          <span variant2="link2" className={`${classes.link} ${classes.linksty}`}>
                            {props.disabled === true ? (
                              <Link></Link>
                            ) : (
                              <Link
                                className={`${classes.link} ${classes.linkCursor}`}
                                onClick={() => handleLink("/L3ProfileOpeningScreen")}
                                data-rt-div-updateprofile="updateprofile"
                              >
                                Update Section
                              </Link>
                            )}
                          </span>
                        ) : (
                          <span variant2="link2">
                            <Link className={classes.disabledLink}>Update Section</Link>
                          </span>
                        )}
                      </>
                    )}
                  {(combinevalues?.[0]?.testingRequired === "Profile" ||
                    combinevalues?.[1]?.testingRequired === "Profile" ||
                    combinevalues?.[2]?.testingRequired === "Profile" ||
                    combinevalues?.[3]?.testingRequired === "Profile") && (
                    <Sections data={authState?.areaOfStrengthsAndImprovements?.Profile}></Sections>
                  )}
                  {/* )} */}
                </Typography>
              </CardContent>
            </Card>
          </ImageListItem>
          {/* ********************* Technical section ********************* */}
          <ImageListItem>
            <Card
              className={
                props.disabled === true
                  ? `${classes.sectionDisable}`
                  : combinevalues?.[0]?.testingRequired === "Technical" ||
                    combinevalues?.[1]?.testingRequired === "Technical" ||
                    combinevalues?.[2]?.testingRequired === "Technical" ||
                    combinevalues?.[3]?.testingRequired === "Technical"
                  ? classes.scoreCard
                  : classes.scoreCard1
              }
              elevation={0}
            >
              <CardContent>
                <Typography
                  variant2="b2Medium"
                  className={`${classes.scoresCalculatedByCardTitle} ${classes.mediumsty}`}
                  align="left"
                >
                  Technical Section:{" "}
                  {roleState?.candidateDashboardDetails &&
                    authState?.areaOfStrengthsAndImprovements?.sessionStatus?.technical !== "" &&
                    authState?.areaOfStrengthsAndImprovements?.sessionStatus?.technical.status === "scored" &&
                    roleState?.candidateDashboardDetails?.L2TechnicalScoreUpdatedDate && (
                      <span
                        className={props.disabled === true ? `${classes.disablePrimaryText}` : `${classes.primaryText}`}
                      >
                        Last Updated{" "}
                        {moment(roleState?.candidateDashboardDetails?.L2TechnicalScoreUpdatedDate).format(
                          "MMM DD, YYYY"
                        )}
                      </span>
                    )}
                  {(authState?.areaOfStrengthsAndImprovements?.sessionStatus?.technical === "" ||
                    authState?.areaOfStrengthsAndImprovements?.sessionStatus?.technical.status !== "scored") &&
                    (combinevalues?.[0]?.testingRequired === "Technical" ||
                      combinevalues?.[1]?.testingRequired === "Technical" ||
                      combinevalues?.[2]?.testingRequired === "Technical" ||
                      combinevalues?.[3]?.testingRequired === "Technical") && (
                      <>
                        <span className={classes.roletype}>
                          {authState?.areaOfStrengthsAndImprovements?.sessionStatus?.technical !== "" &&
                          authState?.areaOfStrengthsAndImprovements?.sessionStatus?.technical.status === "submitted" ? (
                            <span key="L2" className={classes.primaryText}>
                              Submitted (scoring in progress)
                            </span>
                          ) : authState?.areaOfStrengthsAndImprovements?.sessionStatus?.technical !== "" &&
                            authState?.areaOfStrengthsAndImprovements?.sessionStatus?.technical.status === "started" ? (
                            <span key="L2" className={classes.primaryText}>
                              Started
                            </span>
                          ) : (
                            "Pending"
                          )}
                        </span>
                      </>
                    )}
                  {combinevalues?.[0]?.testingRequired !== "Technical" &&
                    combinevalues?.[1]?.testingRequired !== "Technical" &&
                    combinevalues?.[2]?.testingRequired !== "Technical" &&
                    combinevalues?.[3]?.testingRequired !== "Technical" && (
                      <span className={classes.roletype}>Not Required</span>
                    )}
                </Typography>
                <Typography
                  variant2="b2Regular"
                  className={`${
                    combinevalues?.[0]?.testingRequired === "Technical" ||
                    combinevalues?.[1]?.testingRequired === "Technical" ||
                    combinevalues?.[2]?.testingRequired === "Technical" ||
                    combinevalues?.[3]?.testingRequired === "Technical"
                      ? classes.grayText
                      : classes.grayText1
                  } ${classes.textSty}`}
                  align="left"
                  style={theme.typography.b2Regular}
                >
                  A series of multiple-choice questions test the technical knowledge of the candidate. These questions
                  are customized based on the job requirements. Only one attempt is permitted.{" "}
                  {roleState?.candidateDashboardDetails?.L2TechnicalScoreUpdatedDate && (
                    <>
                      <span variant2="link2" className={`${classes.link} ${classes.linksty}`}></span>
                    </>
                  )}
                  {roleState?.candidateDashboardDetails?.L2TechnicalScoreUpdatedDate &&
                    (combinevalues?.[0]?.testingRequired === "Technical" ||
                      combinevalues?.[1]?.testingRequired === "Technical" ||
                      combinevalues?.[2]?.testingRequired === "Technical" ||
                      combinevalues?.[3]?.testingRequired === "Technical") && (
                      <>
                        {authState?.areaOfStrengthsAndImprovements?.sessionStatus?.technical !== "" &&
                        authState?.areaOfStrengthsAndImprovements?.sessionStatus?.technical.status !== "started" &&
                        authState?.areaOfStrengthsAndImprovements?.sessionStatus?.technical.status !== "submitted" ? (
                          <span variant2="link2" className={`${classes.link} ${classes.linksty}`}>
                            {props.disabled === true ? (
                              <Link></Link>
                            ) : (
                              <Link
                                className={`${classes.link} ${classes.linkCursor}`}
                                onClick={() => ResetTest("Technical")}
                                data-rt-div-takequiztechnical="takequiztechnical"
                              >
                                Reset Section
                              </Link>
                            )}
                          </span>
                        ) : (
                          <span variant2="link2">
                            <Link className={classes.disabledLink}>Reset Section</Link>
                          </span>
                        )}
                      </>
                    )}
                  {(combinevalues?.[0]?.testingRequired === "Technical" ||
                    combinevalues?.[1]?.testingRequired === "Technical" ||
                    combinevalues?.[2]?.testingRequired === "Technical" ||
                    combinevalues?.[3]?.testingRequired === "Technical") && (
                    <Sections data={getSortedSkills}></Sections>
                  )}
                </Typography>
              </CardContent>
            </Card>
          </ImageListItem>
          {/* ********************* Coding section ********************* */}
          <ImageListItem>
            <Card
              className={
                props.disabled === true
                  ? `${classes.sectionDisable}`
                  : combinevalues?.[0]?.testingRequired === "Coding" ||
                    combinevalues?.[1]?.testingRequired === "Coding" ||
                    combinevalues?.[2]?.testingRequired === "Coding" ||
                    combinevalues?.[3]?.testingRequired === "Coding"
                  ? classes.scoreCard
                  : classes.scoreCard1
              }
              elevation={0}
            >
              <CardContent>
                <Typography
                  variant2="b2Medium"
                  className={`${classes.scoresCalculatedByCardTitle} ${classes.mediumsty}`}
                  align="left"
                  style={theme.typography.b2Medium}
                >
                  Coding Section:{" "}
                  {roleState?.candidateDashboardDetails &&
                    authState?.areaOfStrengthsAndImprovements?.sessionStatus?.coding !== "" &&
                    authState?.areaOfStrengthsAndImprovements?.sessionStatus?.coding.status === "scored" &&
                    roleState?.candidateDashboardDetails?.L3CodingUpdatedDate && (
                      <span
                        className={props.disabled === true ? `${classes.disablePrimaryText}` : `${classes.primaryText}`}
                      >
                        Last Updated{" "}
                        {moment(roleState?.candidateDashboardDetails?.L3CodingUpdatedDate).format("MMM DD, YYYY")}
                      </span>
                    )}
                  {(authState?.areaOfStrengthsAndImprovements?.sessionStatus?.coding === "" ||
                    authState?.areaOfStrengthsAndImprovements?.sessionStatus?.coding.status !== "scored") &&
                    (combinevalues?.[0]?.testingRequired === "Coding" ||
                      combinevalues?.[1]?.testingRequired === "Coding" ||
                      combinevalues?.[2]?.testingRequired === "Coding" ||
                      combinevalues?.[3]?.testingRequired === "Coding") && (
                      <>
                        <span className={classes.roletype} data-rt-div-completecode="completecode">
                          {authState?.areaOfStrengthsAndImprovements?.sessionStatus?.coding !== "" &&
                          authState?.areaOfStrengthsAndImprovements?.sessionStatus?.coding.status === "submitted" ? (
                            <span key="coding" className={classes.primaryText}>
                              Submitted (scoring in progress)
                            </span>
                          ) : authState?.areaOfStrengthsAndImprovements?.sessionStatus?.coding !== "" &&
                            authState?.areaOfStrengthsAndImprovements?.sessionStatus?.coding.status === "started" ? (
                            <span key="coding" className={classes.primaryText}>
                              Started
                            </span>
                          ) : (
                            "Pending"
                          )}
                        </span>
                      </>
                    )}
                  {combinevalues?.[0]?.testingRequired !== "Coding" &&
                    combinevalues?.[1]?.testingRequired !== "Coding" &&
                    combinevalues?.[2]?.testingRequired !== "Coding" &&
                    combinevalues?.[3]?.testingRequired !== "Coding" && (
                      <span className={classes.roletype}>Not Required</span>
                    )}
                </Typography>
                <Typography
                  variant2="b2Regular"
                  className={`${
                    combinevalues?.[0]?.testingRequired === "Coding" ||
                    combinevalues?.[1]?.testingRequired === "Coding" ||
                    combinevalues?.[2]?.testingRequired === "Coding" ||
                    combinevalues?.[3]?.testingRequired === "Coding"
                      ? classes.grayText
                      : classes.grayText1
                  } ${classes.textSty}`}
                  align="left"
                  style={theme.typography.b2Regular}
                >
                  This section evaluates skills in algorithms and programming through a series of coding problems of
                  progressive difficulty. A coding IDE is provided, and only one attempt is permitted.{" "}
                  {roleState?.candidateDashboardDetails?.L3CodingUpdatedDate && (
                    <>
                      <span variant2="link2" className={classes.link} style={theme.typography.link2}></span>
                    </>
                  )}
                  {roleState?.candidateDashboardDetails?.L3CodingUpdatedDate &&
                    (combinevalues?.[0]?.testingRequired === "Coding" ||
                      combinevalues?.[1]?.testingRequired === "Coding" ||
                      combinevalues?.[2]?.testingRequired === "Coding" ||
                      combinevalues?.[3]?.testingRequired === "Coding") && (
                      <>
                        {authState?.areaOfStrengthsAndImprovements?.sessionStatus?.coding !== "" &&
                        authState?.areaOfStrengthsAndImprovements?.sessionStatus?.coding.status !== "started" &&
                        authState?.areaOfStrengthsAndImprovements?.sessionStatus?.coding.status !== "submitted" ? (
                          <span variant2="link2" className={classes.link} style={theme.typography.link2}>
                            {props.disabled === true ? (
                              <Link></Link>
                            ) : (
                              <Link
                                className={`${classes.link} ${classes.linkCursor}`}
                                onClick={() => ResetTest("Coding")}
                                data-rt-div-startcode="startcode"
                              >
                                Reset Section
                              </Link>
                            )}
                          </span>
                        ) : (
                          <span variant2="link2">
                            <Link className={classes.disabledLink}>Reset Section</Link>
                          </span>
                        )}
                      </>
                    )}
                  {(combinevalues?.[0]?.testingRequired === "Coding" ||
                    combinevalues?.[1]?.testingRequired === "Coding" ||
                    combinevalues?.[2]?.testingRequired === "Coding" ||
                    combinevalues?.[3]?.testingRequired === "Coding") && (
                    <Sections data={authState?.areaOfStrengthsAndImprovements?.Coding}></Sections>
                  )}
                  {/* )} */}
                </Typography>
              </CardContent>
            </Card>
          </ImageListItem>
          {/* ********************* Presentation section ********************* */}
          <ImageListItem>
            <Card
              className={
                props.disabled === true
                  ? `${classes.sectionDisable}`
                  : combinevalues?.[0]?.testingRequired === "Presentation" ||
                    combinevalues?.[1]?.testingRequired === "Presentation" ||
                    combinevalues?.[2]?.testingRequired === "Presentation" ||
                    combinevalues?.[3]?.testingRequired === "Presentation"
                  ? classes.scoreCard
                  : classes.scoreCard1
              }
              elevation={0}
            >
              <CardContent>
                <Typography
                  variant2="b2Medium"
                  className={`${classes.scoresCalculatedByCardTitle} ${classes.mediumsty}`}
                  align="left"
                  style={theme.typography.b2Medium}
                >
                  Behavioral Section:{" "}
                  {roleState?.candidateDashboardDetails &&
                    authState?.areaOfStrengthsAndImprovements?.sessionStatus?.presentation !== "" &&
                    authState?.areaOfStrengthsAndImprovements?.sessionStatus?.presentation.status === "scored" &&
                    roleState?.candidateDashboardDetails?.L3PresentationUpdatedDate && (
                      <span
                        className={
                          props.disabled === true ||
                          (combinevalues?.[0]?.testingRequired !== "Presentation" &&
                            combinevalues?.[1]?.testingRequired !== "Presentation" &&
                            combinevalues?.[2]?.testingRequired !== "Presentation" &&
                            combinevalues?.[3]?.testingRequired !== "Presentation")
                            ? `${classes.disablePrimaryText}`
                            : `${classes.primaryText}`
                        }
                      >
                        Last Updated{" "}
                        {moment(roleState?.candidateDashboardDetails?.L3PresentationUpdatedDate).format("MMM DD, YYYY")}
                      </span>
                    )}
                  {(authState?.areaOfStrengthsAndImprovements?.sessionStatus?.presentation === "" ||
                    authState?.areaOfStrengthsAndImprovements?.sessionStatus?.presentation.status !== "scored") &&
                    (combinevalues?.[0]?.testingRequired === "Presentation" ||
                      combinevalues?.[1]?.testingRequired === "Presentation" ||
                      combinevalues?.[2]?.testingRequired === "Presentation" ||
                      combinevalues?.[3]?.testingRequired === "Presentation") && (
                      <>
                        <span className={classes.roletype}>
                          {authState?.areaOfStrengthsAndImprovements?.sessionStatus?.presentation !== "" &&
                          authState?.areaOfStrengthsAndImprovements?.sessionStatus?.presentation.status ===
                            "submitted" ? (
                            <span key="presentation" className={classes.primaryText}>
                              Submitted (scoring in progress)
                            </span>
                          ) : authState?.areaOfStrengthsAndImprovements?.sessionStatus?.presentation !== "" &&
                            authState?.areaOfStrengthsAndImprovements?.sessionStatus?.presentation.status ===
                              "started" ? (
                            <span key="presentation" className={classes.primaryText}>
                              Started
                            </span>
                          ) : (
                            "Pending"
                          )}
                        </span>
                      </>
                    )}
                  {!roleState?.candidateDashboardDetails?.L3PresentationUpdatedDate &&
                    combinevalues?.[0]?.testingRequired !== "Presentation" &&
                    combinevalues?.[1]?.testingRequired !== "Presentation" &&
                    combinevalues?.[2]?.testingRequired !== "Presentation" &&
                    combinevalues?.[3]?.testingRequired !== "Presentation" && (
                      <span className={classes.roletype}>Not Required</span>
                    )}
                </Typography>
                <Typography
                  variant2="b2Regular"
                  className={`${
                    combinevalues?.[0]?.testingRequired === "Presentation" ||
                    combinevalues?.[1]?.testingRequired === "Presentation" ||
                    combinevalues?.[2]?.testingRequired === "Presentation" ||
                    combinevalues?.[3]?.testingRequired === "Presentation"
                      ? classes.grayText
                      : classes.grayText1
                  } ${classes.textSty}`}
                  align="left"
                  style={theme.typography.b2Regular}
                >
                  With a series of scenario-based questions, the candidate is evaluated in multiple areas like role
                  awareness, work culture, work ethics, conscientiousness, and job readiness.{" "}
                  {roleState?.candidateDashboardDetails?.L3PresentationUpdatedDate &&
                    (combinevalues?.[0]?.testingRequired === "Presentation" ||
                      combinevalues?.[1]?.testingRequired === "Presentation" ||
                      combinevalues?.[2]?.testingRequired === "Presentation" ||
                      combinevalues?.[3]?.testingRequired === "Presentation") && (
                      <>
                        {authState?.areaOfStrengthsAndImprovements?.sessionStatus?.presentation !== "" &&
                        authState?.areaOfStrengthsAndImprovements?.sessionStatus?.presentation.status !== "started" &&
                        authState?.areaOfStrengthsAndImprovements?.sessionStatus?.presentation.status !==
                          "submitted" ? (
                          <span variant2="link2" className={`${classes.link} ${classes.linksty}`}>
                            {props.disabled === true ? (
                              <Link></Link>
                            ) : (
                              <Link
                                className={`${classes.link} ${classes.linkCursor}`}
                                onClick={() => ResetTest("Presentation")}
                                data-rt-div-takequizpresentation="takequizpresentation"
                              >
                                Reset Section
                              </Link>
                            )}
                          </span>
                        ) : (
                          <span variant2="link2">
                            <Link className={classes.disabledLink}>Reset Section</Link>
                          </span>
                        )}
                      </>
                    )}
                  <>
                    {!roleState?.candidateDashboardDetails?.L3PresentationUpdatedDate &&
                      (combinevalues?.[0]?.testingRequired === "Presentation" ||
                        combinevalues?.[1]?.testingRequired === "Presentation" ||
                        combinevalues?.[2]?.testingRequired === "Presentation" ||
                        combinevalues?.[3]?.testingRequired === "Presentation") && (
                        <>
                          {/* ****** DISABLE BEHAVIORAL SECTION FOR RECRUITERS ******

                          {authState?.areaOfStrengthsAndImprovements?.sessionStatus?.presentation === "" ? (
                            <span variant2="link2" className={`${classes.link} ${classes.linksty}`}>
                              {props.disabled === true ? (
                                <Link></Link>
                              ) : (
                                <Link
                                  className={
                                    props.disabled === true
                                      ? `${classes.disabledCardLink}`
                                      : `${classes.link} ${classes.linkCursor}`
                                  }
                                  onClick={() => handleLink("/L3PresentationOpeningScreen")}
                                  data-rt-div-takepresentation="takepresentation"
                                >
                                  Update Section
                                </Link>
                              )}
                            </span>
                          ) : ( */}
                          {/* <span variant2="link2">
                            <Link className={classes.disabledLink}>Update Section</Link>
                          </span> */}
                          {/* )} */}
                        </>
                      )}
                  </>
                  {(combinevalues?.[0]?.testingRequired === "Presentation" ||
                    combinevalues?.[1]?.testingRequired === "Presentation" ||
                    combinevalues?.[2]?.testingRequired === "Presentation" ||
                    combinevalues?.[3]?.testingRequired === "Presentation") && (
                    <Sections data={authState?.areaOfStrengthsAndImprovements?.Presentation}></Sections>
                  )}
                </Typography>
              </CardContent>
            </Card>
          </ImageListItem>
        </ImageList>
      </div>
      <ReactiveModel
        open={reset}
        OnReactive={ResetTestsection}
        onClose={() => setReset(false)}
        Text={"Are you sure you want to reset section?"}
        textchange={"ResetTest"}
      />
      <SimpleSnackbar open={open} setOpen={setOpen} text={snacktext} />
    </>
  );
}
export default QuizSection;
