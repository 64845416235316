import React, { useState, useEffect } from "react";
import Plot from "../../manageAccounts/analytics/charts/Plot";
import { chartConfig } from "../../manageAccounts/analytics/chartConfig";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import { useSelector } from "react-redux";

const TreemapChart = ({ treeMapData }) => {
  const [data, setData] = useState("");
  const authState = useSelector((state) => state.auth);

  function calcScoreOpacity(obj) {
    // if (obj.monthsUsed > 0 && obj.lastUsed != "") {
    let factor1 = 1 / (1 + Math.E ** -((obj.monthsUsed - 28) / 14));
    factor1 = obj.monthsUsed === 0 ? 0 : factor1;
    let factor2 = 0.7 / (1 + Math.E ** ((obj.lastUsedInMonths - 30) / 12)) + 0.3;
    factor2 = obj.lastUsedInMonths <= 3 ? 1.0 : factor2;
    const score = Math.round(factor1 * factor2 * 100);
    obj.score = score;
    obj.opacity = score <= 25 ? 0.25 : score / 100;
    return obj;
    // }
  }

  const mapDataToCategoryArray = (mappedData, skills) => {
    return mappedData?.map((item) => {
      const skill = skills[item.skill];
      return {
        ...skill,
        skill: item.skill,
        score: item.score,
        opacity: item.opacity,
        lastUsedInMonths: item.lastUsedInMonths,
        monthsUsed: item.monthsUsed,
        category: skill?.category || "Others",
        lastUsed: item.lastUsed,
      };
    });
  };

  function mapCategoryData(categoryArray) {
    const mappedData = {};
    categoryArray?.forEach((item) => {
      // const { category, fullName, monthsUsed, opacity } = item;
      if (!mappedData[item.category]) {
        mappedData[item.category] = [];
      }

      mappedData[item.category].push({
        // skill: item.fullName,
        skill: item.category === "Others" ? item.skill : item.fullName,
        // score: item.monthsUsed === 0 ? 0 : item.score,
        score: item.score,
        opacity: item.opacity,
        lastUsedInMonths: item.lastUsedInMonths,
        monthsUsed: item.monthsUsed,
        lastUsed: item.lastUsed,
      });
    });

    if ("Database" in mappedData) {
      mappedData["Databases"] = mappedData["Database"];
      delete mappedData["Database"];
    }
    return mappedData;
  }

  function formatAnnotation(moe, lu) {
    let txt = "";
    ///lu month year

    if (moe <= 23) {
      txt = moe === 1 ? `<b>${moe}</b> Month` : `<b>${moe}</b> Months`;
    } else {
      const years = Math.floor(moe / 12);
      const remainingMonths = moe % 12;
      txt = remainingMonths === 0 ? `<b>${years}</b> Years` : `<b>${years}+</b> Years`;
    }
    return `<br>${txt} of Experience<br>Last Used <b>${lu}</b>`;
  }

  useEffect(() => {
    const mappedData = treeMapData?.map(calcScoreOpacity);
    if (authState?.skillsConfig && mappedData?.length) {
      const categoryArray = mapDataToCategoryArray(mappedData, authState?.skillsConfig);
      const finalMappedData = mapCategoryData(categoryArray);
      setData(finalMappedData);
    }
  }, [treeMapData, authState?.skillsConfig]);

  let catColors = {
    "AI and Emerging Technologies": "rgba(255, 214, 91)",
    "Database and Backend Development": "rgba(140, 240, 240)",
    "Data and Analytics": "rgba(178, 224, 97)",
    "DevOps and Cloud Computing": "rgba(193, 163, 224)",
    "Networking and Security": "rgba(253, 204, 229)",
    "Product and Program Management": "rgba(139, 211, 199)",
    // Unknown: "rgba(212, 188, 168)",
    "Programming Languages": "rgba(255, 160, 122)",
    "Quality Engineering": "rgba(240, 139, 139)",
    "Web and App Development": "rgba(184, 214, 240)",
    CAT2: "rgba(144, 209, 176)",
    Others: "rgba(223, 223, 223)",
  };

  var plotlyData = {
    type: "treemap",
    labels: [],
    parents: [],
    values: [],
    text: [],
    hoverinfo: "label+text",
    marker: { colors: [] },
  };

  for (let cat in data) {
    let catData = data[cat];
    plotlyData.labels.push(`<b>${cat}</b>`);
    plotlyData.parents.push("");
    plotlyData.values.push(0);
    plotlyData.text.push("");
    plotlyData.marker.colors.push(`${catColors[cat]?.slice(0, -1) + ", " + 0.0 + ")"}`);

    catData?.forEach(function (entry) {
      plotlyData.labels.push(`<b><i>${entry.skill}</i></b>`);
      plotlyData.parents.push(`<b>${cat}</b>`);
      plotlyData.values.push(entry.score);

      // Update hoverinfo directly in the marker object
      var opacity = entry.opacity;
      var color = `${catColors[cat]?.slice(0, -1) + ", " + opacity + ")"}`;
      plotlyData.marker.colors.push(color);

      // plotlyData.text.push(
      //   `<b>${entry?.monthsUsed}</b> Months Experience<br>` +
      //     `Last Used <b>${entry?.lastUsedInMonths}</b> Months Ago`
      // );
      plotlyData.text.push(formatAnnotation(entry.monthsUsed, entry.lastUsed));
    });
  }

  var layout = {
    margin: {
      l: 2,
      r: 2,
      b: 2,
      t: 2,
      pad: 2,
    },
    font: {
      size: 12,
      family: "Roboto",
    },
    // font: chartConfig.font,
    hovermode: "closest",
  };

  return treeMapData.length ? (
    <Plot
      data={[plotlyData]}
      layout={layout}
      style={{ width: "100%", height: "100%", margin: "0" }}
      containerId="treemap"
      config={{
        displaylogo: false,
        responsive: true,
        displayModeBar: false,
        // modeBarButtonsToRemove: chartConfig.modeBarButtonsToRemove,
      }}
    />
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "450px",
        textAlign: "center",
      }}
    >
      <ViewQuiltIcon style={{ marginBottom: "8px", fontSize: "27px" }} />
      <span style={{ marginLeft: "5px", marginBottom: "5px" }}>{chartConfig.MessageWhenNoDataTreemap}</span>
    </div>
  );
};

export default TreemapChart;
