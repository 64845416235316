import React, { useEffect, useState } from "react";
import NavBar from "../../components/NavBar";
import { makeStyles } from "@mui/styles";
import { Container, Grid, Skeleton } from "@mui/material";
import GridTable from "../../components/Table";
import { theme } from "../../styles/theme";
import SearchBar from "../../components/controls/search";
import { useNavigate } from "react-router-dom";
import role from "../../redux/actions/role";
import { useDispatch, useSelector } from "react-redux";
import { generatePath } from "react-router-dom";
import Button from "../../components/controls/Button";
import Footer from "../../components/Footer";
import RefreshIcon from "@mui/icons-material/Refresh";
import CustomPagination from "../../components/controls/CustomPagination";
import CheckSelectMenu from "../../components/controls/Checkmenu";
import CloseIcon from "@mui/icons-material/Close";
import HeaderConfig from "../../constants/HeaderConfigs";
import ToggleButtonAll from "../../components/controls/ToggleButton";
import { sortJson, applySearch, transformOptions } from "../../services/globalService";
import Tour from "../../components/Tour";
// import TrialExpiryNotification from "../../components/TrialExpiryNotification";
import SelectWithCheckbox from "../../components/controls/SelectWithCheckbox";
import Notification from "../../components/AnnouncementNotification";
import CustomBreadcrumbs from "../../components/controls/CustomBreadcrumbs";

const statusMenuProps = {
  PaperProps: {
    style: {
      //width: 194,
      // height: 188,
      padding: 12,
      marginLeft: 45,
      //left:"795px !important"
    },
  },
};
const skillMenuProps = {
  PaperProps: {
    style: {
      //width: 194,
      // height: 400,
      padding: 12,
      marginLeft: 114,
      //left:"795px !important"
    },
  },
};
const recruiterMenuProps = {
  PaperProps: {
    style: {
      //  width: 207,
      // height: 188,
      padding: 12,
      marginLeft: 28,
    },
  },
};
const addedByMenuProps = {
  PaperProps: {
    style: {
      width: 194,
      //  height: 300,
      padding: 12,
      marginLeft: 40,
    },
  },
};
const useStyles = makeStyles({
  container: {
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingBottom: "100px",
  },
  viewText: {
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontSize: "16px !important",
  },
  allJob: {
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontWeight: `${theme.font.fontWeight} !important`,
    fontSize: "24px !important",
    lineHeight: "32px",
    letterSpacing: "-0.02em",
    color: "#2F2F2F",
  },
  btnCls: {
    width: 300,
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontWeight: `${theme.font.fontWeight} !important`,
    fontSize: "14px !important",
    lineHeight: "24px !important",
    borderRadius: "4px !important",
    textTransform: "none !important",
    height: "30px !important",
    // height: '40px'
  },
  Allsty: {
    boxSizing: "border-box",

    /* Auto layout */

    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: " center",
    // padding: "8px 12px",
    gap: "10px",
    width: "71px",
    height: "40px",
    border: "1px solid #2F2F2F",
    flex: "none",
    order: 0,
    flexGrow: 0,
    margin: "0px -1px !important",

    fontSize: "16px !important",
    fontWeight: 500,
    cursor: "pointer",
  },
  Alltext: {
    display: "flex",
    //flexDirection: "column",
  },
  Allbtn: {
    borderRadius: "4px 0px 0px 4px",
    color: "#FFFFFF",
    background: "#2F2F2F",
  },
  Mybtn: {
    borderRadius: "0px 4px 4px 0px",

    color: "#2F2F2F",
  },
  Allbtn1: {
    borderRadius: "0px 4px 4px 0px",
    color: "#FFFFFF",
    background: "#2F2F2F",
  },
  Mybtn1: {
    borderRadius: "4px 0px 0px 4px",

    color: "#2F2F2F",
  },
  filtersty: {
    width: "101px",
    height: "40px",
    border: "1px solid #787878",
    color: "#787878",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "space-evenly",
    cursor: "pointer",
  },
  filterText: {
    boxSizing: "border-box",
    fontSize: "16px",
    fontWeight: 500,
    margin: "0px -1px",
    width: "45px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  selectmenu: {
    "& .MuiSelect-select": {
      border: "1px solid #787878 !important",
    },
    "& .MuiOutlinedInput-root .Mui-focused": {
      // border: `1px solid ${theme.palette.btnPrimary.main}`,
      border: "none",
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        border: "none !important",
      },
    },
    "&.Mui-error": {
      // border: `1px solid ${theme.palette.error.main}`,
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
      border: "none",
    },
    "& .MuiOutlinedInput-notchedOutline:hover": {
      border: "none",
    },
    "& .MuiOutlinedInput-notchedOutline:active": {
      border: "none",
    },
    "&:hover": {
      outline: "none !important",
      border: "none !important",
    },
  },
  icon: {
    color: "#2F2F2F !important",
  },
  Noresult: {
    position: "relative",
    bottom: "200px",
  },
});

const RolesList = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const roleState = useSelector((state) => state.role);
  const [selectText, setSelectText] = useState("all");
  const [searchText, setSearchText] = useState("");

  const setUserDetails = () => {
    // check if recruiterUserId needs to be set
    if ("recruiterUserId" in localStorage) {
      let recruiterUserId = localStorage.getItem("recruiterUserId");
      localStorage.setItem("userid", recruiterUserId);
      let userDetails = JSON.parse(localStorage.getItem("user_details"));
      userDetails.userId = recruiterUserId;
      localStorage.setItem("user_details", JSON.stringify(userDetails));
      localStorage.removeItem("recruiterUserId");
    }
    return JSON.parse(localStorage.getItem("user_details"));
  };
  let userDetails = setUserDetails();
  // const [tableRows, setTableRows] = useState([])
  const [onlyMine, setOnlyMine] = useState(false);
  const [start, setStart] = useState(1);
  const [limit, setLimit] = useState(10);
  const [createdby, setCreatedby] = useState([]);
  const [status, setStatus] = useState([]);
  const [category, setCategory] = useState([]);
  const [skill, setSkill] = useState([]);
  const [showReset, setShowReset] = useState(false);
  const [statuslength, setStatuslength] = useState(0);
  const [createlength, setCreatelength] = useState(0);
  const [categorylength, setCategorylength] = useState(0);
  const [skilllength, setSkilllength] = useState(0);
  const [statusBColor, setStatusBColor] = useState("");
  const [createBColor, setCreateBColor] = useState("");
  const [categoryBColor, setCategoryBColor] = useState("");
  const [skillBColor, setSkillBColor] = useState("");
  const [userid, setUserid] = useState(null);
  const [roleview, setRoleview] = useState(false);
  const [rolefilt, setRolefilt] = useState(false);
  const [roleList, setRoleList] = useState(roleState?.rolelist);
  const [totalRole, setTotalRole] = useState(0);
  const [myTotal, setMyTotal] = useState(0);
  const [showStepper, setShowStepper] = useState(false);
  const [showNotification, setShowNotification] = useState(true);
  const [uiSortModel, setUiSortModel] = useState([
    {
      field: "uid",
      sort: "desc",
    },
  ]);
  var addedBy = roleState?.rolefilter?.createdBy
    ?.map((item, i) => {
      return item.displayName;
    })
    .sort();

  const headerdata = HeaderConfig.jobRoleHeader();

  useEffect(() => {
    dispatch(role.roleList({ userId: userDetails.userId }));
    setRolefilt(true);
  }, []);

  useEffect(() => {
    setRoleList(roleState?.rolelist);
    // run updates once role filter data is populated and run role list API.
    if (rolefilt && roleState?.rolelist?.length !== 0) {
      setRolefilt(false);
      setCreatedby(addedBy);
      setStatus(roleState?.rolefilter?.status);

      const roleCategories = transformOptions(roleState?.roleCategories).map((ele) => ele?.id);
      setCategory(roleCategories);

      // setSkill(roleState?.rolefilter?.skills);
      setSkill([]);
      setMyTotal(roleState?.rolemycount);
      setTotalRole(roleState?.roletotalcount);
    }
  }, [roleState?.rolelist]);

  useEffect(() => {
    if (roleview && roleState?.roledetails?.uid) window.open(generatePath("/newRoleView/:id", { id: userid }));
  }, [roleview && roleState?.roledetails?.uid]);

  // Apply filter
  useEffect(() => {
    if (roleState?.rolelist?.length !== 0) {
      // setTableRows(roleState?.rolelist)
      setRoleFilteredList();
    }
  }, [roleState?.rolelist, createdby, status, category, skill]);

  // Search Value side effect
  useEffect(() => {
    // let timeOut = setTimeout(() => {
    if (roleState?.rolelist?.length) {
      setRoleFilteredList();
    }
  }, [searchText]);

  // Side effect for toggle mylist or all list
  useEffect(() => {
    setRoleFilteredList();
    // eslint-disable-next-line
  }, [selectText]);

  // Appy search filter on data
  const applySearchFilter = (rawData) => {
    let visibleKeys = [
      "uid",
      "name",
      "dateFilter",
      "displayName",
      "status",
      "invited",
      "completed",
      "reachedTarget",
      "targetScore",
    ];
    let filteredData = applySearch(rawData, searchText, visibleKeys);
    return filteredData;
  };

  // Extract Filter Data
  const dataFilter = (rawData, filterData, key) => {
    // Make sure all filters are in lowercase
    filterData = filterData.map((i) => i.toLowerCase());
    return rawData.filter(
      // eslint-disable-next-line
      (item) => eval(`item.${key}`) && filterData.indexOf(eval(`item.${key}.toLowerCase().trim()`)) >= 0
    );
  }; //End

  const applyFilterOnData = () => {
    let rawData = roleState?.rolelist?.length ? [...roleState?.rolelist] : [];
    if (rawData?.length) {
      // AddedBy Filter
      rawData = dataFilter(rawData, createdby, "displayName");

      // Status Filter
      rawData = dataFilter(rawData, status, "status");

      // Role Catagory Filter
      rawData = dataFilter(rawData, category, "category");

      //  // Skills Filter
      let skills = skill?.length ? skill.map((s) => s.toLowerCase()) : [];

      // Filter rawData based on whether all selected skills match any skillDetails in each item
      rawData = rawData.filter((item) => {
        // Check if skillDetails exists for the current item
        if (item["skillDetails"]) {
          // Check if all selected skills are present in the skillDetails of the current item
          return skills.every((selectedSkill) =>
            item["skillDetails"].some((value) => value.toLowerCase().trim() === selectedSkill)
          );
        }
        return false; // If skillDetails doesn't exist, exclude the item
      });
    }
    return rawData;
  }; //End

  // Set Role filtered List
  const setRoleFilteredList = () => {
    let filteredData = applyFilterOnData();

    filteredData = applySearchFilter(filteredData);

    // Set Total Role count
    setTotalRole(filteredData?.length);

    // Set My Total Count
    if (onlyMine) {
      filteredData = filteredData?.filter((i) => i.createdBy.uid === userDetails.userId);
    }
    setMyTotal(filteredData.filter((i) => i.createdBy.uid === userDetails.userId).length);
    filteredData = sortJson(
      [...filteredData],
      uiSortModel[0].sort,
      uiSortModel[0].field,
      uiSortModel[0].field === "createdAt" ? "date" : ""
    );
    // removing year from dateFilter
    filteredData.map((filteredData) => {
      return (filteredData["dateFilter"] = filteredData["dateFilter"].slice(0, 6));
    });

    setRoleList(filteredData);
  }; //End

  const handleSortChange = (sortInfo) => {
    setUiSortModel([sortInfo]);
    let sortedOrder = sortJson(
      [...roleList],
      sortInfo.sort,
      sortInfo.field,
      sortInfo.field === "createdAt" ? "date" : ""
    );
    setRoleList(sortedOrder);
  };

  // changing createnewrole to CreateJobRole for demo 20/02/2024
  const AddRoleChange = () => {
    navigate("/CreateJobRole");
  };
  const RoleView = (rec) => {
    if (rec.uid !== undefined) {
      let id = rec.uid;
      setRoleview(true);
      setUserid(id);
      dispatch(role.roleDetails(id));
    } else {
      return null;
    }
  };
  const handleChangeCreate = (value) => {
    setCreatedby(value);
    setShowReset(true);
    if (value?.length === createdby?.length) {
      setCreatelength(0);
    } else {
      setCreatelength(value.length);
    }

    setCreateBColor("#EBEFF8");
    setTablePaginationPageNumber(0);
  };

  const onSelectAllCreate = () => {
    setCreatedby(addedBy);
    setCreatelength(0);
    setCreateBColor("");
    setShowReset(false);
    setTablePaginationPageNumber(0);
  };
  const onClearAllCreate = () => {
    setCreatedby([]);
    setCreatelength(0);
    setCreateBColor("");
    setTablePaginationPageNumber(0);
  };

  const handleChangeStatus = (value) => {
    setStatus(value);
    setShowReset(true);
    if (value?.length === roleState?.rolefilter?.status?.length) {
      setStatuslength(0);
    } else {
      setStatuslength(value.length);
    }
    setStatusBColor("#EBEFF8");
    setTablePaginationPageNumber(0);
  };

  const onSelectAllstatus = () => {
    setStatus(roleState?.rolefilter?.status);
    setStatuslength(0);
    setStatusBColor("#EBEFF8");
    setTablePaginationPageNumber(0);
  };

  const onClearAllstatus = () => {
    setStatus([]);
    setStatuslength(0);
    setStatusBColor("#EBEFF8");
    setShowReset(true);
    setTablePaginationPageNumber(0);
  };
  const handleChangeCategory = (value) => {
    setCategory(value);
    setShowReset(true);
    if (transformOptions(roleState?.roleCategories).length === value.length) {
      setCategorylength(0);
    } else {
      setCategorylength(value.length);
    }

    setCategoryBColor("#EBEFF8");
    setTablePaginationPageNumber(0);
  };

  const onSelectAllCategory = () => {
    setCategory(transformOptions(roleState?.roleCategories).map((ele) => ele?.id));
    setCategorylength(0);
    setCategoryBColor("#EBEFF8");
    setTablePaginationPageNumber(0);
  };
  const onClearAllCategory = () => {
    setCategory([]);
    setCategorylength(0);
    setCategoryBColor("#EBEFF8");
    setTablePaginationPageNumber(0);
  };
  const handleChangeSkill = (value) => {
    setSkill(value);
    setShowReset(true);
    if (value?.length === skill?.length) {
      setSkilllength(0);
    } else {
      setSkilllength(value.length);
    }
    setSkillBColor("#EBEFF8");
    setTablePaginationPageNumber(0);
  };

  const onSelectAllSkill = () => {
    setSkill(roleState?.rolefilter?.skills.map((i) => i?.id));
    setSkilllength((roleState?.rolefilter?.skills.map((i) => i?.id)).length);
    setSkillBColor("#EBEFF8");
    setTablePaginationPageNumber(0);
  };

  const onClearAllSkill = () => {
    setSkill([]);
    setSkilllength(0);
    setSkillBColor("#EBEFF8");
    setShowReset(true);
    setTablePaginationPageNumber(0);
  };

  const onChangeSearch = (e) => {
    let searchValue = e.target.value;
    searchValue = searchValue.replace(/,/g, "");
    if (searchValue) {
      setShowReset(true);
    }
    setSearchText(searchValue);
    setTablePaginationPageNumber(0);
  };

  const SelectBtn = (event, text) => {
    if (text === "my") {
      setOnlyMine(true);
      setSelectText("my");
      setTablePaginationPageNumber(0);
    } else {
      setOnlyMine(false);
      setSelectText("all");
      setTablePaginationPageNumber(0);
    }
  };
  const Paginationstart = (value) => {
    setStart(value);
  };
  const Paginationlimit = (value) => {
    setLimit(value);
  };

  const onRefresh = () => {
    dispatch(role.roleList({ userId: userDetails.userId }));
  };

  const onClearAllFilters = () => {
    setCategory(transformOptions(roleState?.roleCategories).map((ele) => ele?.id));
    setCreatedby(addedBy);
    setStatus(roleState?.rolefilter?.status);
    setSkill([]);
    setSearchText("");
    setShowReset(false);
    setStatuslength(0);
    setStatusBColor("");
    setCreateBColor("");
    setCreatelength(0);
    setCategorylength(0);
    setSkilllength(0);
    setSkillBColor("");
    setRoleList(roleState?.rolelist);
    setTablePaginationPageNumber(0);
  };

  //stepper
  const handleStepper = () => {
    setShowStepper((prevState) => !prevState);
  };

  //trial expiry notification
  // const trialExpiryApproaching = localStorage.getItem("trialExpiryApproaching");

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  // eslint-disable-next-line
  const [paginationPageNumber, setPaginationPageNumber] = useState(1);
  const [tablePaginationPageNumber, setTablePaginationPageNumber] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);

  const handleChangePage = (_, newPage) => {
    setPaginationPageNumber(newPage);
    setTablePaginationPageNumber(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setTablePaginationPageNumber(0);
    setPaginationPageNumber(1);
  };

  const SkeletonBtn = () => {
    return <Skeleton variant="rectangular" width={120} height={40} sx={{ borderRadius: "4px" }} />;
  };

  const SkeletonStrip = () => {
    return (
      <Skeleton variant="rectangular" width="75%" height={40} sx={{ borderRadius: "4px", marginBottom: "20px" }} />
    );
  };

  const SkeletonTable = () => {
    return (
      <Skeleton variant="rectangular" width="100%" height={window.innerHeight - 410} sx={{ borderRadius: "4px" }} />
    );
  };

  return (
    <div>
      <NavBar />
      <Notification handleCloseNotification={handleCloseNotification} showNotification={showNotification} />
      <Container maxWidth="xl" className={classes.container}>
        <Grid style={showNotification ? { marginTop: "30px" } : { marginTop: "130px" }}>
          <Grid style={{ display: "flex" }}>
            <CustomBreadcrumbs links={[{ name: "Home", path: "/home" }, { name: "Roles" }]} />
          </Grid>
          <div
            style={{ display: "flex", justifyContent: "space-between", lineHeight: "40px", padding: "25px 0px 20px" }}
          >
            <Grid style={{ display: "flex", alignItems: "flex-start", gap: "24px", flexDirection: "row" }}>
              <Button
                data-rt-role-addnew=" addnewrole"
                color={
                  userDetails.role === "Recruiter" && userDetails.roleSubType === "Contributor" ? "disablebtn" : "btn"
                }
                width={199}
                text="Create Role"
                disabled={userDetails.role === "Recruiter" && userDetails.roleSubType === "Contributor"}
                onClick={AddRoleChange}
              />
              <Button
                sx={{
                  textTransform: "none",
                  color: "#2F2F2F",
                  fontSize: "16px",
                  border: "1px solid #787878",
                  height: "40px",
                }}
                variant="outlined"
                startIcon={<RefreshIcon />}
                width={165}
                text="Refresh List"
                onClick={onRefresh}
              />
              {roleState?.isRoleListLoading ? (
                <SkeletonBtn />
              ) : (
                <ToggleButtonAll
                  handleAllAndMy={SelectBtn}
                  selectText={selectText}
                  Alltotal={totalRole}
                  Mytotal={myTotal}
                />
              )}
            </Grid>
            {roleState?.isRoleListLoading ? (
              ""
            ) : (
              <div style={{ display: "flex", alignItems: "flex-start", gap: "24px", flexDirection: "row" }}>
                <Grid>
                  <CustomPagination
                    Paginationstart={(start) => Paginationstart(start)}
                    Paginationlimit={(limit) => Paginationlimit(limit)}
                    TotalCount={roleList?.length}
                    rows={roleList || []}
                    tablePaginationPageNumber={tablePaginationPageNumber}
                    setTablePaginationPageNumber={setTablePaginationPageNumber}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    handleChangePage={handleChangePage}
                  />
                </Grid>
              </div>
            )}
          </div>
          {roleState?.isRoleListLoading ? (
            <SkeletonStrip />
          ) : (
            <Grid style={{ display: "flex", gap: "14px", paddingBottom: "20px" }}>
              <SearchBar
                placeholdertitle={"Search role"}
                onChange={onChangeSearch}
                value={searchText}
                // width={'310px'}
              />
              <CheckSelectMenu
                Title={"Created By"}
                Data={addedBy}
                HandleChangeValue={(value) => handleChangeCreate(value)}
                MenuProps={addedByMenuProps}
                width={createlength === 0 ? 122 : 153}
                text={"Createby"}
                checkLength={createlength}
                BgColor={createlength !== 0 && createBColor}
                OnSelectAll={onSelectAllCreate}
                OnClearAll={onClearAllCreate}
                selectValue={createdby}
              />
              <CheckSelectMenu
                Title={"Status"}
                Data={roleState?.rolefilter?.status}
                HandleChangeValue={(value) => handleChangeStatus(value)}
                MenuProps={statusMenuProps}
                width={statuslength === 0 ? 90 : 115}
                checkLength={statuslength}
                BgColor={statuslength !== 0 && statusBColor}
                selectValue={status}
                OnSelectAll={onSelectAllstatus}
                OnClearAll={onClearAllstatus}
              />
              <SelectWithCheckbox
                title={"Role Category"}
                options={roleState?.roleCategories}
                handleSelectOption={(value) => handleChangeCategory(value)}
                MenuProps={recruiterMenuProps}
                width={categorylength === 0 ? 145 : 167}
                checkLength={categorylength}
                BgColor={categorylength !== 0 && categoryBColor}
                defaultValue={category}
                handleSelectAll={onSelectAllCategory}
                handleClearAll={onClearAllCategory}
              />
              <SelectWithCheckbox
                title={"Required Skills"}
                options={roleState?.rolefilter?.skills}
                handleSelectOption={(value) => handleChangeSkill(value)}
                MenuProps={skillMenuProps}
                width={skilllength === 0 ? 153 : 173}
                checkLength={skilllength}
                BgColor={skilllength !== 0 && skillBColor}
                defaultValue={skill}
                handleSelectAll={onSelectAllSkill}
                handleClearAll={onClearAllSkill}
                disabledCapitalize
              />
              {showReset === true && (
                <div style={{ minWidth: "140px" }}>
                  <Button
                    sx={{ float: "right", textTransform: "none", color: "#2F2F2F" }}
                    variant="text"
                    endIcon={<CloseIcon />}
                    text="Reset Filters"
                    onClick={onClearAllFilters}
                  />
                </div>
              )}
            </Grid>
          )}
          <Grid>
            {roleState?.isRoleListLoading ? (
              <SkeletonTable />
            ) : (
              <GridTable
                height={window.innerHeight - 410}
                columns={headerdata}
                rows={roleList || []}
                checkbox={false}
                getRowId={(row) => row._id || 0}
                count={roleList?.length}
                handleRowClick={RoleView}
                onSortChange={handleSortChange}
                onResetFilters={onClearAllFilters}
                sortModel={uiSortModel}
                pageStart={start}
                pageLimit={limit}
                // sortingMode={'client'}
                showPagination
                tablePaginationPageNumber={tablePaginationPageNumber}
                setTablePaginationPageNumber={setTablePaginationPageNumber}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                handleChangePage={handleChangePage}
              />
            )}
          </Grid>
        </Grid>
      </Container>
      <Tour isTourBtnShow={showStepper} handleStepper={handleStepper} />
      <Footer />
    </div>
  );
};
export default RolesList;
