import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams, generatePath } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { Container, Typography, Grid, Box, Skeleton } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DownloadIcon from "@mui/icons-material/Download";
import UploadIcon from "@mui/icons-material/Upload";
import LoopIcon from "@mui/icons-material/Loop";
import { Button as BaseButton } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import NavBar from "../../components/NavBar";
import { theme } from "../../styles/theme";
import Button from "../../components/controls/Button";
import TextInput from "../../components/controls/TextField";
import auth from "../../redux/actions/auth";
import SimpleSnackbar from "../../components/controls/ToastMessage";
import Footer from "../../components/Footer";
import SelectMenu from "../../components/controls/SelectMenu";
import role from "../../redux/actions/role";
import ErrorSnackbar from "../../components/controls/Errormessage";
import { sortJson, transformOptions } from "../../services/globalService";
import GridTable from "../../components/Table";
// import fullSkills from "../../config";
import { analyticsEvent } from "../../services/ga4utility";
import TreemapChart from "./chart/TreemapChart";
import EditIcon from "@mui/icons-material/Edit";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import CustomBreadcrumbs from "../../components/controls/CustomBreadcrumbs";
import ReusableDialog from "../../components/controls/ReusableDialog";
import Stack from "@mui/material/Stack";

// styles for this component
const useStylesGridTable = makeStyles((theme) => ({
  monthsUsedValueGutterRight: {
    paddingRight: "143px",
    [theme.breakpoints.down("xl")]: {
      paddingRight: "153px",
    },
    [theme.breakpoints.down("lg")]: {
      paddingRight: "130px",
    },
    [theme.breakpoints.down("md")]: {
      paddingRight: "90px",
    },
  },
  monthsHeader: {
    marginLeft: "-9px", // Add the desired right margin for the Skills column
  },
  skillsColumn: {
    marginLeft: "10px", // Add the desired right margin for the Skills column
  },
  skillsHeader: {
    marginLeft: "10px",
  },
  lastUsedValueGutterLeft: {
    paddingLeft: "0px",
    marginLeft: "48px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "4px",
      marginLeft: "-20px",
    },
  },
  gutterLeftHeader: {
    paddingLeft: "28px",
    marginLeft: "46px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "4px",
      marginLeft: "-20px",
    },
  },
}));

const useStyles = makeStyles({
  container: {
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingBottom: "100px",
    // [theme.breakpoints.up("tablet")]: {
    //   paddingLeft: "64px",
    //   paddingRight: "64px",
    // },
  },
  navIconCls: {
    height: "14px",
    width: "22px",
    color: "#2F2F2F",
  },
  viewText: {
    fontFamily: `${theme.font.fontFamily} !important`,
    fontStyle: `${theme.font.fontStyle} !important`,
    fontSize: "16px !important",
  },
  roletype: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: " #787878",
    // textAlign:'center',
    paddingTop: "10px",
  },
  roletype1: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "18px !important",
    lineHeight: "24px !important",
    color: " #2F2F2F",
    // textAlign:'center',
    paddingTop: "10px",
  },
  roletext: {
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "24px !important",
    lineHeight: "28px !important",
    letterSpacing: "-0.02em !important",
    color: "#2F2F2F",
    textAlign: "center",
    flex: "none",
    order: 0,
    flexGrow: 0,
    paddingTop: "30px",
  },
  spaceflex: {
    display: "flex",
    justifyContent: "space-between",
  },
  resume: {
    fontFamily: "Roboto !important",
    fontStyle: "medium",
    fontWeight: "500 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: "#787878 !important",
    textTransform: "unset !important",
  },
  fileInput: {
    opacity: 0.0,
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
  },

  field: {
    // height: "48px !important",
    marginTop: "8px ",
    borderRadius: "5px !important",
    border: "1px solid #787878 !important",
    backgroundColor: "transparent",

    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
      height: "48px !important",
    },
    "& input::placeholder": {
      fontSize: "16px",
      color: "#787878 !important",
    },
    "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
      height: "17.2px!important",
    },
  },
  field1: {
    // height: 48,
    marginTop: 8,
    borderRadius: "5px !important",
    // width: '533px !important',
    //border:'1px solid !important',
    backgroundColor: theme.palette.btnPrimary.light,

    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
    "& input::placeholder": {
      fontFamily: "Roboto !important",
      color: "#787878 !important",
      fontSize: "16px !important",
      opacity: 1,
    },
  },
  customTextField: {
    "& input::placeholder": {
      fontSize: "16px",
      color: "#787878",
    },
  },
  skillField: {
    height: "40px !important",
    borderRadius: "5px !important",
    border: "1px solid #787878 !important",
    backgroundColor: "transparent",

    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
    "& input::placeholder": {
      fontSize: "16px",
      color: "#787878 !important",
    },
  },
  skillField1: {
    height: "40px !important",
    borderRadius: "5px !important",
    // border: '1px solid #787878 !important',
    backgroundColor: theme.palette.btnPrimary.light,

    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
    "& input::placeholder": {
      fontFamily: "Roboto !important",
      color: "#787878 !important",
      fontSize: "16px !important",
      opacity: 1,
    },
  },
  textareasty: {
    width: "533px",
    // background: "#EBEFF8",
    borderRadius: "4px",
    //  border: "1px solid #787878",
    outline: "none",
    padding: "10px",
    marginTop: "10px",
    fontFamily: "Roboto",
    color: "#2F2F2F",
    fontSize: "16px",
    "&::placeholder": {
      fontFamily: "Roboto !important",
      color: "#787878 !important",
      fontSize: "16px !important",
      opacity: 1,
    },
  },
  textareasty1: {
    width: "533px",
    background: "#EBEFF8",
    borderRadius: "4px",
    border: "none",
    outline: "none",
    padding: "10px",
    marginTop: "10px",
    fontFamily: "Roboto",
    color: "#2F2F2F",
    fontSize: "16px",
    "&::placeholder": {
      fontFamily: "Roboto !important",
      color: "#787878 !important",
      fontSize: "16px !important",
      opacity: 1,
    },
  },
  hideInput: {
    "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
      display: "none!important",
    },
  },

  candidateResumeUploadBtn: {
    textTransform: "unset !important",
    marginTop: "16px !important",
    marginBottom: "8px !important",
    width: "100% !important",
    height: "48px !important",
    fontWeight: "400 !important",
    border: "1px solid #787878 !important",
    color: "#787878 !important",
    justifyContent: "space-between !important",
  },

  candidateResumeUploadBtnFilled: {
    background: "#EBEFF8 !important",
  },
  fieldpadding: {
    paddingTop: "5px !important",
  },

  messageContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(3),
  },

  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    gap: theme.spacing(2),
  },
  textview: {
    marginRight: "100px",
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "20px !important",
    color: `${theme.palette.black.dark}`,
    // textAlign:'center',
    paddingTop: "20px",
    paddingBottom: "20px",
  },
});

const featureNotAccesMessage = "This feature is not available in your subscription plan.";

const AddNewCandidates = () => {
  const classes = useStyles();
  const classesForGridTable = useStylesGridTable();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  // const [editList, setEditList] = useState([]);
  const authState = useSelector((state) => state.auth);
  const [skeletonLoading, setSkeletonLoading] = useState(true);
  const [snacktext, setsnacktext] = useState("");
  const [emailErrMsg, setEmailErrMsg] = useState("");
  // eslint-disable-next-line
  const [perpage, setPerpage] = useState(100);
  const [skilldata, setSkilldata] = useState([]);
  // eslint-disable-next-line
  const [skillindex, setSkillindex] = useState(0);
  const [fileObj, setFileDetail] = useState({
    fileName: "",
    fileSize: 0,
  });
  const [useraddid, setUseraddid] = useState(null);
  const [editedData, setEditedData] = useState([]);
  const [uiSortModel, setUiSortModel] = useState([
    {
      field: "fullName",
      sort: "asc",
    },
  ]);
  const [addview, setAddview] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const dispatch = useDispatch();
  const { id } = useParams();
  // const [userid, setUserid] = useState(null);
  // const [editview, setEditview] = useState(false);
  // const [savebtn, setSavebtn] = useState(false);
  const [linkedinDetails, setLinkedinDetails] = useState({});
  const inputRef = useRef(null);
  const navigate = useNavigate();
  const pathName = location.pathname;
  // const roleState = useSelector((state) => state.role);
  const [degree, setDegree] = useState("");
  // const [year, setYear] = useState("");
  let editPath = `/editcandidate/${id}`;
  let userDetails = JSON.parse(localStorage.getItem("user_details"));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [disableInput, setDisableInput] = useState(false);
  const [openDialogText, setOpenDialogText] = useState(false);
  const [DialogText, setDialogText] = useState("");
  const [treeMapData, setTreeMapData] = useState([]);
  const [isGridVisible, setIsGridVisible] = useState(false);

  //setting initial values of the form
  const initialValues = {
    givenName: pathName === editPath ? authState?.candidatedetails?.user?.firstName : "",
    familyName: pathName === editPath ? authState?.candidatedetails?.user?.lastName : "",
    linkedInUrl: pathName === editPath ? authState?.candidatedetails?.linkedInProfile : "",
    phone: pathName === editPath ? authState?.candidatedetails?.user?.phone : "",
    email: pathName === editPath ? authState?.candidatedetails?.user?.email : "",
    jobTitle: pathName === editPath ? authState?.candidatedetails?.jobTitle : "",
    location: pathName === editPath ? authState?.candidatedetails?.location : "",
    year: pathName === editPath ? authState?.candidatedetails?.yoe : "",
    skills:
      pathName === editPath && authState?.candidatedetails?.skills?.length !== 0
        ? skilldata
        : [
            {
              id: "",
              skill: "",
              monthsUsed: "",
              lastUsed: "",
            },
            {
              id: "",
              skill: "",
              monthsUsed: "",
              lastUsed: "",
            },
            {
              id: "",
              skill: "",
              monthsUsed: "",
              lastUsed: "",
            },
            {
              id: "",
              skill: "",
              monthsUsed: "",
              lastUsed: "",
            },
            {
              id: "",
              skill: "",
              monthsUsed: "",
              lastUsed: "",
            },
            {
              id: "",
              skill: "",
              monthsUsed: "",
              lastUsed: "",
            },
            {
              id: "",
              skill: "",
              monthsUsed: "",
              lastUsed: "",
            },
            {
              id: "",
              skill: "",
              monthsUsed: "",
              lastUsed: "",
            },
            {
              id: "",
              skill: "",
              monthsUsed: "",
              lastUsed: "",
            },
            {
              id: "",
              skill: "",
              monthsUsed: "",
              lastUsed: "",
            },
          ],
    textAreaValue: "",
  };
  // validation schema for the form.
  const validationSchema = Yup.object().shape({
    givenName: Yup.string().required("This field cannot be empty"),
    familyName: Yup.string().required("This field cannot be empty").required("This field cannot be empty"),
    email: Yup.string().email("Invalid email format").required("This field cannot be empty"),
    year: Yup.number().min(0, "Enter a value between 0 and 50").max(50, "Enter a value between 0 and 50"),
    linkedInUrl: Yup.string()
      .transform(function (value) {
        return value !== null ? value.toLowerCase() : value;
      })
      .matches(/^(https:\/\/www.linkedin.com|https:\/\/linkedin.com|www.linkedin.com)/, "Enter correct URL"),
  });
  const formik = useFormik({
    initialValues,
    // onSubmit,
    validationSchema,
    enableReinitialize: true,
  });
  //setting degree input, set the degree state to the new value.
  const HandleChangeDegree = (value) => {
    setDegree(value);
  };
  // setting year
  // const HandleChangeYear = (value) => {
  //   setYear(value);
  // };
  let data = [
    {
      skill: "",
      monthsUsed: "",
      lastUsed: "",
    },
    {
      skill: "",
      monthsUsed: "",
      lastUsed: "",
    },
    {
      skill: "",
      monthsUsed: "",
      lastUsed: "",
    },
    {
      skill: "",
      monthsUsed: "",
      lastUsed: "",
    },
    {
      skill: "",
      monthsUsed: "",
      lastUsed: "",
    },
    {
      skill: "",
      monthsUsed: "",
      lastUsed: "",
    },
    {
      skill: "",
      monthsUsed: "",
      lastUsed: "",
    },
    {
      skill: "",
      monthsUsed: "",
      lastUsed: "",
    },
    {
      skill: "",
      monthsUsed: "",
      lastUsed: "",
    },
    {
      skill: "",
      monthsUsed: "",
      lastUsed: "",
    },
  ];

  //initial render of the page.
  useEffect(() => {
    if (pathName !== editPath) {
      dispatch(auth.checkSubscriptionStatusCandidate());
    }
    if (id) {
      dispatch(auth.candidateDetails(id));
      dispatch(role.referenceData());
    } else {
      dispatch(role.referenceData());
      // dispatch(auth.candidateFilter());
    }
    dispatch(auth.validateFeatureAccess("skillTreeMap"));
  }, []);

  const handleIconClick = () => {
    setIsGridVisible(!isGridVisible);
  };

  // run updates once candidateDetails skills are populated
  useEffect(() => {
    if (authState?.candidatedetails?.skills) {
      let PostData = authState?.candidatedetails?.skills?.concat(data);
      setSkilldata(PostData.slice(skillindex, skillindex + perpage));
      // setTreeMapData(authState?.candidatedetails?.skills || []);
      setSkeletonLoading(false);
    }
    // eslint-disable-next-line
  }, [authState?.candidatedetails?.skills]);

  useEffect(() => {
    if (authState?.validateFeatureAccessSuccess?.data?.access) {
      setIsGridVisible(true);
    } else {
      setIsGridVisible(false);
    }
  }, [authState?.validateFeatureAccessSuccess?.data]);

  const addNewCandidate = () => {
    setIsSubmitting(true);
    // check monthsUsed, lastUsed then only send data
    const skillsWithMonthsAndLastUsed = editedData
      .filter(
        (obj) =>
          obj.hasOwnProperty("monthsUsed") &&
          obj.hasOwnProperty("lastUsed") &&
          obj.hasOwnProperty("skill") &&
          obj.monthsUsed &&
          obj.lastUsed
      )
      .map((obj) => {
        const date = moment(obj.lastUsed);
        if (date.isValid()) {
          obj.lastUsed = date.format("MMM yyyy");
        }
        // Create a new object without the id and fullName properties
        const { id, fullName, lastUsedInMonths, ...newObj } = obj;
        return newObj;
      });
    let payload = {
      linkedInProfile: formik.values.linkedInUrl,
      firstName: formik.values.givenName,
      lastName: formik.values.familyName,
      email: formik.values.email,
      phone: formik.values.phone,
      jobTitle: formik.values.jobTitle,
      degree: degree ? degree.id : formik.values.degree,
      yoe: formik.values.year,
      location: formik.values.location,
      resumeUrl: authState?.uploadresume?.data?.location
        ? authState?.uploadresume?.data?.location
        : authState?.candidatedetails?.resumeUrl,
      resumeName: authState?.uploadresume?.data?.fileName
        ? authState?.uploadresume?.data?.fileName
        : authState?.candidatedetails?.resumeName,
      resumeSize: authState?.uploadresume?.data?.fileSize
        ? authState?.uploadresume?.data?.fileSize
        : authState?.candidatedetails?.fileSize,
      skills: skillsWithMonthsAndLastUsed,
      note: formik.values.textAreaValue,
      resumeCollectionId: authState?.uploadresume?.data?.resumeCollectionId
        ? authState?.uploadresume?.data?.resumeCollectionId
        : "",
      linkedinDetails: linkedinDetails,
      Social: authState?.uploadresume?.data?.Social,
    };
    if (pathName === editPath) {
      let payload1 = {
        data: payload,
        id: id,
      };
      dispatch(auth.editCandidate(payload1));
    } else {
      dispatch(auth.addCandidate(payload));
    }
  };

  // run update the add candidate status
  useEffect(() => {
    if (authState.addcandidate.status === 0) {
      setOpen(true);
      setsnacktext("New candidate added");
      let add_id = authState?.addcandidate?.data?.uid;
      setAddview(true);
      setUseraddid(add_id);
      dispatch(auth.candidateDetails(add_id));
      // setTimeout(() => {
      //   setSavebtn(false);
      // }, 1000);
    } else if (authState.addcandidate.status === 1) {
      setOpen(true);
      setsnacktext("Validation Error");
      // setSavebtn(false);
    }
    // eslint-disable-next-line
  }, [authState?.addcandidate?.status]);

  useEffect(() => {
    if (addview && authState?.candidatedetails?.uid) {
      navigate(generatePath("/candidateview/:id", { id: useraddid }));
    }
    // eslint-disable-next-line
  }, [addview && authState?.candidatedetails?.uid]);

  useEffect(() => {
    // run update the edit candidate status
    if (authState.editcandidate.status === 0) {
      setOpen(true);
      setsnacktext("Changes saved");
      // let edit_id = authState?.editcandidate?.data?.uid // FK cleanup unused variable july 2023
      navigate(generatePath("/candidateview/:id", { id }));
      // setTimeout(() => {
      //   setSavebtn(false);
      // }, 1000);
    } else if (authState.editcandidate.status === 1) {
      setOpen(true);
      setsnacktext("Already Created");
      // setSavebtn(false);
    }
    // eslint-disable-next-line
  }, [authState?.editcandidate?.status]);

  // If the pathname is equal to the editPath, then set the userid to the id, set the editview to true,and navigate to the candidateview path. Otherwise, navigate to the candidatelist path.
  const cancelCandidate = () => {
    if (pathName === editPath) {
      // setUserid(id);
      // setEditview(true);
      // dispatch(auth.candidateDetails(id))
      navigate(generatePath("/candidateview/:id", { id }));
    } else {
      navigate("/candidatelist");
    }
  };

  // expression that checks if the email is valid.
  // FK cleanup check this regex july 2023
  let regEmail =
    // eslint-disable-next-line
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // check the available email
  const checkAvailabilityEmail = (email) => {
    let payloadSearchbyemail = {
      searchTxt: email.toLowerCase(),
      type: "Candidate",
    };
    if (pathName === editPath) {
      payloadSearchbyemail.uid = id;
    }
    if (email === "") {
      setEmailErrMsg("This field cannot be empty");
    } else if (email !== "" && regEmail.test(email)) {
      dispatch(auth.candidateSearchByEmail(payloadSearchbyemail));
    } else if (!regEmail.test(email) && email !== "") {
      setEmailErrMsg("Invalid Email Address");
    } else {
      setEmailErrMsg("");
    }
  };

  // Checking if the email address is already in use with an existing user.
  useEffect(() => {
    if (authState?.searchByEmail?.error && authState?.searchByEmail?.error === true) {
      setEmailErrMsg(authState?.searchByEmail?.message);
    } else if (authState?.candidatedetails?.user?.email === authState?.searchByEmail?.email) {
      setEmailErrMsg("");
    } else if (
      formik.values.email === authState?.searchByEmail?.email ||
      Object.keys(authState?.searchByEmail).length !== 0
    ) {
      setEmailErrMsg(authState?.searchByEmail?.message);
    } else {
      setEmailErrMsg("");
    }
  }, [authState?.searchByEmail?.email]);

  // handles to upload the resume
  const uploadFile = ({ target: { files } }) => {
    if (files && files[0]) {
      // max limit 5 mb
      const MAX_SIZE = 1024 * 1024 * 5; // 5MB in bytes

      const selectedFile = files[0];
      const fileName = selectedFile.name;
      const fileSize = selectedFile.size;
      if (fileSize > MAX_SIZE) {
        setOpenDialogText(true);
        setDialogText("File size exceeds 5MB. Please upload a smaller file.");
        document.getElementById("candidate-upload-resume-file").value = "";
        return;
      }
      setFileDetail({
        fileName,
        fileSize,
      });
      let data = new FormData();
      let dpUploadedFileName = files[0].name;
      dpUploadedFileName = dpUploadedFileName.replaceAll(" ", "_");
      data.append("file", files[0], dpUploadedFileName);
      dispatch(auth.resumeScanDetails(data));
      //switch to chart
      if (authState?.validateFeatureAccessSuccess?.data?.access) {
        setIsGridVisible(true);
      } else {
        setIsGridVisible(false);
      }
      setDisableInput(true);
    }
  };

  // run if upload resume status updated
  useEffect(() => {
    if (authState?.uploadresume?.status === 1) {
      setOpenToast(true);
      setToastMessage(authState?.uploadresume?.message);
    }
  }, [authState?.uploadresume?.message, authState?.uploadresume?.status]);

  // handle to remove the resume
  const RemoveResume = () => {
    let payloadresume = authState?.uploadresume?.data?.location
      ? authState?.uploadresume?.data?.location
      : authState?.candidatedetails?.resumeUrl;
    let payload = {
      payloadresume: payloadresume,
      id: id,
    };
    dispatch(auth.candidateRemoveResume(payload));
    const updatedSkills = [];
    const skillKeys = Object.keys(authState?.skillsConfig);
    skillKeys.forEach((key) => {
      const value = authState?.skillsConfig[key];
      updatedSkills.push({
        skill: key,
        fullName: value.fullName,
        monthsUsed: "",
        lastUsed: "",
        id: Math.random(),
      });
    });
    let fieldName = "fullName";
    let fieldtype = "asc";
    setUiSortModel([{ field: fieldName, sort: fieldtype }]);
    let sortedOrder = sortJson([...updatedSkills], fieldtype, fieldName);
    setEditedData(sortedOrder);
    setFileDetail(null);
    inputRef.current.value = "";
    formik.resetForm();
    setEmailErrMsg("");
    setIsGridVisible(false);
    setDisableInput(false);
  };

  // run if remove resume status updated
  useEffect(() => {
    if (authState?.resumeremove?.status === 0) {
      setOpen(true);
      setsnacktext("Resume removed successfully");
      if (pathName === editPath) {
        dispatch(auth.candidateDetails(id));
      }
    }
    // eslint-disable-next-line
  }, [authState?.resumeremove?.status]);

  // This code is setting the values of the form fields based on the response from the API.
  useEffect(() => {
    const uploadedResumeData = authState?.uploadresume?.data;
    const uploadedResumeStatus = authState?.uploadresume?.status;
    if (uploadedResumeStatus === 0 && uploadedResumeData !== null) {
      // Set the value of the first name field here if exists in response:
      if (uploadedResumeData?.FirstName) {
        formik.setFieldValue("givenName", uploadedResumeData.FirstName);
      }
      if (uploadedResumeData?.length !== 0 && uploadedResumeData?.FirstName === undefined) {
        formik.setFieldValue("givenName", "");
      }
      // Set the value of the last name field here if exists in response:
      if (uploadedResumeData?.LastName) {
        formik.setFieldValue("familyName", uploadedResumeData.LastName);
      }
      if (uploadedResumeData?.length !== 0 && uploadedResumeData?.LastName === undefined) {
        formik.setFieldValue("familyName", "");
      }
      // Set the value of the phone field here if exists in response:
      if (uploadedResumeData?.Phone) {
        formik.setFieldValue("phone", uploadedResumeData.Phone);
      }
      if (uploadedResumeData?.length !== 0 && uploadedResumeData?.Phone === undefined) {
        formik.setFieldValue("phone", "");
      }
      // Set the value of the email field here if exists in response:
      if (uploadedResumeData?.Email) {
        formik.setFieldValue("email", uploadedResumeData.Email);
        checkAvailabilityEmail(uploadedResumeData.Email);
      }
      if (uploadedResumeData?.length !== 0 && uploadedResumeData?.Email === undefined) {
        formik.setFieldValue("email", "");
      }
      // Set the value of the location field here if exists in response:
      if (uploadedResumeData?.Location) {
        formik.setFieldValue("location", uploadedResumeData.Location ? uploadedResumeData.Location.Municipality : "");
      }
      if (uploadedResumeData?.length !== 0 && uploadedResumeData?.Location === undefined) {
        formik.setFieldValue("location", "");
      }
      // Set the value of the social field here if exists in response:
      if (uploadedResumeData?.Social) {
        formik.setFieldValue("linkedInUrl", uploadedResumeData?.Social?.linkedinUrl);
        handleOnBlurLinkedinUrl(uploadedResumeData?.Social?.linkedinUrl);
      }
      if (uploadedResumeData?.length !== 0 && uploadedResumeData?.Social === undefined) {
        formik.setFieldValue("linkedInUrl", "");
      }
      // Set the value of the jobTitle field here if exists in response:
      if (uploadedResumeData?.JobTitle) {
        formik.setFieldValue("jobTitle", uploadedResumeData.JobTitle);
      }
      if (uploadedResumeData?.length !== 0 && uploadedResumeData?.JobTitle === undefined) {
        // console.log(formik.values.email);
        formik.setFieldValue("jobTitle", "");
      }
      // Set the value of the yoe field here if exists in response:
      if (uploadedResumeData?.MonthsOfWorkExperience) {
        // console.log(formik.values.year);
        // const yearVal = Math.round(authState.fetchedResumeDetails.MonthsOfWorkExperience/12);
        const yearVal = Math.floor(uploadedResumeData.MonthsOfWorkExperience / 12);
        if (!yearVal || yearVal === "" || yearVal === undefined || yearVal == null || yearVal === "unknown") {
          formik.setFieldValue("year", "");
        } else if (yearVal > 0 && yearVal <= 50) {
          formik.setFieldValue("year", yearVal);
        } else if (yearVal > 50) {
          formik.setFieldValue("year", 50);
        }
      }
      // Set the value of the yoe field here if exists in response:
      // if (uploadedResumeData && uploadedResumeData?.FirstName) {
      //   const yearVal = Math.round(uploadedResumeData?.MonthsOfWorkExperience / 12);
      //   if (yearVal <= 10) {
      //     HandleChangeYear(yearVal?.toFixed());
      //   } else if (yearVal === 0) {
      //     HandleChangeYear("");
      //   }
      //   //  else if (yearVal > 10) {
      //   //   HandleChangeYear("10");
      //   // }
      // }
      // Set the value of the degree field here if exists in response:
      if (uploadedResumeData?.DegreeNormalized) {
        const degrees = transformOptions(degreeMapping);
        const matchedDegree = degrees?.find(
          (degree) =>
            degree?.id === uploadedResumeData?.DegreeNormalized ||
            degree?.displayName === uploadedResumeData?.DegreeNormalized
        );
        setDegree(matchedDegree);
      }
      // Set the value of the degree field here if exists in response:
      if (uploadedResumeData?.Skills) {
        const skill = uploadedResumeData.Skills;
        let retrivedSkillsList = sortJson(
          [...Object.entries(skill).filter((ele) => ele[1].MonthsExperienceNet !== 0)],
          "desc",
          "MonthsExperienceNet"
        );
        retrivedSkillsList = sortJson([...retrivedSkillsList], "desc", "MonthsExperienceNet", "date");
        let skillsArray = [];
        if (retrivedSkillsList.length > 0) {
          skillsArray = retrivedSkillsList.map((retrivedSkillObj) => {
            let toSetSkillObj = { skill: "", monthsUsed: "", lastUsed: "" };
            if (retrivedSkillObj[0]) {
              toSetSkillObj.skill = retrivedSkillObj[0];
            }
            if (retrivedSkillObj[1].MonthsExperienceNet) {
              toSetSkillObj.monthsUsed = retrivedSkillObj[1].MonthsExperienceNet;
            }
            if (retrivedSkillObj[1].LastUsed) {
              toSetSkillObj.lastUsed = moment(retrivedSkillObj[1].LastUsed, "YYYY-MM-DD").format("MMM YYYY");
              // console.log(toSetSkillObj.lastUsed,"after -----")
            }
            return toSetSkillObj;
          });
        }
        if (skillsArray.length < 32) {
          let emptySkillstoAddCount = 32 - skillsArray.length;
          const emptySkillObj = {
            skill: "",
            monthsUsed: "",
            lastUsed: "",
          };
          for (let i = 1; i <= emptySkillstoAddCount; i++) {
            skillsArray.push(emptySkillObj);
          }
        }
        formik.setFieldValue("skills", [...skillsArray]);
      }
    }
  }, [authState?.uploadresume]);

  // handle to download the resume
  const Downloadresume = () => {
    let payloaddownload = authState?.uploadresume?.data?.location
      ? authState?.uploadresume?.data?.location
      : authState?.candidatedetails?.resumeUrl;
    dispatch(auth.downloadResume(payloaddownload));
  };

  const setOpenFunc = () => {
    setOpenToast(false);
  };

  useEffect(() => {
    if (authState?.downloadresume?.status === 0) {
      if (Object.keys(authState?.downloadresume?.data).length !== 0) {
        window.open(authState.downloadresume.data, "_self");
      } else {
        setOpen(true);
        setsnacktext(authState?.downloadresume?.message);
      }
    }
    // eslint-disable-next-line
  }, [authState?.downloadresume?.status]);

  // linkedin profile
  const handleOnBlurLinkedinUrl = async (profileUrl = "") => {
    if (formik.values.linkedInUrl.trim() === "" && profileUrl === "") {
      return;
    }
    let url = profileUrl || formik.values.linkedInUrl;
    url = url.toLowerCase();
    let incompleteUrl = /^(linkedin.com)/;
    if (incompleteUrl.test(url)) {
      url = `https://www.${url}`;
    }
    let regex = /^(https:\/\/www.linkedin.com|https:\/\/linkedin.com|www.linkedin.com)/;
    if (regex.test(url)) {
      // console.log(url)
      try {
        let result = await fetch(`${process.env.REACT_APP_API_URL}/candidate/new/linkedInProfileInfo`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token1"),
          },
          body: JSON.stringify({ url: url }),
        });
        result = await result.json();
        if (result?.data?.data && result?.data?.data !== undefined) {
          setLinkedinDetails(result?.data?.data);
          formik.setFieldValue("location", result?.data?.data?.location);
        }
      } catch (err) {
        console.log(err); // FK cleanup console july 2023
      }
    }
  };

  const handleEditCell = (params) => {
    const { id, field, value } = params;
    // check zero or negative value
    if (field === "monthsUsed" && value < 1) {
      // alert('Zero/Negative values are not allowed');
      return;
    }
    // check future values
    if (field === "lastUsed") {
      const currentDate = new Date();
      const selectedDate = new Date(value);
      if (selectedDate > currentDate) {
        // alert('Future values are not allowed');
        return;
      }
    }

    const updatedData = editedData.map((item) => (item.id === id ? { ...item, [field]: value } : item));
    setEditedData(updatedData);
  };

  // Last used in months
  function lastUsedInMonths(luTxt) {
    var now = moment();
    var then = moment(luTxt, "MMM YYYY");
    if (!then.isValid()) {
      return 0;
    }

    return now.diff(then, "months");
  }

  useEffect(() => {
    if (editedData && editedData.length !== 0) {
      let editedTreeData = [];
      editedData?.forEach((itm) => {
        editedTreeData.push({ ...itm });
      });

      let newDataSet = editedTreeData.filter((item) => item.monthsUsed && item.lastUsed);
      // eslint-disable-next-line
      newDataSet.map((itm) => {
        ///////
        // itm['lastUsed'] = moment(itm['lastUsed'],'MMM YYYY').format('MMM YYYY');
        if (!itm["lastUsedInMonths"] || itm["lastUsedInMonths"] === "") {
          itm["lastUsedInMonths"] = lastUsedInMonths(itm["lastUsed"]);
        }
      });

      setTreeMapData(newDataSet);
    }
  }, [editedData]);

  const columns = [
    {
      field: "fullName",
      headerName: "Skills",
      flex: 2.066,
      disableColumnMenu: true,
      headerClassName: classesForGridTable.skillsHeader,
      renderCell: (params) => <div className={classesForGridTable.skillsColumn}>{params.value}</div>,
    },
    {
      field: "monthsUsed",
      headerName: "Months Used",
      type: "number",
      flex: 1.4,
      editable: true,
      align: "right",
      headerAlign: "center",
      headerClassName: classesForGridTable.monthsHeader,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div className={classesForGridTable.monthsUsedValueGutterRight}>{params.value > 0 ? params.value : null}</div>
      ),
    },
    {
      field: "lastUsed",
      headerName: "Last Used",
      type: "date",
      flex: 0.94,
      editable: true,
      align: "left",
      headerAlign: "left",
      disableColumnMenu: true,
      headerClassName: classesForGridTable.gutterLeftHeader,
      renderCell: (params) => (
        <div
          className={classesForGridTable.lastUsedValueGutterLeft}
          style={{
            color: moment(params.value, "MMM YYYY").isAfter(new Date()) ? "#DD2E2E" : "#2F2F2F",
          }}
        >
          {moment(params.value, "MMM YYYY").isAfter(new Date())
            ? ""
            : params.value
            ? moment(params.value, "MMM YYYY").format("DD MMM 'YY")
            : null}
          {/* {params.value ? moment(params.value).format('MMM yyyy') : null} */}
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (formik?.values?.skills.length) {
      const updatedSkill = [];
      const updatedSkills = [];
      const skillKeys = Object.keys(authState?.skillsConfig);

      // Sort skillKeys alphabetically based on the full string value
      skillKeys.sort((a, b) => {
        const aFullName = authState?.skillsConfig[a].fullName.trim().toLowerCase();
        const bFullName = authState?.skillsConfig[b].fullName.trim().toLowerCase();
        return aFullName.localeCompare(bFullName);
      });

      skillKeys.forEach((key) => {
        const skill = formik?.values?.skills.find((skill) => skill.skill === key);
        if (!skill) {
          const value = authState?.skillsConfig[key];
          updatedSkills.push({
            skill: key,
            fullName: value.fullName,
            monthsUsed: "",
            lastUsed: "",
            id: Math.random(),
          });
        } else {
          updatedSkill.push({
            ...skill,
            fullName: authState?.skillsConfig[key].fullName,
            id: Math.random(),
          });
        }
      });

      const res = updatedSkill.concat(updatedSkills);
      let fieldName = res[0]?.monthsUsed ? "monthsUsed" : "fullName";
      let fieldtype = res[0]?.monthsUsed ? "desc" : "asc";
      setUiSortModel([{ field: fieldName, sort: fieldtype }]);
      let sortedOrder = sortJson([...res], fieldtype, fieldName);
      setEditedData(sortedOrder);
    }
  }, [formik?.values?.skills, authState?.skillsConfig]);

  const handleSortChange = (sortInfo) => {
    const fieldType = sortInfo.field === "fullName" ? "string" : "";
    const field = sortInfo.field || uiSortModel[0].field;
    setUiSortModel([sortInfo]);
    let sortedData;
    if (field === "lastUsed") {
      sortedData = sortJson([...editedData], sortInfo.sort, "lastUsed", "date", "lastUsed");
      sortedData.sort((a, b) => {
        if (a.lastUsed === b.lastUsed) {
          return a.fullName.localeCompare(b.fullName);
        }
        return 0;
      });
    } else {
      sortedData = sortJson([...editedData], sortInfo.sort, field, fieldType);
    }

    // Filter out records with non-empty values
    const nonEmptyData = sortedData.filter((record) => record[field]);
    // Filter out records with empty values
    const emptyData = sortedData.filter((record) => !record[field]);
    // Concatenate the two arrays to produce the final sorted array with empty values at the end
    const finalData = [...nonEmptyData, ...emptyData];
    setEditedData(finalData);
  };
  const isMaxLimitZero = authState?.checkSubscriptionStatusCandidateData?.maxLimit === 0;

  const degreeMapping = {
    Bachelors: "Bachelors",
    Masters: "Masters",
    PhD: "PhD",
    Other: "Other",
  };

  useEffect(() => {
    if (authState?.candidatedetails?.degree) {
      const degrees = transformOptions(degreeMapping);
      const matchedDegree = degrees?.find(
        (degree) =>
          degree?.id === authState?.candidatedetails?.degree ||
          degree?.displayName === authState?.candidatedetails?.degree
      );
      setDegree(matchedDegree);
    }
  }, [authState?.candidatedetails?.degree]);

  const handleDailog = () => {
    setOpenDialogText(false);
  };

  return (
    <>
      {isMaxLimitZero ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <NavBar />
          <Container maxWidth="xl" className={classes.container}>
            <Grid
              container
              direction="column"
              alignItems="center"
              style={{ width: "50%", margin: "auto", textAlign: "center" }}
            >
              <Grid item className={classes.messageContainer}>
                {userDetails.roleSubType !== "TeamAdmin" &&
                authState.checkSubscriptionStatusCandidateData.button === "upgrade" ? (
                  <Typography>
                    {" "}
                    You have reached your usage limit for adding a candidate or your subscription is not active. Please
                    contact Team Admin to upgrade your subscription.
                  </Typography>
                ) : (
                  <Typography>{authState.checkSubscriptionStatusCandidateData.message}</Typography>
                )}
              </Grid>
              <Grid item style={{ marginTop: "30px" }} className={classes.buttonContainer}>
                <Typography sx={{ mr: 3 }} className={classes.textview}>
                  <a href="/candidatelist">Back to Candidates page</a>
                </Typography>
                {userDetails.roleSubType === "TeamAdmin" &&
                authState.checkSubscriptionStatusCandidateData.button === "upgrade" ? (
                  <Button
                    variant="contained"
                    color="btn"
                    onClick={() =>
                      navigate("/accountSettings", {
                        state: { selectedSection: "billing" },
                      })
                    }
                    text="Upgrade"
                  ></Button>
                ) : authState.checkSubscriptionStatusCandidateData.button === "sendInvite" ? (
                  <Button
                    variant="contained"
                    color="btn"
                    onClick={() => navigate("/inviteaddcandidates")}
                    text="Send Invite"
                  ></Button>
                ) : null}
              </Grid>
            </Grid>
          </Container>
        </div>
      ) : (
        <div>
          <NavBar />
          <Container maxWidth="xl" className={classes.container}>
            {skeletonLoading && pathName === editPath ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Grid container spacing={1} mt={"80px"} width={"85%"}>
                  <Grid item xs={12} md={12}>
                    <Stack spacing={1} mt={5}>
                      <Skeleton variant="text" sx={{ fontSize: "20px", width: "30%" }} />
                    </Stack>
                    <Grid item xs={12} md={12} mt={12}>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Skeleton variant="text" sx={{ fontSize: "40px", width: "200px" }} />
                        <Skeleton variant="text" sx={{ fontSize: "40px", width: "200px" }} />
                      </Box>
                    </Grid>
                    <p style={{ borderBottom: "1px solid #C4C4C4", marginTop: "25px", marginBottom: "25px" }} />

                    <Grid container item xs={12} md={12} spacing={2}>
                      <Grid item xs={12} md={12}>
                        <Grid container spacing={3}>
                          <Grid item xs={6} md={6}>
                            <Stack spacing={0.8}>
                              <Skeleton variant="text" sx={{ fontSize: "20px", width: "40%" }} />
                            </Stack>
                          </Grid>
                        </Grid>
                        <Grid container spacing={3} marginTop={2}>
                          {[...Array(2)].map((_, index) => (
                            <Grid item xs={6} md={6} key={index}>
                              <Stack spacing={0.8}>
                                <Skeleton variant="text" sx={{ fontSize: "20px", width: "30%" }} />
                                <Skeleton variant="text" sx={{ fontSize: "22px", width: "100%" }} />
                                {index === 0 && <Skeleton variant="text" sx={{ fontSize: "20px", width: "15%" }} />}
                              </Stack>
                            </Grid>
                          ))}
                        </Grid>
                        <Grid container spacing={3} marginTop={2}>
                          {[...Array(4)].map((_, index) => (
                            <Grid item xs={3} md={3} key={index}>
                              <Stack spacing={0.8}>
                                <Skeleton variant="text" sx={{ fontSize: "20px", width: "100%" }} />
                                <Skeleton variant="text" sx={{ fontSize: "22px", width: "100%" }} />
                              </Stack>
                            </Grid>
                          ))}
                        </Grid>
                        <Grid container spacing={3} marginTop={2}>
                          {[...Array(4)].map((_, index) => (
                            <Grid item xs={3} md={3} key={index}>
                              <Stack spacing={0.8}>
                                <Skeleton variant="text" sx={{ fontSize: "20px", width: "100%" }} />
                                <Skeleton variant="text" sx={{ fontSize: "22px", width: "100%" }} />
                              </Stack>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>
                    </Grid>
                    <p style={{ borderBottom: "1px solid #C4C4C4", marginTop: "25px", marginBottom: "25px" }} />

                    <Grid container item xs={12} md={12} columnSpacing={4}>
                      <Grid item xs={3} md={3}>
                        <Skeleton variant="text" sx={{ fontSize: "40px", width: "70%" }} />
                      </Grid>
                      <Grid item xs={3} md={3}>
                        {/* <Skeleton variant="text" sx={{ fontSize: "40px", width: "100%" }} /> */}
                      </Grid>
                      <Grid item xs={3} md={3}>
                        {/* <Skeleton variant="text" sx={{ fontSize: "40px", width: "100%" }} /> */}
                      </Grid>
                      <Grid item xs={3} md={3} sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Skeleton variant="text" sx={{ fontSize: "40px", width: "50%" }} />
                      </Grid>
                      <Grid item xs={12} md={12} mt={6}>
                        <Skeleton sx={{ bgcolor: "grey.300" }} variant="rectangular" width="100%" height="400px" />
                      </Grid>
                    </Grid>
                    {/* <p style={{ borderBottom: "1px solid #C4C4C4", marginTop: "25px", marginBottom: "25px" }} /> */}

                    <Grid container item xs={12} md={12} columnSpacing={4}>
                      <Grid item xs={9.07} md={9.07} mt={7}>
                        <Skeleton variant="text" sx={{ fontSize: "25px", width: "45%" }} />
                      </Grid>

                      <Grid container item xs={12} spacing={2} md={12} mt={1}>
                        <Grid item xs={6} md={6}>
                          <Skeleton sx={{ bgcolor: "grey.300" }} variant="rectangular" width="100%" height="150px" />
                        </Grid>
                      </Grid>
                    </Grid>
                    <p style={{ borderBottom: "1px solid #C4C4C4", marginTop: "25px", marginBottom: "25px" }} />

                    <Grid container item xs={12} md={12}>
                      <Grid item xs={12} md={12} mt={2}>
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                          <Box sx={{ display: "flex", justifyContent: "flex-start", gap: "20px" }}>
                            <Skeleton variant="text" sx={{ fontSize: "40px", width: "200px" }} />
                          </Box>

                          <Grid item xs={4} md={4}>
                            <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-start" }}>
                              <Skeleton variant="text" sx={{ fontSize: "50px", width: "60%" }} />
                            </Box>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <Grid style={{ marginTop: "130px" }}>
                <Grid>
                  <CustomBreadcrumbs
                    links={[
                      { name: "Home", path: "/home" },
                      { name: "Candidates", path: "/candidatelist" },
                      { name: pathName === editPath ? `${authState?.candidatedetails?.uid || ""}` : "Add Candidate" },
                    ]}
                  />
                </Grid>
                <Grid container className="Home-hero" alignItems="center">
                  <Grid item xs={1}></Grid>
                  <Grid item xs={10} style={{ marginTop: "25px" }}>
                    <form>
                      <Grid className={classes.spaceflex}>
                        <Typography className={classes.roletext}>
                          {pathName === editPath ? "Editing Candidate" : "New Candidate"}
                        </Typography>
                        {pathName === editPath && (
                          <Typography className={classes.roletext} display="inline-flex">
                            Candidate ID{" "}
                            {authState?.candidatedetails?.uid ? (
                              authState?.candidatedetails?.uid
                            ) : (
                              <Skeleton width={60} sx={{ ml: 1 }} />
                            )}
                          </Typography>
                        )}
                      </Grid>
                      <p style={{ borderBottom: "1px solid #C4C4C4" }} />
                      <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                        Profile & Proficiency
                      </Typography>
                      <Grid container spacing={3}>
                        <Grid item xs={6} className={classes.fieldpadding}>
                          <Grid style={{ marginTop: "10px" }}>
                            <Typography className={classes.roletype} style={{ color: "#2F2F2F", marginTop: "20px" }}>
                              Candidate Resume
                            </Typography>
                            <input
                              style={{ display: "none" }}
                              ref={inputRef}
                              id="candidate-upload-resume-file"
                              type="file"
                              accept=".pdf,.doc,.docx,.txt"
                              onChange={uploadFile}
                              onClick={(e) => e.target.files === null}
                              disabled={disableInput}
                            />
                            <label htmlFor="candidate-upload-resume-file">
                              <BaseButton
                                variant="outlined"
                                component="span"
                                className={
                                  authState?.uploadresume?.data?.orginalFileName
                                    ? `${classes.candidateResumeUploadBtn} ${classes.candidateResumeUploadBtnFilled}`
                                    : `${classes.candidateResumeUploadBtn}`
                                }
                                endIcon={
                                  authState.isResumeUploadRunning ? (
                                    <LoopIcon position="end" style={{ color: "#1789FC" }} />
                                  ) : (
                                    <UploadIcon
                                      position="end"
                                      style={{ color: disableInput ? "#787878" : "#1789FC" }}
                                    />
                                  )
                                }
                                onClick={() => analyticsEvent("resume", "Resume Upload clicked", "Resume Uploaded")}
                              >
                                {authState?.uploadresume?.data?.orginalFileName
                                  ? authState?.uploadresume?.data?.orginalFileName
                                  : pathName === editPath && authState?.candidatedetails?.resumeName !== undefined
                                  ? authState?.candidatedetails?.resumeName !== null
                                    ? authState?.candidatedetails?.resumeName
                                    : "Accepted file formats PDF, DOC, DOCX, TXT. File size max.5MB"
                                  : "Accepted file formats PDF, DOC, DOCX, TXT. File size max.5MB"}
                              </BaseButton>
                            </label>
                            {formik.touched.candidateResume && formik.errors.candidateResume ? (
                              <div className="error">
                                <Typography className={classes.errorty} style={theme.typography.error}>
                                  {formik.errors.candidateResume}
                                </Typography>
                              </div>
                            ) : null}
                            <div className="error" style={{ display: "flex", cursor: "pointer" }}>
                              {authState?.uploadresume?.data?.orginalFileName ||
                              (pathName === editPath &&
                                authState?.candidatedetails?.resumeName !== undefined &&
                                authState?.candidatedetails?.resumeName !== null) ? (
                                <span>
                                  <BaseButton size="small" onClick={RemoveResume} className={classes.resume}>
                                    Remove Resume
                                    <span>
                                      <DeleteForeverIcon style={{ verticalAlign: "bottom" }} />
                                    </span>
                                  </BaseButton>
                                  <BaseButton size="small" className={classes.resume} onClick={Downloadresume}>
                                    Download Resume
                                    <span>
                                      <DownloadIcon style={{ verticalAlign: "bottom" }} />{" "}
                                    </span>
                                  </BaseButton>
                                </span>
                              ) : (
                                <span></span>
                              )}
                            </div>
                          </Grid>
                        </Grid>
                        <Grid item xs={6} className={classes.fieldpadding}>
                          <Grid style={{ marginTop: "10px" }}>
                            <Typography className={classes.roletype} style={{ color: "#2F2F2F", marginTop: "20px" }}>
                              LinkedIn Profile
                            </Typography>
                            <TextInput
                              name="linkedInUrl"
                              placeholder="Ex. https://www.linkedin.com/in/xxx-xxx-xxx"
                              className={formik.values.linkedInUrl === "" ? classes.field : classes.field1}
                              id="linkedInUrl"
                              onChange={formik.handleChange}
                              error={
                                formik.touched.linkedInUrl && formik.errors.linkedInUrl
                                  ? Boolean(formik.errors.linkedInUrl)
                                  : null
                              }
                              onBlur={(e) => {
                                formik.handleBlur(e);
                                handleOnBlurLinkedinUrl();
                              }}
                              value={formik.values.linkedInUrl}
                              style={{ borderRadius: "5px" }}
                            ></TextInput>
                            {formik.touched.linkedInUrl && formik.errors.linkedInUrl ? (
                              <div className="error">
                                <Typography className={classes.errorty} style={theme.typography.error}>
                                  {formik.errors.linkedInUrl}
                                </Typography>
                              </div>
                            ) : null}
                          </Grid>
                        </Grid>
                        <Grid item xs={3} sx={{ width: "310px !important" }} className={classes.fieldpadding}>
                          <Grid style={{ marginTop: "10px" }}>
                            <Typography className={classes.roletype} style={{ color: "#2F2F2F", marginTop: "10px" }}>
                              First Name <span style={{ color: "red" }}>*</span>
                            </Typography>
                            <TextInput
                              name="givenName"
                              placeholder="Ex. John, Mary, Alice, Ali"
                              className={formik.values.givenName === "" ? classes.field : classes.field1}
                              width={310}
                              id="givenName"
                              onChange={formik.handleChange}
                              error={
                                formik.touched.givenName && formik.errors.givenName
                                  ? Boolean(formik.errors.givenName)
                                  : null
                              }
                              onBlur={formik.handleBlur}
                              value={formik.values.givenName}
                              style={{ borderRadius: "5px" }}
                              inputProps={{
                                maxLength: 50,
                              }}
                            ></TextInput>
                            {formik.touched.givenName && formik.errors.givenName ? (
                              <div className="error">
                                <Typography className={classes.errorty} style={theme.typography.error}>
                                  {formik.errors.givenName}
                                </Typography>
                              </div>
                            ) : null}
                          </Grid>
                        </Grid>
                        <Grid item xs={3} className={classes.fieldpadding}>
                          <Grid style={{ marginTop: "10px" }}>
                            <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                              Last Name <span style={{ color: "red" }}>*</span>
                            </Typography>
                            <TextInput
                              name="familyName"
                              placeholder="Ex. Jones, Kumar, Cheng"
                              className={formik.values.familyName === "" ? classes.field : classes.field1}
                              id="familyName"
                              onChange={formik.handleChange}
                              error={
                                formik.touched.familyName && formik.errors.familyName
                                  ? Boolean(formik.errors.familyName)
                                  : null
                              }
                              onBlur={formik.handleBlur}
                              value={formik.values.familyName}
                              style={{ borderRadius: "5px" }}
                              inputProps={{
                                maxLength: 50,
                              }}
                            ></TextInput>
                            {formik.touched.familyName && formik.errors.familyName ? (
                              <div className="error">
                                <Typography className={classes.errorty} style={theme.typography.error}>
                                  {formik.errors.familyName}
                                </Typography>
                              </div>
                            ) : null}
                          </Grid>
                        </Grid>
                        <Grid item xs={2.5} className={classes.fieldpadding}>
                          <Grid style={{ marginTop: "10px" }}>
                            <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                              Phone
                            </Typography>
                            <TextInput
                              name="phone"
                              placeholder="+1 999-999-9999"
                              className={formik.values.phone === "" ? classes.field : classes.field1}
                              id="phone"
                              onChange={formik.handleChange}
                              error={formik.touched.phone && formik.errors.phone ? Boolean(formik.errors.phone) : null}
                              onBlur={formik.handleBlur}
                              value={formik.values.phone}
                              style={{ borderRadius: "5px" }}
                            ></TextInput>
                          </Grid>
                        </Grid>
                        <Grid item xs={3.5} className={classes.fieldpadding}>
                          <Grid style={{ marginTop: "10px" }}>
                            <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                              Email Address <span style={{ color: "red" }}>*</span>
                            </Typography>
                            <TextInput
                              name="email"
                              placeholder="example@email.com"
                              className={formik.values.email === "" ? classes.field : classes.field1}
                              id="email"
                              onChange={formik.handleChange}
                              error={formik.touched.email && formik.errors.email ? Boolean(formik.errors.email) : null}
                              // onBlur={formik.handleBlur}
                              onBlur={() => {
                                checkAvailabilityEmail(formik.values.email);
                              }}
                              value={formik.values.email}
                              style={{ borderRadius: "5px" }}
                            ></TextInput>
                            {formik.touched.email && formik.errors.email ? (
                              <div className="error">
                                <Typography className={classes.errorty} style={theme.typography.error}>
                                  {formik.errors.email}
                                </Typography>
                              </div>
                            ) : null}
                            {authState?.searchByEmail?.email === formik.values.email && (
                              <div className="error">
                                <Typography className={classes.errorty} style={theme.typography.error}>
                                  {emailErrMsg}
                                </Typography>
                              </div>
                            )}
                            {formik.values.email === "" && (
                              <div className="error">
                                <Typography className={classes.errorty} style={theme.typography.error}>
                                  {emailErrMsg}
                                </Typography>
                              </div>
                            )}
                            {!regEmail.test(formik.values.email) && formik.values.email !== "" && (
                              <div className="error">
                                <Typography className={classes.errorty} style={theme.typography.error}>
                                  {emailErrMsg}
                                </Typography>
                              </div>
                            )}
                          </Grid>
                        </Grid>
                        <Grid item xs={3} className={classes.fieldpadding}>
                          <Grid style={{ marginTop: "10px" }}>
                            <Typography className={classes.roletype} style={{ color: "#2F2F2F", marginTop: "10px" }}>
                              Job Title
                            </Typography>
                            <TextInput
                              name="jobTitle"
                              placeholder="Ex. Senior Java Developer"
                              className={formik.values.jobTitle === "" ? classes.field : classes.field1}
                              id="jobTitle"
                              onChange={formik.handleChange}
                              error={
                                formik.touched.jobTitle && formik.errors.jobTitle
                                  ? Boolean(formik.errors.jobTitle)
                                  : null
                              }
                              onBlur={formik.handleBlur}
                              value={formik.values.jobTitle}
                              style={{ borderRadius: "5px" }}
                            ></TextInput>
                            {formik.touched.jobTitle && formik.errors.jobTitle ? (
                              <div className="error">
                                <Typography className={classes.errorty} style={theme.typography.error}>
                                  {formik.errors.jobTitle}
                                </Typography>
                              </div>
                            ) : null}
                          </Grid>
                        </Grid>
                        <Grid item xs={3} className={classes.fieldpadding}>
                          <Grid style={{ marginTop: "10px" }}>
                            <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                              Degree
                            </Typography>
                            <SelectMenu
                              fileObj={fileObj}
                              selectvalue={degreeMapping}
                              selectHandleChange={(value) => HandleChangeDegree(value)}
                              prevSelectedValue={degree ? degree : null}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={2.5} className={classes.fieldpadding}>
                          <Grid style={{ marginTop: "10px" }}>
                            <Typography
                              className={classes.roletype}
                              style={{
                                color: "#2F2F2F",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              Years of Experience
                            </Typography>
                            <TextInput
                              name="year"
                              type="number"
                              placeholder="Year of Experience"
                              className={formik.values.year === "" ? classes.field : classes.field1}
                              id="year"
                              onChange={formik.handleChange}
                              error={formik.touched.year && formik.errors.year ? Boolean(formik.errors.year) : null}
                              onBlur={formik.handleBlur}
                              value={formik.values.year}
                              style={{ borderRadius: "5px" }}
                            ></TextInput>
                            {formik.touched.year && formik.errors.year ? (
                              <div className="error">
                                <Typography className={classes.errorty} style={theme.typography.error}>
                                  {formik.errors.year}
                                </Typography>
                              </div>
                            ) : null}
                          </Grid>
                        </Grid>
                        <Grid item xs={3.5} className={classes.fieldpadding}>
                          <Grid style={{ marginTop: "10px" }}>
                            <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                              Location
                            </Typography>
                            <TextInput
                              name="location"
                              placeholder="Ex.Dallas, Mumbai, Shangai"
                              className={formik.values.location === "" ? classes.field : classes.field1}
                              id="location"
                              onChange={formik.handleChange}
                              error={
                                formik.touched.location && formik.errors.location
                                  ? Boolean(formik.errors.location)
                                  : null
                              }
                              onBlur={formik.handleBlur}
                              value={formik.values.location}
                              style={{ borderRadius: "5px" }}
                            ></TextInput>
                            {formik.touched.location && formik.errors.location ? (
                              <div className="error">
                                <Typography className={classes.errorty} style={theme.typography.error}>
                                  {formik.errors.location}
                                </Typography>
                              </div>
                            ) : null}
                          </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={3}>
                          <Grid item xs={12}>
                            <Grid
                              container
                              spacing={2}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Grid item xs={6}>
                                <Typography sx={{ mt: 4 }} className={classes.roletype1}>
                                  Technical Skills
                                </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                <Typography sx={{ mt: 3, mr: 1, textAlign: "end" }} className={classes.roletype1}>
                                  {isGridVisible ? (
                                    <Grid
                                      container
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        textAlign: "end",
                                      }}
                                    >
                                      <Typography sx={{ mr: 1 }}>Edit Skills</Typography>
                                      <EditIcon
                                        sx={{
                                          fontSize: "24px",
                                          width: "auto",
                                          mr: 1,
                                          // marginLeft: "10px",
                                          marginRight: "0px",
                                          color: "#1789fc",
                                        }}
                                        onClick={handleIconClick}
                                      />
                                    </Grid>
                                  ) : (
                                    <Grid
                                      container
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        textAlign: "end",
                                      }}
                                    >
                                      <Typography sx={{ mr: 1 }}>View Chart</Typography>
                                      <ViewQuiltIcon
                                        sx={{
                                          fontSize: "26px",
                                          width: "auto",
                                          mr: 1,
                                          // marginLeft: "10px",
                                          marginRight: "0px",
                                          color: "#1789fc",
                                        }}
                                        onClick={handleIconClick}
                                      />
                                    </Grid>
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                            {isGridVisible && (
                              <Box
                                style={{
                                  border: "solid 1px rgba(224, 224, 224, 1)",
                                  borderRadius: "5px",
                                  height: "518px",
                                  width: "97%",
                                  padding: "20px",
                                  margin: "20px",
                                  marginLeft: "0px",
                                  // display: isGridVisible ? "block" : "none",
                                }}
                              >
                                {authState?.validateFeatureAccessSuccess?.data?.access ? (
                                  <TreemapChart treeMapData={treeMapData} />
                                ) : (
                                  <Typography
                                    className={classes.roletype}
                                    style={{
                                      color: "red",
                                      paddingTop: "0px",
                                      textAlign: "center",
                                      marginTop: "250px",
                                    }}
                                  >
                                    {featureNotAccesMessage}
                                  </Typography>
                                )}
                              </Box>
                            )}
                          </Grid>
                          {!isGridVisible && (
                            <Grid
                              item
                              xs={7}
                              // style={{ display: isGridVisible ? "none" : "block" }}
                            >
                              <div style={{ width: "174%", height: "520" }}>
                                <GridTable
                                  checkbox={false}
                                  height={520}
                                  rows={editedData || []}
                                  columns={columns}
                                  sortModel={uiSortModel}
                                  onSortChange={handleSortChange}
                                  onCellEditCommit={handleEditCell}
                                />
                              </div>
                            </Grid>
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          <Grid style={{ marginTop: "10px" }}>
                            <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                              Internal Notes (optional)
                            </Typography>
                            <textarea
                              style={{ resize: "none" }}
                              id="textAreaValue"
                              value={formik.values.textAreaValue}
                              onChange={formik.handleChange}
                              placeholder="Enter your notes here"
                              rows={5}
                              cols={5}
                              className={
                                formik.values.textAreaValue === "" ? classes.textareasty : classes.textareasty1
                              }
                              maxLength="500"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <p style={{ borderBottom: "1px solid #C4C4C4" }} />
                      <Grid
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          paddingTop: "12px",
                        }}
                      >
                        <Button
                          data-rt-candidate-add="cancel"
                          color="cancel_btn"
                          text="Cancel"
                          // type="submit"
                          onClick={cancelCandidate}
                          width={112}
                        ></Button>
                        {/* FK cleanup Unexpected mix of '&&' and '||'. Use parentheses to clarify the intended order of operations july 2023 */}
                        {(formik.values.givenName !== "" &&
                          formik.values.familyName !== "" &&
                          formik.values.email !== "" &&
                          !formik.errors.year &&
                          Object.keys(authState?.searchByEmail).length === 0) ||
                        (pathName === editPath &&
                          authState?.candidatedetails?.user?.email === authState?.searchByEmail?.email) ? (
                          <Button
                            data-rt-candidate-add={pathName === editPath ? "savechanges" : "savecandidate"}
                            color={isSubmitting || /\s/.test(formik.values.email) ? "disablebtn" : "btn"}
                            width={199}
                            text={pathName === editPath ? "Save Changes" : "Add Candidate"}
                            // onClick={() => addNewCandidate()}
                            onClick={() => {
                              if (pathName === editPath) {
                                addNewCandidate();
                                analyticsEvent("Updated Candidate", "Edit Candidate clicked", "Edit Candidate");
                              } else {
                                addNewCandidate();
                                analyticsEvent("Add Candidate", "Add Candidate clicked", "Candidate Added");
                              }
                            }}
                            disabled={isSubmitting || /\s/.test(formik.values.email)}
                          ></Button>
                        ) : (
                          <Button
                            color="disablebtn"
                            width={199}
                            data-rt-candidate-add={pathName === editPath ? "savechanges" : "savecandidate"}
                            text={pathName === editPath ? "Save Changes" : "Add Candidate"}
                            // onClick={ContinueNext}
                          />
                        )}
                      </Grid>
                    </form>
                  </Grid>
                  <Grid item xs={1}></Grid>
                </Grid>
              </Grid>
            )}
          </Container>
          <Footer></Footer>
          <SimpleSnackbar open={open} setOpen={setOpen} text={snacktext} />
          <ReusableDialog
            open={openDialogText}
            onClose={handleDailog}
            content={<Typography>{DialogText}</Typography>}
            actions={[{ onClick: handleDailog, color: "btn1", autoFocus: true, text: "Ok" }]}
          />
          <ErrorSnackbar
            open={openToast}
            setOpen={() => setOpenFunc()}
            vertical={"bottom"}
            horizontal={"left"}
            severity="error"
            message={toastMessage}
          />
        </div>
      )}
    </>
  );
};

export default AddNewCandidates;
