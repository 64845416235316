import { call, put, takeLatest } from "redux-saga/effects";
import role from "../actions/role";
import auth from "../actions/auth";
import axios from "../../services/axios";
import roleTypes from "../types/role";

let PayloadSkipLimit = {
  start: 0,
  limit: 5,
  //selectText:selectText
};

function* roleList({ payload }) {
  try {
    if (payload) {
      const res = yield call(axios.request, {
        // method: "POST",
        // url: `/jobRole/list`,
        method: "GET",
        url: `/jobRole/new/list`,
        data: payload,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token1"),
        },
      });
      // eslint-disable-next-line
      res.data.map((i) => {
        i["displayName"] = i.createdBy.displayName;
      });
      yield put(role.roleListSuccess(res.data));
      yield put(role.roleTotalCount(res.totalCount));
      yield put(role.roleMyCount(res.myCount));
      yield put(role.roleAllCount(res.allCount));
    }
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

// function* roleFilter({payload}){
//   try{

//     const res = yield call(axios.request, {
//       method: "POST",
//       url: `jobRole/filters`,
//       data:payload,
//       headers: {
//         "Content-Type": "application/json",
//       Authorization: localStorage.getItem("token1"),
//       }
//     });
//     yield put(role.roleFilterSuccess(res.data));
//   }
//   catch(err){
//     yield put(auth.apiError(err));
//   }
// }

function* roleDetails({ payload }) {
  try {
    if (payload) {
      const res = yield call(axios.request, {
        method: "GET",
        url: `/jobRole/${payload}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token1"),
        },
      });
      if (res.status !== 0) {
        yield put(role.roleDetailsFailure(res.data));
      } else {
        yield put(role.roleDetailsSuccess(res.data[0]));
      }
    }
  } catch (err) {
    yield put(role.roleDetailsFailure(err));
    yield put(auth.apiError(err));
  }
}

function* referenceData({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/referenceData/Degree`,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    yield put(role.referenceDataSuccess(res.data[0]));
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

function* addRole({ payload }) {
  try {
    if (payload) {
      const res = yield call(axios.request, {
        method: "POST",
        url: `/jobRole`,
        data: payload,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token1"),
        },
      });
      yield put(role.addRoleSuccess(res));
      //yield put(role.roleList("all"))
    }
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

function* roleStatus({ payload }) {
  try {
    if (payload) {
      const res = yield call(axios.request, {
        method: "PATCH",
        url: `/jobRole/${payload.id}`,
        data: payload.data,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token1"),
        },
      });
      yield put(role.roleStatusSuccess(res));
      //yield put(role.roleList("all"))
      yield put(role.roleDetails(payload.id));
    }
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

function* editRole({ payload }) {
  try {
    if (payload) {
      const res = yield call(axios.request, {
        method: "PATCH",
        url: `/jobRole/${payload.id}`,
        data: payload.data,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token1"),
        },
      });
      yield put(role.editRoleSuccess(res));
      // yield put(role.roleDetails(payload.id))
    }
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

function* deleteRole({ payload }) {
  try {
    if (payload) {
      const res = yield call(axios.request, {
        method: "DELETE",
        url: `/jobRole/${payload}`,
        // data:payload.data,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token1"),
        },
      });
      yield put(role.deleteRoleSuccess(res));
      yield put(role.roleList("all"));
    }
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

function* inviteList({ payload }) {
  try {
    const res = yield call(axios.request, {
      // method: "POST",
      // url: `/jobRoleInvite`,
      method: "GET",
      url: `/jobRoleInvite/new/list`,
      data: { limit: 10 },
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    yield put(role.inviteListSuccess(res.data));
    yield put(role.inviteAllTotal(res.data.length));
    // let userDetails = JSON.parse(localStorage.getItem("user_details"));
    // const mycount = res.data.filter(i => i.uid === userDetails.userId);
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

// function* inviteFilter({payload}){
//   try{
//     const res = yield call(axios.request, {
//       method: "POST",
//       url: `/jobRoleInvite/filters/`,
//       headers: {
//         "Content-Type": "application/json",
//       Authorization: localStorage.getItem("token1"),
//       }
//     });
//     yield put(role.inviteFilterSuccess(res.data));
//     }
//   catch(err){
//     yield put(auth.apiError(err));
//   }
// }

function* addInvite({ payload }) {
  // let userDetails = JSON.parse(localStorage.getItem("user_details"))
  try {
    if (payload) {
      const res = yield call(axios.request, {
        method: "POST",
        url: `/jobRoleInvite`,
        data: payload,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token1"),
        },
      });
      yield put(role.addInviteSuccess(res));
      //yield put(role.candidateinviteList(PayloadSkipLimit))
    }
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

function* deleteInvite({ payload }) {
  // let userDetails = JSON.parse(localStorage.getItem("user_details"))
  try {
    if (payload) {
      const res = yield call(axios.request, {
        method: "DELETE",
        url: `/jobRoleInvite/${payload}`,
        // data:payload.data,
        //  params: { email: payload },
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token1"),
        },
      });
      yield put(role.deleteInviteSuccess(res));
      yield put(role.candidateinviteList(PayloadSkipLimit));
    }
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

function* editInvite({ payload }) {
  // let userDetails = JSON.parse(localStorage.getItem("user_details"))
  try {
    if (payload) {
      const res = yield call(axios.request, {
        method: "PATCH",
        url: `/jobRoleInvite/${payload.id}`,
        data: payload.data,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token1"),
        },
      });
      yield put(role.editInviteSuccess(res));
      yield put(role.candidateinviteList(PayloadSkipLimit));
    }
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

function* sendInviteReminder({ payload }) {
  // let userDetails = JSON.parse(localStorage.getItem("user_details"))
  try {
    if (payload) {
      const res = yield call(axios.request, {
        method: "POST",
        url: `/jobRoleInvite/reminder/${payload.queryInviteId}`,
        data: payload,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token1"),
        },
      });
      yield put(role.sendInviteReminderSuccess(res));
      yield put(role.inviteDetails(payload.queryInviteId));
    }
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

function* extendExpirationDate({ payload }) {
  // let userDetails = JSON.parse(localStorage.getItem("user_details"))
  try {
    if (payload) {
      const res = yield call(axios.request, {
        method: "PATCH",
        url: `/jobRoleInvite/extendExpirationDate/${payload.id}`,
        data: payload.data,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token1"),
        },
      });
      yield put(role.extendExpirationDateSuccess(res));
      // yield put(role.inviteDetails(payload.id))
    }
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

function* candidateinviteList({ payload }) {
  try {
    if (payload) {
      // const res = yield call(axios.request, {
      //   method: "POST",
      //   url: `/jobRoleInvite/list/`,
      //   data:payload,
      //   headers: {
      //     "Content-Type": "application/json",
      //   Authorization: localStorage.getItem("token1"),
      //   }
      // });

      let url = "";

      if (payload.candidateId) {
        url = `/jobRoleInvite/new/list/candidateInvites/${payload.candidateId}`;
      }

      if (payload.jobRoleId) {
        url = `/jobRoleInvite/new/list/invitedJobRole/${payload.jobRoleId}`;
      }

      const res = yield call(axios.request, {
        method: "GET",
        url: url,
        data: payload,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token1"),
        },
      });
      yield put(role.candidateinviteListSuccess(res.data));
      // yield put(role.inviteTotalCount(res.totalCount));
      // yield put(role.inviteMyTotal(res.myCount));
      // yield put(role.inviteAllTotal(res.allCount));
    }
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

function* candidateinviteDetails({ payload }) {
  try {
    if (payload) {
      const res = yield call(axios.request, {
        method: "GET",
        url: `/invite/${payload}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token1"),
        },
      });
      yield put(role.candidateinviteDetailsSuccess(res.data));
    }
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

function* inviteDetails({ payload }) {
  try {
    if (payload) {
      const res = yield call(axios.request, {
        method: "GET",
        url: `/jobRoleInvite/${payload}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token1"),
        },
      });
      if (res.status !== 0) {
        yield put(role.inviteDetailsFailure(res.data));
      } else {
        yield put(role.inviteDetailsSuccess(res.data));
      }
    }
  } catch (err) {
    yield put(role.inviteDetailsFailure(err));
    yield put(auth.apiError(err));
  }
}

function* candidateDashboardDetails({ payload }) {
  try {
    if (payload) {
      const res = yield call(axios.request, {
        method: "GET",
        url: `/jobRoleInvite/candidateDashboard/${payload}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token1"),
        },
      });
      yield put(role.candidateDashboardDetailsSuccess(res.data));
    }
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

function* generateProfile({ payload }) {
  try {
    if (payload) {
      const res = yield call(axios.request, {
        method: "POST",
        url: `/jobrole/generateProfile/`,
        data: payload,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token1"),
        },
      });
      yield put(role.generateProfileSuccess(res.data));
      //yield put(role.inviteList())
    }
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

function* inviteCandidateDetails({ payload }) {
  try {
    if (payload) {
      const res = yield call(axios.request, {
        method: "GET",
        url: `/jobRoleInvite/getCandidateInviteDetails/${payload}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token1"),
        },
      });
      yield put(role.inviteCandidateDetailsSuccess(res.data));
      //yield put(role.inviteList())
    }
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

function* roleGenerateSkill({ payload }) {
  try {
    if (payload) {
      const res = yield call(axios.request, {
        method: "POST",
        url: `/jobRole/generateSkills`,
        data: payload,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token1"),
        },
      });
      yield put(role.roleGenerateSkillSuccess(res.data));
      //yield put(role.inviteList())
    }
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

function* deactivateActiveStatus({ payload }) {
  try {
    if (payload) {
      const res = yield call(axios.request, {
        method: "PATCH",
        url: `/jobRoleInvite/${payload.id}`,
        data: payload.data,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token1"),
        },
      });
      yield put(role.deactivateActiveStatusSuccess(res));
      yield put(role.inviteDetails(payload.id));
    }
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

function* roleMatching({ payload }) {
  try {
    if (payload) {
      const res = yield call(axios.request, {
        method: "POST",
        url: `/jobRole/matching/`,
        data: payload,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token1"),
        },
      });
      yield put(role.roleMatchingSuccess(res.data));
    }
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

function* restTestInvite({ payload }) {
  try {
    if (payload) {
      const res = yield call(axios.request, {
        method: "POST",
        url: `/jobRoleInvite/resetTestSection/`,
        data: payload,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token1"),
        },
      });
      yield put(role.restTestInviteSuccess(res));
      yield put(role.inviteDetails(payload.inviteId));
      yield put(auth.getAoSI(payload.inviteId));
    }
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

function* downloadCR({ payload }) {
  try {
    if (payload) {
      const res = yield call(axios.request, {
        method: "POST",
        url: `/session/downloadFullReport`,
        data: payload,
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token1"),
        },
      });
      yield put(role.downloadCRSuccess(res));
    }
  } catch (err) {
    yield put(auth.apiError(err));
  }
}

// function* updateInviteReportParams({ payload }) {
//   try {
//     if (payload) {
//       const res = yield call(axios.request, {
//         // FK cleanup july 2023
//         method: "POST",
//         url: `/jobRoleInvite/updateReportParams`,
//         data: payload,
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: localStorage.getItem("token1"),
//         },
//       });
//       // yield put(role.restTestInviteSuccess(res))
//     }
//   } catch (err) {
//     yield put(auth.apiError(err));
//   }
// }

function* createConfig({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: `/jobRoleInvite/report/createConfig`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(role.createConfigFailure(res));
    } else {
      yield put(role.createConfigSuccess(res));
    }
  } catch (err) {
    yield put(role.createConfigFailure(err));
    yield put(auth.apiError(err));
  }
}

function* updateConfig({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: `/jobRoleInvite/report/updateConfig/${payload.token}`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(role.updateConfigFailure(res));
    } else {
      yield put(role.updateConfigSuccess(res));
    }
  } catch (err) {
    yield put(role.updateConfigFailure(err));
    yield put(auth.apiError(err));
  }
}

function* config({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/jobRoleInvite/report/list/config/${payload}`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(role.configFailure(res));
    } else {
      yield put(role.configSuccess(res));
    }
  } catch (err) {
    yield put(role.configFailure(err));
    yield put(auth.apiError(err));
  }
}

function* jobRoleData({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/jobRole/data/roleData`,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(role.jobRoleDataFailure(res));
    } else {
      yield put(role.jobRoleDataSuccess(res.data));
    }
  } catch (err) {
    yield put(role.jobRoleDataFailure(err));
    yield put(auth.apiError(err));
  }
}

function* getRoleFeatures({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: `/jobRole/getRoleFeature`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(role.getRoleFeaturesFailure(res));
    } else {
      yield put(role.getRoleFeaturesSuccess(res.data));
    }
  } catch (err) {
    yield put(role.getRoleFeaturesFailure(err));
    yield put(auth.apiError(err));
  }
}

function* getRoleSkills({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: `/jobRole/getRoleSkills`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(role.getRoleSkillsFailure(res));
    } else {
      yield put(role.getRoleSkillsSuccess(res.data));
    }
  } catch (err) {
    yield put(role.getRoleSkillsFailure(err));
    yield put(auth.apiError(err));
  }
}

function* getBehavioralSkills({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: `/jobRole/getBehavioralSkills`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(role.getBehavioralSkillsFailure(res));
    } else {
      yield put(role.getBehavioralSkillsSuccess(res.data));
    }
  } catch (err) {
    yield put(role.getBehavioralSkillsFailure(err));
    yield put(auth.apiError(err));
  }
}

function* getCandidateJobRoleProfileToken({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/jobRole/getCandidateJobRoleProfileToken/${payload.candidateId}/${payload.jobRoleId}`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(role.getCandidateJobRoleProfileTokenFailure(res));
    } else {
      yield put(role.getCandidateJobRoleProfileTokenSuccess(res));
    }
  } catch (err) {
    yield put(role.getCandidateJobRoleProfileTokenFailure(err));
    yield put(auth.apiError(err));
  }
}

function* editApplyLink({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/applyLinks/editDetails/${payload.id}/${payload.linkId}`,
      // url: `/applyLinks/editApplyLinkDetails/30021/384857d9`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(role.editApplyLinkFailure(res));
    } else {
      yield put(role.editApplyLinkSuccess(res.data));
    }
  } catch (err) {
    yield put(role.editApplyLinkFailure(err));
    yield put(auth.apiError(err));
  }
}

function* createApplyLink({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: `/applyLinks/create`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(role.createApplyLinkFailure(res));
    } else {
      yield put(role.createApplyLinkSuccess(res.data));
    }
  } catch (err) {
    yield put(role.createApplyLinkFailure(err));
    yield put(auth.apiError(err));
  }
}

function* listApplyLinks({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/applyLinks/list/${payload}`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(role.listApplyLinksFailure(res));
    } else {
      yield put(role.listApplyLinksSuccess(res.data));
    }
  } catch (err) {
    yield put(role.listApplyLinksFailure(err));
    yield put(auth.apiError(err));
  }
}

function* uploadApplyLinkBanner({ payload }) {
  console.log(payload, "auth.apiError(err)");
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: `/applyLinks/uploadBanner/${payload.id}/${payload.linkId}`,
      data: payload.formData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(role.uploadApplyLinkBannerFailure(res));
    } else {
      yield put(role.uploadApplyLinkBannerSuccess(res.data));
    }
  } catch (err) {
    yield put(role.uploadApplyLinkBannerFailure(err));
    yield put(auth.apiError(err));
  }
}

function* saveApplyLinksDetails({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: `/applyLinks/saveDetails/${payload.id}/${payload.linkId}`,
      data: payload.data,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(role.saveApplyLinksDetailsFailure(res));
    } else {
      yield put(role.saveApplyLinksDetailsSuccess(res));
    }
  } catch (err) {
    yield put(role.saveApplyLinksDetailsFailure(err));
    yield put(auth.apiError(err));
  }
}

// we can remove publishApplyLink Jan 27 2025 as we have saveSettingsAndPublish
function* publishApplyLink({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/applyLinks/publish/${payload.id}/${payload.linkId}`,
      data: payload,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(role.publishApplyLinkFailure(res));
    } else {
      yield put(role.publishApplyLinkSuccess(res));
    }
  } catch (err) {
    yield put(role.publishApplyLinkFailure(err));
    yield put(auth.apiError(err));
  }
}

// we can remove save settings Jan 27 2025 as we have saveSettingsAndPublish
function* saveSettings({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: `/applyLinks/saveSettings/${payload.id}/${payload.linkId}`,
      data: payload.data,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(role.saveSettingsFailure(res));
    } else {
      yield put(role.saveSettingsSuccess(res));
    }
  } catch (err) {
    yield put(role.saveSettingsFailure(err));
    yield put(auth.apiError(err));
  }
}

function* appliedCandidatesList({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/applyLinks/appliedCandidatesList/${payload.linkId}`,
      // url: `/applyLinks/editApplyLinkDetails/30021/384857d9`,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(role.appliedCandidatesListFailure(res));
    } else {
      yield put(role.appliedCandidatesListSuccess(res.data));
    }
  } catch (err) {
    yield put(role.appliedCandidatesListFailure(err));
    yield put(auth.apiError(err));
  }
}

function* shortlistingInfo({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/applyLinks/getShortlistingInfo/${payload.linkId}`,
      // url: `/applyLinks/editApplyLinkDetails/30021/384857d9`,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(role.shortlistingInfoFailure(res));
    } else {
      yield put(role.shortlistingInfoSuccess(res.data));
    }
  } catch (err) {
    yield put(role.shortlistingInfoFailure(err));
    yield put(auth.apiError(err));
  }
}

function* approveShortlisting({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/applyLinks/approveShortlisting/${payload.linkId}`,
      // url: `/applyLinks/editApplyLinkDetails/30021/384857d9`,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(role.approveShortlistingFailure(res));
    } else {
      yield put(role.approveShortlistingSuccess(res.data));
    }
  } catch (err) {
    yield put(role.approveShortlistingFailure(err));
    yield put(auth.apiError(err));
  }
}

function* manualShortlisting({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: `/applyLinks/shortlistApplications/${payload.linkId}`,
      // url: `/applyLinks/editApplyLinkDetails/30021/384857d9`,
      data: payload.data,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(role.manualShortlistingFailure(res));
    } else {
      yield put(role.manualShortlistingSuccess(res.data));
    }
  } catch (err) {
    yield put(role.manualShortlistingFailure(err));
    yield put(auth.apiError(err));
  }
}

function* updateApplicationStatus({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: `/applyLinks/updateApplicationStatus/${payload.linkId}`,
      data: payload.data,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(role.updateApplicationStatusFailure(res));
    } else {
      yield put(role.updateApplicationStatusSuccess(res.data));
    }
  } catch (err) {
    yield put(role.updateApplicationStatusFailure(err));
    yield put(auth.apiError(err));
  }
}

function* downloadApplicationData({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/applyLinks/downloadApplicationsData/${payload.linkId}`,
      data: payload.data,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(role.downloadApplicationDataFailure(res));
    } else {
      yield put(role.downloadApplicationDataSuccess(res.data));
    }
  } catch (err) {
    yield put(role.downloadApplicationDataFailure(err));
    yield put(auth.apiError(err));
  }
}

function* getSettings({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/applyLinks//getSettings/${payload.linkId}`,
      data: payload.data,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(role.getSettingsFailure(res));
    } else {
      yield put(role.getSettingsSuccess(res.data));
    }
  } catch (err) {
    yield put(role.getSettingsFailure(err));
    yield put(auth.apiError(err));
  }
}

function* saveSettingsAndPublish({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: `/applyLinks/saveSettingsAndPublish/${payload.linkId}`,
      data: payload.data,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(role.saveSettingsAndPublishFailure(res));
    } else {
      yield put(role.saveSettingsAndPublishSuccess(res.data));
    }
  } catch (err) {
    yield put(role.saveSettingsAndPublishFailure(err));
    yield put(auth.apiError(err));
  }
}

function* updatePurpose({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: `/applyLinks/updatePurpose/${payload.linkId}`,
      data: payload.data,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(role.updatePurposeFailure(res));
    } else {
      yield put(role.updatePurposeSuccess({ linkId: payload.linkId, purpose: payload.data.purpose }));
    }
  } catch (err) {
    yield put(role.updatePurposeFailure(err));
    yield put(auth.apiError(err));
  }
}

function* getTestingRequirements({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/jobRole/getTestingRequirements/${payload.id}`,
      data: payload.data,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(role.getTestingRequirementsFailure(res));
    } else {
      yield put(role.getTestingRequirementsSuccess(res.data));
    }
  } catch (err) {
    yield put(role.getTestingRequirementsFailure(err));
    yield put(auth.apiError(err));
  }
}

function* testsList({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "GET",
      url: `/jobRole/getTestsList/${payload.type}/${payload.roleId}`,
      data: payload.data,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(role.testsListFailure(res));
    } else {
      yield put(role.testsListSuccess(res.data));
    }
  } catch (err) {
    yield put(role.testsListFailure(err));
    yield put(auth.apiError(err));
  }
}

function* updateCustomTest({ payload }) {
  try {
    const res = yield call(axios.request, {
      method: "POST",
      url: `/jobRole/updateCustomTests/${payload.roleID}`,
      data: payload.data,
      headers: {
        "Content-Type": "application/json",
        Authorization: localStorage.getItem("token1"),
      },
    });
    if (res.status !== 0) {
      yield put(role.updateCustomTestFailure(res));
    } else {
      yield put(role.updateCustomTestSuccess(res));
    }
  } catch (err) {
    yield put(role.updateCustomTestFailure(err));
    yield put(auth.apiError(err));
  }
}

function* roleSaga() {
  yield takeLatest(roleTypes.ROLE_LIST, roleList);
  yield takeLatest(roleTypes.REFERENCE_DATA, referenceData);
  yield takeLatest(roleTypes.ADD_ROLE, addRole);
  yield takeLatest(roleTypes.UPDATE_ROLE, editRole);
  yield takeLatest(roleTypes.DELETE_ROLE, deleteRole);
  yield takeLatest(roleTypes.INVITE_LIST, inviteList);
  yield takeLatest(roleTypes.ADD_INVITE, addInvite);
  yield takeLatest(roleTypes.UPDATE_INVITE, editInvite);
  yield takeLatest(roleTypes.DELETE_INVITE, deleteInvite);
  yield takeLatest(roleTypes.SEND_INVTE_REMINDER, sendInviteReminder);
  yield takeLatest(roleTypes.EXTEND_EXPIRATION_DATA, extendExpirationDate);
  yield takeLatest(roleTypes.ROLE_DETAILS, roleDetails);
  yield takeLatest(roleTypes.CANDIDATE_INVITE, candidateinviteList);
  yield takeLatest(roleTypes.GENERATE_PROFILE, generateProfile);
  yield takeLatest(roleTypes.INVITE_CANDIDATE_DETAILS, inviteCandidateDetails);
  yield takeLatest(roleTypes.DETAIL_INVITE_CANDIDATE, candidateinviteDetails);
  // yield takeLatest(roleTypes.FILTER_ROLE,roleFilter)
  yield takeLatest(roleTypes.ROLE_STATUS, roleStatus);
  // yield takeLatest(roleTypes.INVITE_FILTER,inviteFilter)
  yield takeLatest(roleTypes.INVITE_DETAILS, inviteDetails);
  yield takeLatest(roleTypes.CANDIDATE_DASHBOARD_DETAILS, candidateDashboardDetails);
  yield takeLatest(roleTypes.ROLE_GENERATE_SKILL, roleGenerateSkill);
  yield takeLatest(roleTypes.INVITE_STATUS, deactivateActiveStatus);
  yield takeLatest(roleTypes.ROLESKILL_MATCHING, roleMatching);
  yield takeLatest(roleTypes.RESET_TEST, restTestInvite);
  yield takeLatest(roleTypes.DOWNLOAD_CR, downloadCR);
  // yield takeLatest(roleTypes.UPDATE_INVITE_PARAMS, updateInviteReportParams);
  yield takeLatest(roleTypes.CREATE_CONFIG, createConfig);
  yield takeLatest(roleTypes.UPDATE_CONFIG, updateConfig);
  yield takeLatest(roleTypes.CONFIG, config);
  yield takeLatest(roleTypes.JOB_ROLE_DATA, jobRoleData);
  yield takeLatest(roleTypes.GET_ROLE_FEATURES, getRoleFeatures);
  yield takeLatest(roleTypes.GET_ROLE_SKILLS, getRoleSkills);
  yield takeLatest(roleTypes.GET_BEHAVIORAL_SKILLS, getBehavioralSkills);
  yield takeLatest(roleTypes.GET_CANDIDATE_JOB_ROLE_PROFILE_TOKEN, getCandidateJobRoleProfileToken);
  yield takeLatest(roleTypes.EDIT_APPLY_LINK, editApplyLink);
  yield takeLatest(roleTypes.CREATE_APPLY_LINK, createApplyLink);
  yield takeLatest(roleTypes.LIST_APPLY_LINKS, listApplyLinks);
  yield takeLatest(roleTypes.UPLOAD_APPLY_LINK_BANNER, uploadApplyLinkBanner);
  yield takeLatest(roleTypes.SAVE_APPLY_LINKS_DETAILS, saveApplyLinksDetails);
  yield takeLatest(roleTypes.PUBLISH_APPLY_LINK, publishApplyLink);
  yield takeLatest(roleTypes.SAVE_SETTINGS, saveSettings);
  yield takeLatest(roleTypes.APPLIED_CANDIDATES_LIST, appliedCandidatesList);
  yield takeLatest(roleTypes.SHORTLISTING_INFO, shortlistingInfo);
  yield takeLatest(roleTypes.APPROVE_SHORTLISTING, approveShortlisting);
  yield takeLatest(roleTypes.MANUAL_SHORTLISTING, manualShortlisting);
  yield takeLatest(roleTypes.UPDATE_APPLICATION_STATUS, updateApplicationStatus);
  yield takeLatest(roleTypes.DOWNLOAD_APPLICATION_DATA, downloadApplicationData);
  yield takeLatest(roleTypes.GET_SETTINGS, getSettings);
  yield takeLatest(roleTypes.SAVE_SETTINGS_AND_PUBLISH, saveSettingsAndPublish);
  yield takeLatest(roleTypes.UPDATE_PURPOSE, updatePurpose);
  yield takeLatest(roleTypes.GET_TESTING_REQUIREMENTS, getTestingRequirements);
  yield takeLatest(roleTypes.TESTS_LIST, testsList);
  yield takeLatest(roleTypes.UPDATE_CUSTOM_TEST, updateCustomTest);
}
export { roleSaga };
