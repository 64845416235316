import { Box, Button, Grid, TextField, Typography, Checkbox, FormControlLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import ScatterPlot from "./ScatterPlot";
import { makeStyles } from "@mui/styles";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
// import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
// import chart from "../../../../redux/actions/chart";
import chart from "../../redux/actions/chart";
import { useParams } from "react-router-dom";
// import { config } from "../../../config";
import ReusableDialog from "../controls/ReusableDialog";
import Skeleton from "@mui/material/Skeleton";

const useStyles = makeStyles({
  textContent: {
    padding: "20px",
    paddingTop: "14px",
    textAlign: "justify",
  },

  hideToolbar: {
    "& .MuiDialogActions-root": {
      display: "none !important",
      visibility: "hidden !important",
      // width:0
    },
    "& .MuiSvgIcon-root": {
      visibility: "hidden !important",
      display: "none !important",
      // width:0
    },
  },
  roletype1: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: " #787878",
    // textAlign:'center',
    paddingTop: "10px",
  },
  roletype: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "18px !important",
    lineHeight: "24px !important",
    color: " #787878",
    // textAlign:'center',
    paddingTop: "10px",
    display: "flex",
    flexWrap: "nowrap",
  },
});

const CandidateComparison = ({ skill, skillWeightage, sectionToTest, chartData, datefilter = true }) => {
  const chartStore = useSelector((state) => state.chart);
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.auth);
  const chartLoding = chartStore?.isauthLoading;
  const storeData = chartStore?.roleChartData;
  const { id } = useParams();
  const [filter, setFilter] = useState("all");
  const [quadrantFilter, setQuadrantFilter] = useState("all");
  const [firstFilter, setFirstFilter] = useState("all");
  const [lastSelectedFirstFilter, setLastSelectedFirstFilter] = useState("all");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(new Date());
  const [isCustomDateModalOpen, setCustomDateModalOpen] = useState(false);
  const [isCustomDifferentiatorModalOpen, setCustomDifferentiatorModalOpen] = useState(false);
  const [customDate, setCustomDate] = useState("Custom Date Range...");
  const [customDifferentiatorData, setCustomDifferentiatorData] = useState([]);
  const [customDifferentiatorDataApply, setCustomDifferentiatorDataApply] = useState([]);
  const [customDateFilter, setCustomDateFilter] = useState("");
  const [differentiator, setDifferentiator] = useState(false);
  const [differentiatorFilter, setDifferentiatorFilter] = useState("None");
  const [differentiatorLastFilter, setDifferentiatorLastFilter] = useState("None");
  const [customScoreFlag, setCustomScoreFlag] = useState(false);
  const [customDateFlag, setCustomDateFlag] = useState(false);
  const [plotData, setPlotData] = useState(null);
  const classes = useStyles();

  // handle differentiator filter change
  const handleDifferentiatorFilterChange = (event) => {
    setDifferentiatorLastFilter(differentiatorFilter);
    const selectedValue = event.target.value;

    if (selectedValue === "customScore") {
      if (!customScoreFlag) {
        setCustomDifferentiatorModalOpen(false);
        setCustomDifferentiatorModalOpen(true);
      } else {
        handleApply();
      }
    }
    if (selectedValue === "None") {
      setDifferentiatorFilter(selectedValue);
      setDifferentiator(false);
    }
    setDifferentiatorFilter(selectedValue);
    setDifferentiator(true);
  };

  useEffect(() => {
    if (datefilter) {
      let payload = {
        roleId: id,
        differentiatorFilter,
      };
      dispatch(chart.RoleChartData(payload));
    }
  }, [id, datefilter]);

  useEffect(() => {
    if (chartStore?.roleChartData?.length && datefilter) {
      setPlotData(storeData);
    }
  }, [chartStore?.roleChartData]);

  useEffect(() => {
    if (chartData && !datefilter) {
      setPlotData(chartData);
    }
  }, [chartData]);

  const handleDateModalCancel = () => {
    setFirstFilter(lastSelectedFirstFilter);
    setCustomDateModalOpen(false);
  };

  const firstFilterHandleChange = (event) => {
    setLastSelectedFirstFilter(firstFilter);
    const selectedValue = event.target.value;

    if (selectedValue === "custom date") {
      if (!customDateFlag) {
        // setCustomDateModalOpen(false);
        setCustomDateModalOpen(true);
        setFirstFilter(selectedValue);
        // setCustomDateFlag(true);
      } else {
        // handleClearAll();
        handleCustumDateChange();
        setFirstFilter(selectedValue);
        // setCustomDateFilter(selectedValue);
      }
    }

    setFirstFilter(selectedValue);
  };

  const handleStartDateChange = (date) => {
    date.setHours(0, 0, 1, 0);
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    date.setHours(23, 59, 59, 999);

    setEndDate(date);
  };

  const handleChange = (event) => {
    setFilter(event.target.value);
  };

  const handleQuadrantChange = (event) => {
    setQuadrantFilter(event.target.value);
  };

  const handleCustumDateChange = (event) => {
    if (firstFilter === "custom date" && !customDateFlag) setCustomDateFlag(true);

    setCustomDateModalOpen(false);
    setCustomDate(startDate.toDateString() + " to " + endDate.toDateString());
    setCustomDateFilter("custom date Filter");
    setFirstFilter("custom date");
  };

  const handleClearAll = () => {
    setCustomDateModalOpen(false);
  };
  const handleEditAll = () => {
    setCustomDateModalOpen(true);
  };

  // const handleDifferentiatorChange = (event) => {
  //   const selectedValue = event.target.value;
  //   setDifferentiator(selectedValue);
  // };

  // make custom differentiator data array of objects
  useEffect(() => {
    if ((sectionToTest, skill, authState)) {
      const skillArr = [];

      if (sectionToTest?.includes("Coding")) {
        skillArr.push({ value: 0, name: "codingScore", label: "Coding", edited: false });
      }
      if (sectionToTest?.includes("Presentation")) {
        skillArr.push({ value: 0, name: "baiScore", label: "Behavioral", edited: false });
      }
      if (sectionToTest?.includes("Profile")) {
        skillArr.push({ value: 0, name: "socialMediaScore", label: "Social Media", edited: false });
        skillArr.push({ value: 0, name: "resumeSkillScore", label: "Skills from Resume", edited: false });
      }

      for (let index = 0; index < skill.length; index++) {
        const item = skill[index];
        const weightage = skillWeightage[index];

        skillArr.push({
          value: 0,
          name: item,
          label: authState?.skillsConfig[item]?.fullName,
          weightage,
          edited: false,
        });
      }
      setCustomDifferentiatorData(skillArr);
    }
  }, [sectionToTest, skill, authState]);

  // auto distribute the remaining values useEffect
  useEffect(() => {
    const editedSum = customDifferentiatorData?.reduce((sum, item) => {
      return item.edited && item.checked ? sum + item.value : sum;
    }, 0);

    const uncheckedCount = customDifferentiatorData?.reduce((count, item) => {
      return !item.edited && item.checked ? count + 1 : count;
    }, 0);

    const distributedValues = distribute(editedSum, uncheckedCount);

    setCustomDifferentiatorData((prevData) => {
      const newData = [...prevData];
      let distributedIndex = 0;
      for (let i = 0; i < newData.length; i++) {
        if (!newData[i].edited && newData[i].checked) {
          newData[i].value = distributedValues[distributedIndex];
          distributedIndex++;
        }
      }
      return newData;
    });
  }, [JSON.stringify(customDifferentiatorData)]);

  // auto distribute the remaining values
  function distribute(n, parts) {
    let targetSum = 100 - n;
    let partSize = Math.floor(targetSum / parts);
    let remaining = targetSum % parts;
    let result = [];
    for (let i = 0; i < parts; i++) {
      result.push(partSize + (i < remaining ? 1 : 0));
    }
    return result;
  }

  // number change hander in textFields
  const handleNumberChangeTextFeild = (index, event) => {
    let newValue = parseInt(event.target.value);

    if (newValue < 0) {
      newValue = 0;
    }

    setCustomDifferentiatorData((prevData) => {
      const newData = [...prevData];
      newData[index] = { ...newData[index], value: newValue, edited: true };
      return newData;
    });
  };

  // checkbox change handler
  const handleCheckboxChange = (index) => {
    const newSkills = [...customDifferentiatorData];
    newSkills[index].checked = !newSkills[index].checked;
    if (!newSkills[index].checked) {
      newSkills[index].value = 0;
      newSkills[index].edited = false;
    }
    setCustomDifferentiatorData(newSkills);
  };

  // modal close button handler
  const handleClose = () => {
    setCustomDifferentiatorModalOpen(false);
    setDifferentiatorFilter(differentiatorLastFilter);
  };

  // modal apply button handler
  const handleApply = () => {
    if (differentiatorFilter === "customScore" && !customScoreFlag) setCustomScoreFlag(true);
    setCustomDifferentiatorModalOpen(false);
    setDifferentiatorFilter("customScore");
    setCustomDifferentiatorDataApply(customDifferentiatorData);
  };

  // calculate total of checked values
  const calculateTotal = () => {
    return customDifferentiatorData?.reduce((total, skill) => {
      return skill.checked ? total + skill.value : total;
    }, 0);
  };

  // check if total is 100
  const total = calculateTotal();
  const isTotal100 = total === 100;

  // custom skill model open
  const handleEditCustomDifferentiator = () => {
    setCustomDifferentiatorModalOpen(true);
  };
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={8}>
        <div
          style={{
            backgroundColor: "white",
            paddingBottom: "100%",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            {!chartLoding ? (
              <ScatterPlot
                chartRoleId={id}
                filter={filter}
                firstFilter={firstFilter}
                xDate={startDate}
                yDate={endDate}
                customDateFilter={customDateFilter}
                differentiator={differentiator}
                differentiatorFilter={differentiatorFilter}
                quadrantFilter={quadrantFilter}
                customDifferentiatorData={customDifferentiatorDataApply}
                chartData={plotData}
              />
            ) : datefilter ? (
              <Skeleton
                sx={{ bgcolor: "grey.300", marginTop: "40px" }}
                variant="rectangular"
                width="95%"
                height="750px"
              />
            ) : (
              <Skeleton sx={{ bgcolor: "grey.300" }} variant="rectangular" width="95%" height="700px" />
            )}
          </div>
        </div>
      </Grid>

      <Grid
        item
        xs={12}
        md={4}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          marginTop: "7px",
          paddingBottom: datefilter === false && "20px",
          paddingTop: datefilter === false && "30px",
        }}
      >
        <Grid container spacing={2}>
          {datefilter && (
            <Box sx={{ width: "100%", padding: "25px 20px", paddingTop: "49px" }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Filter by Duration</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={firstFilter}
                  label="Filter by Duration"
                  onChange={firstFilterHandleChange}
                  // onBlur={firstFilterHandleChange}
                >
                  <MenuItem value={"all"}>All Dates</MenuItem>
                  <MenuItem value={"Last 7 Days"}>Last 7 Days</MenuItem>
                  <MenuItem value={"Last 30 Days"}>Last 30 Days</MenuItem>
                  <MenuItem value={"Last 60 Days"}>Last 60 Days</MenuItem>
                  <MenuItem value={"Last 90 Days"}>Last 90 Days</MenuItem>
                  <MenuItem value={"custom date"}>{customDate}</MenuItem>
                  {/* {firstFilter === "custom date" && ( */}
                  {customDateFlag && (
                    <MenuItem value={""}>
                      <Button
                        variant="outlined"
                        style={{ fontSize: "12px", padding: "5px 10px" }}
                        onClick={(e) => {
                          // e.stopPropagation(); // Stop event propagation
                          handleEditAll();
                        }}
                      >
                        Edit Custom Date Range
                      </Button>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Box>
          )}

          <ReusableDialog
            open={isCustomDateModalOpen}
            onClose={handleClearAll}
            title="Custom Date Range"
            content={
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  {/* <Typography>Select Start Date</Typography> */}
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      components={{
                        OpenPickerIcon: AccessTimeIcon,
                      }}
                      renderInput={(props) => (
                        <div>
                          <Typography sx={{ marginBottom: "10px" }}>Select Start Date</Typography>
                          <TextField
                            {...props}
                            //  className={classes.datepickersty}
                            placeholder="Select Start Date"
                          />
                        </div>
                      )}
                      value={startDate}
                      onChange={handleStartDateChange}
                      // minDate={calenderMinDate}
                      // open={true}
                      maxDate={endDate}
                    />
                  </LocalizationProvider>
                  <div
                    style={{
                      marginLeft: "20px" /* Adjust the margin as needed */,
                    }}
                  ></div>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      components={{
                        OpenPickerIcon: AccessTimeIcon,
                      }}
                      renderInput={(props) => (
                        <div>
                          <Typography sx={{ marginBottom: "10px" }}>Select End Date</Typography>

                          <TextField
                            {...props}
                            //  className={classes.datepickersty}
                            placeholder="Select End Date"
                          />
                        </div>
                      )}
                      views={["year", "month", "day"]}
                      onChange={handleEndDateChange}
                      minDate={startDate} // Set the minimum date for the second date picker
                      maxDate={new Date()}
                      value={endDate}
                      showButtons={false}
                      toolbarTitle="Select End Date"
                    />
                  </LocalizationProvider>
                </div>
              </>
            }
            actions={[
              { onClick: handleDateModalCancel, text: "Cancel", color: "btnCls1", variant: "outlined" },
              {
                onClick: handleCustumDateChange,
                disabled: startDate === null,
                text: "Apply",
                color: startDate === null ? "disablebtn1" : "btn1",
              },
            ]}
          />
          <Box sx={{ width: "100%", padding: "20px", paddingBottom: "25px" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Filter by Score</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filter}
                label="Filter by Score"
                onChange={handleChange}
              >
                <MenuItem value={"all"}>All Candidates</MenuItem>
                <MenuItem value={10}>Top 10 Candidates</MenuItem>
                <MenuItem value={20}>Top 20 Candidates</MenuItem>
                <MenuItem value={30}>Top 30 Candidates</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ width: "100%", padding: "20px", paddingBottom: "25px" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Differentiator</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={differentiatorFilter}
                label="Differentiator"
                onChange={handleDifferentiatorFilterChange}
              >
                <MenuItem value={"None"}>No Differentiator</MenuItem>
                <MenuItem value={"inviteScore"}>Candidate Score</MenuItem>
                {sectionToTest?.includes("Coding") && <MenuItem value={"codingScore"}>Coding</MenuItem>}
                {sectionToTest?.includes("Presentation") && <MenuItem value={"baiScore"}>Behavioral</MenuItem>}
                {sectionToTest?.includes("Profile") && <MenuItem value={"socialMediaScore"}>Social Media</MenuItem>}
                {sectionToTest?.includes("Profile") && (
                  <MenuItem value={"resumeSkillScore"}>Skills from Resume</MenuItem>
                )}

                {skill?.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {authState?.skillsConfig[item]?.fullName}
                  </MenuItem>
                ))}

                <MenuItem value={"customScore"}>Custom Differentiator</MenuItem>

                {customScoreFlag && (
                  <MenuItem value={""}>
                    <Button
                      variant="outlined"
                      style={{ fontSize: "12px", padding: "5px 10px" }}
                      onClick={(e) => {
                        // e.stopPropagation(); // Stop event propagation
                        handleEditCustomDifferentiator();
                      }}
                    >
                      Edit Custom Differentiator
                    </Button>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </Box>
          <ReusableDialog
            open={isCustomDifferentiatorModalOpen}
            onClose={handleClose}
            title="Custom Differentiator"
            content={
              <>
                <Typography className={classes.roletype1} sx={{ marginBottom: "30px" }}>
                  Select a weight for each differentiator. Total weight should add up to 100%.
                </Typography>
                <Grid container>
                  {customDifferentiatorData?.map((item, index) => (
                    <Grid container alignItems="center" key={item.name}>
                      <Grid item xs={1}>
                        {" "}
                      </Grid>
                      <Grid item xs={7.5}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={item.checked}
                              onChange={() => handleCheckboxChange(index)}
                              // disabled={isTotal100 && !item.checked}
                            />
                          }
                          label={<span style={{ color: item.checked ? "black" : "gray" }}>{item.label}</span>}
                        />
                      </Grid>
                      <Grid item xs={2.5}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <TextField
                            type="number"
                            value={item.value}
                            onChange={(e) => handleNumberChangeTextFeild(index, e)}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            inputProps={{
                              style: { padding: "7px 14px" },
                              min: 0,
                              max: 100,
                            }}
                            disabled={!item.checked}
                            style={{ backgroundColor: !item.checked ? "#f0f0f0" : "white" }}
                          />
                          <span style={{ padding: "5px", fontWeight: "480" }}>%</span>
                        </div>
                      </Grid>
                      <Grid item xs={1}></Grid>
                    </Grid>
                  ))}

                  {/* ----------------------- */}
                  <Grid container alignItems="center">
                    <Grid item xs={1}>
                      {" "}
                    </Grid>
                    <Grid item xs={7.5}>
                      <Typography className={classes.textContent} sx={{ paddingLeft: "0px" }}>
                        <b>Total Weight</b>
                      </Typography>
                    </Grid>
                    <Grid item xs={2.5}>
                      <Box
                        style={{
                          // border: "1px solid rgb(102,102,102)",
                          padding: "12%",
                          // borderRadius: "5px",
                          width: "52%",
                        }}
                        // className={classes.textContent}
                      >
                        <span style={{ color: total == 100 ? "black" : "red" }}>
                          <b>{total}%</b>
                        </span>
                      </Box>
                    </Grid>
                    <Grid item xs={1}>
                      {" "}
                    </Grid>
                  </Grid>
                  {/* ----------------------- */}
                </Grid>
              </>
            }
            actions={[
              { onClick: handleClose, text: "Cancel", color: "btnCls1", variant: "outlined" },
              {
                onClick: handleApply,
                disabled: !isTotal100,
                text: "Apply",
                color: isTotal100 ? "btn1" : "disablebtn1",
              },
            ]}
          />
          <Box sx={{ width: "100%", padding: "20px", paddingBottom: "45px" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Quadrants</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={quadrantFilter}
                label="Quadrants"
                onChange={handleQuadrantChange}
              >
                <MenuItem value={"all"}>All</MenuItem>
                <MenuItem value={"Performers"}>Performers</MenuItem>
                <MenuItem value={"Experts"}>Experts</MenuItem>
                <MenuItem value={"Professionals"}>Professionals</MenuItem>
                <MenuItem value={"Learners"}>Learners</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>

        {!datefilter && plotData && (
          <Grid container spacing={2} style={{ paddingBottom: "0px" }}>
            <Typography sx={{ paddingTop: "8px", fontSize: "12px !important" }} className={classes.textContent}>
              <strong>{plotData?.length}</strong> of the selected applicants completed the test. Only candidates who
              have completed the test are compared.
            </Typography>
          </Grid>
        )}

        <Grid container spacing={2} style={{ paddingBottom: "10px" }}>
          <Typography
            sx={datefilter === false ? { paddingBottom: "0px", paddingTop: "8px", fontSize: "12px !important" } : {}}
            className={classes.textContent}
          >
            {" "}
            <b>Professional Drive</b> measures the candidate's work experience, work ethics, integrity,
            conscientiousness, and drive for self-development, all the necessary behavioral traits of a true{" "}
            <b>professional</b>.
          </Typography>
          <Typography
            sx={datefilter === false ? { paddingBottom: "0px", paddingTop: "8px", fontSize: "12px !important" } : {}}
            className={classes.textContent}
          >
            {" "}
            <b>Technical Acumen</b> measures the candidate's current skills relevant to this job role and preparedness
            to accomplish daily technical tasks. Real <b>experts</b> have high technical acumen.
          </Typography>

          <Typography
            sx={
              datefilter === false
                ? { paddingBottom: "0px !important", paddingTop: "8px", fontSize: "12px !important" }
                : {}
            }
            className={classes.textContent}
          >
            {" "}
            <b>Combined</b>, Professional Drive and Technical Acumen provide a true measure of a candidate's ability to
            perform in a job role.
            <b> High Performers</b> have both high expertise and the right professional attitude.
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CandidateComparison;
