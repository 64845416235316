import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
// import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { Divider, Typography, Grid, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { theme } from "../../../styles/theme";
import GraphScoreMeter from "../../../components/GraphScoreMeter/GraphScoreMeter";
import moment from "moment";
import { TimeZonePT, codingLanguageDisplay } from "../../../services/globalService";
import { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import role from "../../../redux/actions/role";

const useStyles = makeStyles({
  textValue: theme.typography.b1Regular,
  deactiveText: theme.typography.b1Medium,
  actionbtn: {
    paddingRight: "20px",
    paddingBottom: "6px",
    paddingTop: "6px",
  },
  root: {
    "& .MuiDialog-paper": {
      maxWidth: "1312px !important",
      maxHeight: "576px !important",
    },
  },
  field: {
    height: "48px !important",
    marginTop: "8px !important",
    borderRadius: "5px !important",
    border: "1px solid #787878 !important",
    backgroundColor: "transparent",

    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0px",
    },
    "& input::placeholder": {
      fontSize: "16px",
      color: "#787878 !important",
    },
  },
  roletype: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "500 !important",
    fontSize: "18px !important",
    lineHeight: "24px !important",
    color: " #787878",
    // textAlign:'center',
    paddingTop: "10px !important",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  roletype1: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: " #787878",
    // textAlign:'center',
    paddingTop: "10px",
  },
  dialogSty: {
    padding: "16px 24px !important",
  },
  sectionContainer: {
    display: "flex",
    alignItems: "center",
  },
  sectionDots: {
    fontSize: "10px",
    margin: "0px 5px",
  },
  sectionText: {
    fontFamily: "Roboto !important",
    fontStyle: "normal",
    fontWeight: "400 !important",
    fontSize: "16px !important",
    lineHeight: "24px !important",
    color: " #787878",
    paddingTop: "4px",
  },
});

const testMapping = {
  standard: "Standard Test",
  custom: "Custom Test",
};

const RoleViewModel = (props) => {
  // const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const matches = useMediaQuery("(max-width:850px)");
  const classes = useStyles();
  const dispatch = useDispatch();
  const roleState = useSelector((state) => state.role);
  const authState = useSelector((state) => state.auth);
  const handleClose = () => {
    props.setOpen(false);
  };
  const [categoryToDisplay, setCategoryToDisplay] = React.useState([]);
  const [testingRequirementsDetails, setTestingRequirementsDetails] = React.useState({});

  /* Sorting the array of objects by weightage in descending order and then by skill in ascending order. */
  const handleSort = useMemo(() => {
    return props?.roleData?.skillsDetailsObj?.slice().sort(function (a, b) {
      // First, sort by weightage
      const weightageDiff = b.weightage - a.weightage;
      if (weightageDiff !== 0) {
        return weightageDiff;
      } else {
        // If weightages are the same, sort by fullName
        const fullNameA = authState?.skillsConfig[a.skill]?.fullName || "";
        const fullNameB = authState?.skillsConfig[b.skill]?.fullName || "";
        return fullNameA.localeCompare(fullNameB);
      }
    });
  }, [props?.roleData?.skillsDetailsObj, authState?.skillsConfig]);

  // const handleSort = useMemo(() => {
  //   return props?.roleData?.skillsDetailsObj?.sort(function (a, b) {
  //     return b.weightage - a.weightage || a.skill.localeCompare(b.skill);
  //   });
  // }, [props?.roleData?.skillsDetailsObj]);

  React.useEffect(() => {
    let id = props?.roleData?.uid;
    dispatch(role.roleDetails(id));
    if (id) {
      dispatch(role.getTestingRequirements({ id }));
    }
  }, [props?.roleData?.uid]);

  React.useEffect(() => {
    if (roleState?.roleCategories && props?.roleData?.category) {
      const categoryMapping = roleState?.roleCategories;
      if (categoryMapping && props?.roleData?.category) {
        const categoryDisplayValue = categoryMapping[props?.roleData?.category];
        setCategoryToDisplay(categoryDisplayValue);
      }
    }
  }, [roleState?.roleCategories, props?.roleData?.category]);

  React.useEffect(() => {
    if (roleState?.getTestingRequirementsSuccess) {
      setTestingRequirementsDetails(roleState?.getTestingRequirementsSuccess?.tests);
    }
  }, [roleState?.getTestingRequirementsSuccess]);

  const formatSkills = (skills, skillsConfig) => {
    return skills
      ?.map((skill) => {
        const skillName = skillsConfig[skill.skill]?.fullName || skill.skill;
        const weightage = skill.weightage ? ` (${skill.weightage}%)` : "";
        return `${skillName}${weightage}`;
      })
      .join(", ");
  };

  const formatBehavioralSkills = (skills, dimension) => {
    return skills?.map((skill) => dimension[skill] || skill).join(", ");
  };

  // console.log(roleState.roledetails.isOverridePermitted,"props")
  return (
    <>
      {" "}
      {matches ? (
        <div>
          {/* dialog box when the user clicks on the "Add" button. */}
          <Dialog
            open={props.open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            className={classes.root}
          >
            <DialogTitle id="responsive-dialog-title">
              Viewing {props?.roleData?.name}
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <Divider></Divider>
            <DialogContent className={classes.dialogSty}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                      Role Details{" "}
                    </Typography>
                    <Grid container>
                      <Grid item xs={6} style={{ marginTop: "20px" }}>
                        <Typography className={classes.roletype1}> Role ID</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {props?.roleData?.uid}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ marginTop: "20px" }}>
                        <Typography className={classes.roletype1}> Role Name</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {props?.roleData?.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ marginTop: "20px" }}>
                        <Typography className={classes.roletype1}>Role Category</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {categoryToDisplay?.length > 0 ? categoryToDisplay : []}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ marginTop: "20px" }}>
                        <Typography className={classes.roletype1}>Required Degree</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {props?.roleData?.degree}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ marginTop: "20px" }}>
                        <Typography className={classes.roletype1}>Years of Experience</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {props?.roleData?.yoe}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ marginTop: "20px" }}>
                        <Typography className={classes.roletype1}>Coding Languages</Typography>
                        <Tooltip
                          title={props?.roleData?.codingLang ? props?.roleData?.codingLang?.sort().join(" / ") : ""}
                        >
                          <Typography className={classes.roletype} style={{ color: "#2F2F2F", paddingRight: "25px" }}>
                            {codingLanguageDisplay(
                              props?.roleData?.codingLang ? props?.roleData?.codingLang.sort() : []
                            )}
                          </Typography>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={6} style={{ marginTop: "20px" }}>
                        <Typography className={classes.roletype1}>Created By</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {typeof props?.roleData?.createdBy === "string"
                            ? props?.roleData?.createdBy
                            : props?.roleData?.createdBy?.displayName}
                        </Typography>
                      </Grid>
                      <Grid item xs={6} style={{ marginTop: "20px" }}>
                        <Typography className={classes.roletype1}>Created On</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {moment(props?.roleData?.createdAt).format("MMM DD, YYYY")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: "20px" }}>
                        <Typography className={classes.roletype1}>Internal Notes</Typography>
                        {(props?.roleData?.notesLog || []).map((note, i) => {
                          return (
                            <Grid key={i}>
                              <Typography className={classes.roletype} style={{ color: "#2F2F2F", paddingTop: "0px" }}>
                                {note.msg}
                              </Typography>
                              <Typography className={classes.roletype} style={{ color: "#2F2F2F", paddingTop: "0px" }}>
                                {note.what} by {note.who.displayName} on {TimeZonePT(note.when)}
                              </Typography>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      style={{
                        background: "#FFFBF6",
                        padding: "20px",
                        marginTop: "60px",
                        width: "420px !important",
                        minWidth: "500px",
                      }}
                    >
                      <Grid className={classes.scoreLeftArea} style={{ background: "#fff" }}>
                        <GraphScoreMeter
                          threeLineWithLink={true}
                          line1="target score"
                          custom={true}
                          targetScore={props?.roleData?.targetScore || 720}
                          // targetLabel="target"
                          // score={250}
                          scoreMeterCls={classes.scoreMeter}
                          className={`${classes.scoreMeter}`}
                        ></GraphScoreMeter>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider style={{ marginTop: "20px" }}></Divider>
              <Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} style={{ marginTop: "20px" }}>
                    <Typography className={classes.roletype} style={{ color: "#2F2F2F", marginBottom: "20px" }}>
                      Testing Requirements
                    </Typography>
                    <Typography className={classes.roletype1}>
                      {" "}
                      {roleState?.roledetails?.isOverridePermitted === false ? (
                        <>
                          Override is <b>not</b> permitted on Sections to test
                        </>
                      ) : (
                        "Override permitted on Sections to test"
                      )}
                    </Typography>
                    {/* <Grid container>
                      <Grid item xs={6}>
                        <Typography className={classes.roletype1}>Sections to Test</Typography>
                        {(props?.roleData?.testingRequirement || []).map((item, i) => {
                          return (
                            <Typography className={classes.roletype} style={{ color: "#2F2F2F" }} key={i}>
                              {item === "Presentation" ? "Behavioral" : item}
                            </Typography>
                          );
                        })}
                      </Grid>
                      {roleState?.roledetails?.behavior &&
                      roleState?.roledetails?.testingRequirement?.includes("Presentation") ? (
                        <Grid item xs={6}>
                          <Typography className={classes.roletype1}>
                            Behavioral Skills to test (
                            {roleState?.roledetails?.behavior?.type === "video" ? "Video" : "Classic"})
                          </Typography>
                          {([...roleState?.roledetails?.behavior?.skills] || [])
                            .sort((a, b) => {
                              const nameA = roleState?.roledetails?.behavior?.dimension[a] || a;
                              const nameB = roleState?.roledetails?.behavior?.dimension[b] || b;
                              return nameA.localeCompare(nameB);
                            })
                            .map((item, i) => {
                              return (
                                <Typography className={classes.roletype} style={{ color: "#2F2F2F" }} key={i}>
                                  {roleState?.roledetails?.behavior?.dimension[item] || item}
                                </Typography>
                              );
                            })}
                        </Grid>
                      ) : null}
                    </Grid> */}
                    <Grid container spacing={3} sx={{ mt: 0, mb: "30px" }}>
                      {roleState?.roledetails?.testingRequirement?.includes("Profile") && (
                        <Grid item xs={6}>
                          <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                            Profile Section
                          </Typography>
                          <Typography className={`${classes.sectionText} ${classes.sectionContainer}`}>
                            {testMapping[testingRequirementsDetails?.profile?.type] ||
                              testingRequirementsDetails?.profile?.type}
                            <span className={classes.sectionDots}>&#x23FA;</span>{" "}
                            {testingRequirementsDetails?.profile?.timeDuration} Minutes
                          </Typography>
                          <Typography className={classes.sectionText} style={{ color: "#2F2F2F" }}>
                            Resume and social media evaluation with skills, education and work experience
                          </Typography>
                        </Grid>
                      )}
                      {roleState?.roledetails?.testingRequirement?.includes("Technical") && (
                        <Grid item xs={6}>
                          <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                            Technical Section
                          </Typography>
                          <Typography className={`${classes.sectionText} ${classes.sectionContainer}`}>
                            {testMapping[testingRequirementsDetails?.technical?.type] ||
                              testingRequirementsDetails?.technical?.type}
                            {testingRequirementsDetails?.technical?.questionCount && (
                              <>
                                <span className={classes.sectionDots}>&#x23FA;</span>{" "}
                                {testingRequirementsDetails?.technical?.questionCount} Questions{" "}
                              </>
                            )}
                            {testingRequirementsDetails?.technical?.timeDuration && (
                              <>
                                <span className={classes.sectionDots}>&#x23FA;</span>{" "}
                                {testingRequirementsDetails?.technical?.timeDuration} Minutes
                              </>
                            )}
                          </Typography>
                          <Typography className={classes.sectionText} style={{ color: "#2F2F2F" }}>
                            Objective Test evaluating <b>{testingRequirementsDetails?.technical?.skills?.length}</b>{" "}
                            skills:{" "}
                            {formatSkills(testingRequirementsDetails?.technical?.skills, authState?.skillsConfig)}
                          </Typography>
                        </Grid>
                      )}
                      {roleState?.roledetails?.testingRequirement?.includes("Coding") && (
                        <Grid item xs={6}>
                          <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                            Coding Section
                          </Typography>
                          <Typography className={`${classes.sectionText} ${classes.sectionContainer}`}>
                            {testMapping[testingRequirementsDetails?.coding?.type] ||
                              testingRequirementsDetails?.coding?.type}
                            {testingRequirementsDetails?.coding?.questionCount && (
                              <>
                                <span className={classes.sectionDots}>&#x23FA;</span>{" "}
                                {testingRequirementsDetails?.coding?.questionCount} Questions{" "}
                              </>
                            )}
                            {testingRequirementsDetails?.coding?.timeDuration && (
                              <>
                                <span className={classes.sectionDots}>&#x23FA;</span>{" "}
                                {testingRequirementsDetails?.coding?.timeDuration} Minutes
                              </>
                            )}
                          </Typography>
                          <Typography className={classes.sectionText} style={{ color: "#2F2F2F" }}>
                            Coding test with IDE evaluating problem-solving through coding exercises
                          </Typography>
                        </Grid>
                      )}
                      {roleState?.roledetails?.testingRequirement?.includes("Presentation") && (
                        <Grid item xs={6}>
                          <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                            Behavioral Section
                          </Typography>
                          <Typography className={`${classes.sectionText} ${classes.sectionContainer}`}>
                            {testingRequirementsDetails?.presentation?.type === "standard" &&
                            testingRequirementsDetails?.presentation?.isVideoBehavioral === true
                              ? "Standard Video Test"
                              : testMapping[testingRequirementsDetails?.presentation?.type] ||
                                testingRequirementsDetails?.presentation?.type}
                            {testingRequirementsDetails?.presentation?.questionCount && (
                              <>
                                <span className={classes.sectionDots}>&#x23FA;</span>{" "}
                                {testingRequirementsDetails?.presentation?.questionCount} Questions{" "}
                              </>
                            )}
                            {testingRequirementsDetails?.presentation?.timeDuration && (
                              <>
                                <span className={classes.sectionDots}>&#x23FA;</span>{" "}
                                {testingRequirementsDetails?.presentation?.timeDuration} Minutes
                              </>
                            )}
                          </Typography>
                          {testingRequirementsDetails?.presentation?.skills && (
                            <Typography className={classes.sectionText} style={{ color: "#2F2F2F" }}>
                              {testingRequirementsDetails?.presentation?.isVideoBehavioral === true ? (
                                <>
                                  Video interview-based test evaluating{" "}
                                  <b>{testingRequirementsDetails?.presentation?.skills.length}</b> skills:{" "}
                                  {formatBehavioralSkills(
                                    testingRequirementsDetails?.presentation?.skills,
                                    roleState?.roledetails?.behavior?.dimension
                                  )}
                                </>
                              ) : (
                                <>
                                  Objective Test evaluating{" "}
                                  <b>{testingRequirementsDetails?.presentation?.skills.length}</b> skills:{" "}
                                  {formatBehavioralSkills(
                                    testingRequirementsDetails?.presentation?.skills,
                                    roleState?.roledetails?.behavior?.dimension
                                  )}
                                </>
                              )}
                            </Typography>
                          )}
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  {/* <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={8}>
                        <Typography className={classes.roletype1} style={{ height: "45px" }}>
                          Skills to Test
                        </Typography>
                        {(handleSort || []).map((item, i) => {
                          return (
                            <Grid display={"flex"} justifyContent={"space-between"} gap="20px" key={i}>
                              <Typography className={classes.roletype} style={{ color: "#2F2F2F", height: "45px" }}>
                                {authState?.skillsConfig[item.skill]?.fullName}
                              </Typography>
                            </Grid>
                          );
                        })}
                      </Grid>
                      <Grid item xs={4}>
                        <Typography className={classes.roletype1} style={{ height: "45px" }}>
                          Assigned Weight
                        </Typography>
                        {(handleSort || []).map((item, i) => {
                          return (
                            <>
                              <Typography
                                className={classes.roletype}
                                style={{
                                  color: "#2F2F2F",
                                  paddingTop: "0px",
                                  height: "45px",
                                }}
                                key={i}
                              >
                                {item.weightage}%
                              </Typography>
                            </>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Grid> */}
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </div>
      ) : (
        <div>
          {/* dialog box when the user clicks on the "Add" button. */}
          <Dialog
            fullScreen={fullScreen}
            open={props.open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            className={classes.root}
          >
            {/* dialog box with close btn */}
            <DialogTitle id="responsive-dialog-title">
              Viewing {props?.roleData?.name}
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  // color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <Divider></Divider>
            <DialogContent className={classes.dialogSty}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={0.7}></Grid>
                  <Grid item xs={6.3}>
                    <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                      Role Details{" "}
                    </Typography>
                    <Grid container>
                      <Grid item xs={4} style={{ marginTop: "20px" }}>
                        <Typography className={classes.roletype1}> Role ID</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {props?.roleData?.uid}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} style={{ marginTop: "20px" }}>
                        <Typography className={classes.roletype1}> Role Name</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {props?.roleData?.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} style={{ marginTop: "20px" }}></Grid>
                      <Grid item xs={4} style={{ marginTop: "20px" }}>
                        <Typography className={classes.roletype1}>Role Category</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {categoryToDisplay?.length > 0 ? categoryToDisplay : []}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} style={{ marginTop: "20px" }}>
                        <Typography className={classes.roletype1}>Required Degree</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {props?.roleData?.degree}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} style={{ marginTop: "20px" }}>
                        <Typography className={classes.roletype1}>Years of Experience</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {props?.roleData?.yoe}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} style={{ marginTop: "20px" }}>
                        <Typography className={classes.roletype1}>Coding Languages</Typography>
                        <Tooltip
                          title={props?.roleData?.codingLang ? props?.roleData?.codingLang?.sort().join(" / ") : ""}
                        >
                          <Typography className={classes.roletype} style={{ color: "#2F2F2F", paddingRight: "25px" }}>
                            {codingLanguageDisplay(
                              props?.roleData?.codingLang ? props?.roleData?.codingLang.sort() : []
                            )}
                          </Typography>
                        </Tooltip>
                      </Grid>
                      <Grid item xs={4} style={{ marginTop: "20px" }}>
                        <Typography className={classes.roletype1}>Created By</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {typeof props?.roleData?.createdBy === "string"
                            ? props?.roleData?.createdBy
                            : props?.roleData?.createdBy?.displayName}
                        </Typography>
                      </Grid>
                      <Grid item xs={4} style={{ marginTop: "20px" }}>
                        <Typography className={classes.roletype1}>Created On</Typography>
                        <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                          {moment(props?.roleData?.createdAt).format("MMM DD, YYYY")}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: "20px" }}>
                        <Typography className={classes.roletype1}>Internal Notes</Typography>
                        {(props?.roleData?.notesLog || []).map((note, i) => {
                          return (
                            <Grid key={i}>
                              <Typography className={classes.roletype} style={{ color: "#2F2F2F", paddingTop: "0px" }}>
                                {note.msg}
                              </Typography>
                              <Typography className={classes.roletype} style={{ color: "#2F2F2F", paddingTop: "0px" }}>
                                {note.what} by {note.who.displayName} on {TimeZonePT(note.when)}
                              </Typography>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4.3}>
                    <Grid
                      style={{
                        background: "#FFFBF6",
                        padding: "20px",
                        marginTop: "60px",
                        width: "420px !important",
                        minWidth: "500px",
                      }}
                    >
                      <Grid className={classes.scoreLeftArea} style={{ background: "#fff" }}>
                        <GraphScoreMeter
                          threeLineWithLink={true}
                          line1="target score"
                          custom={true}
                          targetScore={props?.roleData?.targetScore || 720}
                          // targetLabel="target"
                          // score={250}
                          scoreMeterCls={classes.scoreMeter}
                          className={`${classes.scoreMeter}`}
                        ></GraphScoreMeter>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider style={{ marginTop: "20px" }}></Divider>
              <Grid>
                <Grid container spacing={2}>
                  <Grid item xs={0.7}></Grid>
                  <Grid item xs={11.3} style={{ marginTop: "20px" }}>
                    <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                      Testing Requirements
                    </Typography>
                    <Typography className={classes.roletype1}>
                      {" "}
                      {roleState?.roledetails?.isOverridePermitted === false ? (
                        <>
                          Override is <b>not</b> permitted on Sections to test
                        </>
                      ) : (
                        "Override permitted on Sections to test"
                      )}
                    </Typography>
                    {/* <Grid container>
                      <Grid item xs={2.5}>
                        <Typography className={classes.roletype1}>Sections to Test</Typography>
                        {(props?.roleData?.testingRequirement || []).map((item, i) => {
                          return (
                            <Typography className={classes.roletype} style={{ color: "#2F2F2F" }} key={i}>
                              {item === "Presentation" ? "Behavioral" : item}
                            </Typography>
                          );
                        })}
                      </Grid>
                      <Grid item xs={3.5}>
                        <Typography className={classes.roletype1}>Skills to Test</Typography>
                        {(handleSort || []).map((item, i) => {
                          return (
                            <Grid display={"flex"} justifyContent={"space-between"} gap="20px" key={i}>
                              <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                              
                                {authState?.skillsConfig[item.skill]?.fullName}
                              </Typography>
                            </Grid>
                          );
                        })}
                      </Grid>
                      <Grid item xs={2.5}>
                        <Typography className={classes.roletype1}>Assigned Weight</Typography>
                        {(handleSort || []).map((item, i) => {
                          return (
                            <>
                              <Typography
                                className={classes.roletype}
                                style={{ color: "#2F2F2F", paddingTop: "0px" }}
                                key={i}
                              >
                                {item.weightage}%
                              </Typography>
                            </>
                          );
                        })}
                      </Grid>
                      {roleState?.roledetails?.behavior &&
                      roleState?.roledetails?.testingRequirement?.includes("Presentation") ? (
                        <Grid item xs={3.5}>
                          <Typography className={classes.roletype1}>
                            Behavioral Skills to test (
                            {roleState?.roledetails?.behavior?.type === "video" ? "Video" : "Classic"})
                          </Typography>
                          {([...roleState?.roledetails?.behavior?.skills] || [])
                            .sort((a, b) => {
                              const nameA = roleState?.roledetails?.behavior?.dimension[a] || a;
                              const nameB = roleState?.roledetails?.behavior?.dimension[b] || b;
                              return nameA.localeCompare(nameB);
                            })
                            .map((item, i) => {
                              return (
                                <Typography className={classes.roletype} style={{ color: "#2F2F2F" }} key={i}>
                                  {roleState?.roledetails?.behavior?.dimension[item] || item}
                                </Typography>
                              );
                            })}
                          {/* {roleState?.roledetails?.behavior?.type === "video" && (
                            <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                              {roleState?.roledetails?.behavior?.dimension["Communication"] ||
                                "Effective Communication"}
                            </Typography>
                          )} 
                        </Grid>
                      ) : null}
                    </Grid> */}
                    <Grid container spacing={3} sx={{ mt: 0, mb: "30px" }}>
                      {roleState?.roledetails?.testingRequirement?.includes("Profile") && (
                        <Grid item xs={6}>
                          <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                            Profile Section
                          </Typography>
                          <Typography className={`${classes.sectionText} ${classes.sectionContainer}`}>
                            {testMapping[testingRequirementsDetails?.profile?.type] ||
                              testingRequirementsDetails?.profile?.type}
                            <span className={classes.sectionDots}>&#x23FA;</span>{" "}
                            {testingRequirementsDetails?.profile?.timeDuration} Minutes
                          </Typography>
                          <Typography className={classes.sectionText} style={{ color: "#2F2F2F" }}>
                            Resume and social media evaluation with skills, education and work experience
                          </Typography>
                        </Grid>
                      )}
                      {roleState?.roledetails?.testingRequirement?.includes("Technical") && (
                        <Grid item xs={6}>
                          <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                            Technical Section
                          </Typography>
                          <Typography className={`${classes.sectionText} ${classes.sectionContainer}`}>
                            {testMapping[testingRequirementsDetails?.technical?.type] ||
                              testingRequirementsDetails?.technical?.type}
                            {testingRequirementsDetails?.technical?.questionCount && (
                              <>
                                <span className={classes.sectionDots}>&#x23FA;</span>{" "}
                                {testingRequirementsDetails?.technical?.questionCount} Questions{" "}
                              </>
                            )}
                            {testingRequirementsDetails?.technical?.timeDuration && (
                              <>
                                <span className={classes.sectionDots}>&#x23FA;</span>{" "}
                                {testingRequirementsDetails?.technical?.timeDuration} Minutes
                              </>
                            )}
                          </Typography>
                          <Typography className={classes.sectionText} style={{ color: "#2F2F2F" }}>
                            Objective Test evaluating <b>{testingRequirementsDetails?.technical?.skills?.length}</b>{" "}
                            skills:{" "}
                            {formatSkills(testingRequirementsDetails?.technical?.skills, authState?.skillsConfig)}
                          </Typography>
                        </Grid>
                      )}
                      {roleState?.roledetails?.testingRequirement?.includes("Coding") && (
                        <Grid item xs={6}>
                          <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                            Coding Section
                          </Typography>
                          <Typography className={`${classes.sectionText} ${classes.sectionContainer}`}>
                            {testMapping[testingRequirementsDetails?.coding?.type] ||
                              testingRequirementsDetails?.coding?.type}
                            {testingRequirementsDetails?.coding?.questionCount && (
                              <>
                                <span className={classes.sectionDots}>&#x23FA;</span>{" "}
                                {testingRequirementsDetails?.coding?.questionCount} Questions{" "}
                              </>
                            )}
                            {testingRequirementsDetails?.coding?.timeDuration && (
                              <>
                                <span className={classes.sectionDots}>&#x23FA;</span>{" "}
                                {testingRequirementsDetails?.coding?.timeDuration} Minutes
                              </>
                            )}
                          </Typography>
                          <Typography className={classes.sectionText} style={{ color: "#2F2F2F" }}>
                            Coding test with IDE evaluating problem-solving through coding exercises
                          </Typography>
                        </Grid>
                      )}
                      {roleState?.roledetails?.testingRequirement?.includes("Presentation") && (
                        <Grid item xs={6}>
                          <Typography className={classes.roletype} style={{ color: "#2F2F2F" }}>
                            Behavioral Section
                          </Typography>
                          <Typography className={`${classes.sectionText} ${classes.sectionContainer}`}>
                            {testingRequirementsDetails?.presentation?.type === "standard" &&
                            testingRequirementsDetails?.presentation?.isVideoBehavioral === true
                              ? "Standard Video Test"
                              : testMapping[testingRequirementsDetails?.presentation?.type] ||
                                testingRequirementsDetails?.presentation?.type}
                            {testingRequirementsDetails?.presentation?.questionCount && (
                              <>
                                <span className={classes.sectionDots}>&#x23FA;</span>{" "}
                                {testingRequirementsDetails?.presentation?.questionCount} Questions{" "}
                              </>
                            )}
                            {testingRequirementsDetails?.presentation?.timeDuration && (
                              <>
                                <span className={classes.sectionDots}>&#x23FA;</span>{" "}
                                {testingRequirementsDetails?.presentation?.timeDuration} Minutes
                              </>
                            )}
                          </Typography>
                          {testingRequirementsDetails?.presentation?.skills && (
                            <Typography className={classes.sectionText} style={{ color: "#2F2F2F" }}>
                              {testingRequirementsDetails?.presentation?.isVideoBehavioral === true ? (
                                <>
                                  Video interview-based test evaluating{" "}
                                  <b>{testingRequirementsDetails?.presentation?.skills.length}</b> skills:{" "}
                                  {formatBehavioralSkills(
                                    testingRequirementsDetails?.presentation?.skills,
                                    roleState?.roledetails?.behavior?.dimension
                                  )}
                                </>
                              ) : (
                                <>
                                  Objective Test evaluating{" "}
                                  <b>{testingRequirementsDetails?.presentation?.skills.length}</b> skills:{" "}
                                  {formatBehavioralSkills(
                                    testingRequirementsDetails?.presentation?.skills,
                                    roleState?.roledetails?.behavior?.dimension
                                  )}
                                </>
                              )}
                            </Typography>
                          )}
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default RoleViewModel;
