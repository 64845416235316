// disable eslint for this file
/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { Container, Grid, Typography, Link, useMediaQuery, Snackbar } from "@mui/material";
import { useNavigate, useLocation, generatePath } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "../../../pages/home/home.css";
import Button from "../../../components/controls/Button";
import Timer from "../../../components/Timer/Timer";
import Answers from "../Answers/Answers";
import quiz from "../../../redux/actions/quiz";
import AppConfig from "../../../constants/AppConfig";
import Popup from "../../../components/Popup";
import LOneExampleContent from "../../../components/dialogContent/LOneExampleContent";
import * as globalService from "../../../services/globalService";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ButtonMaterial from "@mui/material/Button";
import TimeoutDialog from "../../TimeoutDialog";
// import BayoneLogo from "../../../assets/svg/bayonelogo.svg";
import SubmitConfirmationDialog from "../../../components/SubmitConfirmationDialog";
import { getUnique, ImagebaseUrl, transformToHTML } from "../../../services/globalService";
import ExitDialog from "../../Exitmodel";
import { theme } from "../../../styles/theme";
// import ReactWebcam from "../../ReactWebcam";
import SessionTracking from "../../SessionTracking";
import MathJaxContent from "../../MathJaxContent";
// import { invalid } from "moment";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.primary.light,
    minHeight: "100vh",
    [theme.breakpoints.up("tablet")]: {},
  },
  container: {
    padding: "16px",
    [theme.breakpoints.up("tablet")]: {
      paddingLeft: "64px !important",
      paddingRight: "64px !important",
    },
  },
  section: {
    width: "100%",
  },
  title: {
    flexGrow: 1,
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  main: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    [theme.breakpoints.up("tablet")]: {
      width: "60%",
    },
  },
  header: {
    width: "100%",
  },
  item1: {
    height: "60px",
    position: "sticky",
    top: "0px",
    backgroundColor: theme.palette.background.main,
    borderBottom: `0.5px solid ${theme.palette.btnPrimary.light}`,
    [theme.breakpoints.up("tablet")]: {
      height: "80px",
    },
  },
  item2: {
    marginTop: "32px",
    [theme.breakpoints.down("sm")]: {
      width: "-webkit-fill-available",
      marginRight: "10px",
    },
  },
  item3: {
    marginTop: "32px",
  },
  item4: {
    marginTop: "8px",
  },
  answers: {
    marginTop: "24px",
  },
  info: {
    marginTop: "24px",
    display: "flex",
    alignItems: "center",
    paddingBottom: "140px",
  },
  // center: {
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "center",
  // },
  item5: {
    height: "80px",
    // backgroundColor: "#FFFFFF",
  },
  pageInfo: {
    height: "36px",
    width: "fit-content",
    borderRadius: "4px",
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  exitBtn: {
    "& div": {
      float: "right",
    },
  },
  next: {
    "& button": {
      float: "right",
      height: 40,
    },
  },
  progess: {
    color: theme.palette.btnPrimary.tertiaryText,
    [theme.breakpoints.up("tablet")]: {},
  },
  progressArea: {
    marginTop: "20px",
    color: theme.palette.btnPrimary.tertiaryText,
    [theme.breakpoints.up("tablet")]: {
      marginTop: "24px",
    },
  },
  font16: {
    fontSize: theme.typography.link2.fontSize,
  },
  questionArea: {
    display: "flex",
  },
  qIndex: {},
  qTitle: {
    flex: "90%",
  },
  sidebar: {
    width: 500,
  },
  actionLink: {
    marginTop: 16,
  },
  qText: {
    whiteSpace: "pre-wrap",
  },
  stickToBottom: {
    position: "fixed",
    bottom: 0,
    width: "100%",
  },
  logoCls: {
    height: "24px",
    [theme.breakpoints.up("tablet")]: {
      height: "30px",
    },
  },
  exit: {
    color: "#787878 !important",
  },
  previous: {
    color: "#787878 !important",
  },
  previousLink: {
    marginRight: "20px !important",
    [theme.breakpoints.up("tablet")]: {
      marginRight: "40px !important",
    },
  },

  addFlex: {
    display: "flex",
  },
  timeExceedDialog: {
    width: "443px",
    height: "403px",
    margin: "auto",
  },
  hintIconCls: {
    verticalAlign: "center",
    marginTop: "-2px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  containerCls: {
    overflowY: "auto",
    paddingBottom: 100,
  },
  Loader: {
    position: "absolute",
    zIndex: 1,
    left: "50%",
    top: "50%",
  },

  ques_st: theme.typography.b1Medium,
  previousLink1: theme.typography.link1,
  linkUnderline: {
    textDecoration: "none !important",
    // marginRight:'10px !important'
  },
  webCam: {
    position: "absolute",
    top: "8rem",
    left: "auto",
    // border: "1px solid #000",
  },
  webCamMobile: {
    marginTop: "24px",
    display: "flex",
    justifyContent: "center",
  },
});

function QuizContainer(props) {
  // eslint-disable-next-line
  const { quizProp, routeParams, candidatelist, qIndex, location, ...other } = props;
  const [openPopup, setOpenPopup] = useState(false);
  const [exitopen, setExitopen] = useState(false);
  let locationRef = useLocation();
  let isFromQuizSummery = locationRef?.state?.isFromQuizSummery || false;
  // let selectedQuesNdAns = useSelector((state) => state?.quiz?.selectedQuesNdAns); // AR: Cleanup
  const counterRef = useRef();
  const [userAns, setUserAns] = useState({});
  const [totalTimetaken, setTotalTimetaken] = useState(0);
  const [alertDialogContent, setAlertDialogContent] = useState("");
  const [actionInfo, setActionInfo] = useState("");
  const { categoryTheme } = AppConfig;
  // const { l1 } = stepToWelcomeScreenMap; // AR: Cleanup
  const dispatch = useDispatch();
  const history = useNavigate();
  // const theme = useTheme();
  const isNotMobile = useMediaQuery(theme.breakpoints.up("tablet"));
  const isMobileCam = useMediaQuery(theme.breakpoints.down("md"));
  const isNotMobileCam = useMediaQuery(theme.breakpoints.up("md"));
  const classes = useStyles();
  const quesRes = useSelector((state) => state.quiz.questions);
  const qTrackRes = useSelector((state) => state.quiz.tracking);
  const authObj = useSelector((state) => state.auth);
  const quizState = useSelector((state) => state.quiz);
  const roleState = useSelector((state) => state.role);
  const [isPageChange, setPageChange] = useState(false);
  const [isShowSnackBar, setShowSnackBar] = useState(false);
  const [isShowTimeoutDialog, setShowTimeoutDialog] = useState(false);
  const [timeoutFlag, setTimeoutFlag] = useState(false);
  const [timeoutMessage, setTimeoutMessage] = useState("");
  const [isShowSubmitDialog, setShowSubmitDialog] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [qusEvent, setQusEvent] = useState("");
  const [submitDialogContent, setSubmitDialogContent] = useState("");
  const unansweredQuestions = useSelector((state) => state.quiz.unansweredQuestions);
  const [timeoutType, setTimeoutType] = useState("");
  // eslint-disable-next-line
  const [logoUrl, setLogoUrl] = React.useState(process.env.REACT_APP_ASSETS_URL);
  const [domainDetails, setDomainDetails] = useState({});

  const [timerMin, setTimerMin] = useState();
  let userDetails = JSON.parse(localStorage.getItem("user_details"));
  let value = `${qIndex} of ${quesRes.length}`;
  let question = quizProp.quesObject.Question_Description;
  let questionID = quizProp.quesObject.Question_ID;
  let nextQuestion = qIndex < quesRes.length + 1 ? quesRes[qIndex] : null;
  let currentQuestion = qIndex < quesRes.length ? quesRes[qIndex - 1] : null;
  let prevQuestion = qIndex < quesRes.length ? quesRes[qIndex - 2] : null;
  let answers = [
    quizProp.quesObject.Answer_A,
    quizProp.quesObject.Answer_B,
    quizProp.quesObject.Answer_C,
    quizProp.quesObject.Answer_D,
    quizProp.quesObject.Answer_E,
  ];
  let category = quizProp.quesObject.Category == "" ? "Profile Details" : quizProp.quesObject.Category;

  const [trackingLoading, setTrackingLoading] = useState(false);

  useEffect(() => {
    // console.log("Setting unansweredQuestions: ", unansweredQuestions);
    let unanswered = 0;
    if (quizState.level == AppConfig.LEVEL0 && quizState.selectedQuesNdAns.length > quizState.Min_Questions) {
      unanswered = 0;
    } else {
      unanswered = unansweredQuestions;
    }

    let msg = "";
    if (unanswered > 0) {
      // msg = `You have run out of time and cannot make any more changes. You have not answered ${unanswered} questions. Do you wish to submit your answers now?.It will automatically saved.`;
      if (timeoutType == "timeout") {
        msg = `You have run out of time and cannot make any more changes. You have not answered ${unansweredQuestions} questions. The test will automatically submit.`;
      } else if (timeoutType == "focusout") {
        msg = `This test was out of focus for more than the permitted time. It will submit automatically now.`;
      }
    } else {
      // msg = `You have run out of time and cannot make any more changes. You have answered all questions. Do you wish to submit your answers now?.It will automatically saved.`;
      if (timeoutType == "timeout") {
        msg = `You have run out of time and cannot make any more changes. You have answered all questions. The test will automatically submit.`;
      } else if (timeoutType == "focusout") {
        msg = `This test was out of focus for more than the permitted time. It will submit automatically now.`;
      }
    }

    setTimeoutMessage(msg);
  }, [timeoutType]);
  // }, [unansweredQuestions,timeoutType]);

  useEffect(() => {
    const handleTabClose = (event) => {
      event.preventDefault();

      // console.log('beforeunload event triggered',event);
      submitAllAnswers();
      let message = "Are you sure you want to exit?";
      event.returnValue = message;
      return message;
      // FK cleanup comments july 2023
      // return (event.returnValue =
      //   'Are you sure you want to exit?');
    };

    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  useEffect(() => {
    if (quizState.testResult && quizState.level == AppConfig.LEVEL0) {
      dispatch(quiz.setL0TestResult(quizState.testResult));
      if (userDetails?.role === "Candidate") {
        history("/candidatedashboard");
      } else {
        history(generatePath("/invitecandidateview/:id", { id: roleState?.candidateDashboardDetails?.uid }));
      }
    }

    if (quizState.testResult && quizState.level == AppConfig.LEVEL1) {
      if (userDetails?.role === "Candidate") {
        history("/candidatedashboard");
      } else {
        history(generatePath("/invitecandidateview/:id", { id: roleState?.candidateDashboardDetails?.uid }));
      }
    }
    if (quizState.testResult && quizState.level == AppConfig.LEVEL3) {
      if (userDetails?.role === "Candidate") {
        history("/candidatedashboard");
      } else {
        history(generatePath("/invitecandidateview/:id", { id: roleState?.candidateDashboardDetails?.uid }));
      }
    }
    if (quizState.testResult && quizState.level == AppConfig.LEVEL2) {
      if (userDetails?.role === "Candidate") {
        history("/candidatedashboard");
      } else {
        history(generatePath("/invitecandidateview/:id", { id: roleState?.candidateDashboardDetails?.uid }));
      }
    }
  }, [quizState.testResult]);

  useEffect(() => {
    if (qIndex == 1) {
      dispatch(quiz.setPrevTime(quizState?.config?.Time_Mins * 60));
    }
    if (quizState && quizState.isPlaying == false) {
      dispatch(quiz.timerPlaying(true));
    }
  }, []);

  const submitAllAnswers = () => {
    if (!isSubmit) {
      props.isSubmitted(true);
      setIsSubmit(true);
      handleTrackingApiReq("submit");
      var ansLn = quizState.selectedAnswers.length;
      var preLn = quizState.selectedQuesNdAns.length;
      var result = [];
      // let totalTime = 0
      // let timeLatest = calcTimeTaken();
      // for (var i = 1; i < preLn; i++){
      //  totalTime +=  timeLatest
      //  console.log("gsdfghfsdg",totalTime)
      // }
      if (quizState.level == AppConfig.LEVEL0 && quizState.selectedQuesNdAns.length > quizState.Min_Questions) {
        for (var i = 1; i < preLn; i++) {
          var o = quizState.selectedQuesNdAns[i];
          var p = o.value;
          // console.log("mgmfg", o.value)
          // let answer =
          //    p.map((item) => item);
          let answer = typeof p.answer == "string" ? [p.answer] : p.answer.map((item) => item.answer);
          if (o?.Question_ID) {
            result.push({
              Question_ID: o.Question_ID,
              User_Answers: answer,
            });
          }
        }
      } else {
        // let totalTime = 0
        // let timeLatest = quizState.tracking.timeTaken;
        // for(var i=0; i < ansLn; i++){

        //  totalTime +=  timeLatest
        //  //console.log("gsdfghfsdg",totalTime)
        // }
        // console.log("ewerewre",totalTime)
        for (var i = 0; i < ansLn; i++) {
          var o = quizState.selectedAnswers[i];
          // console.log("o.answer", o.answer)
          let answer = typeof o.answer == "string" ? [o.answer] : o.answer?.map((item) => item.answer);
          if (o?.ansId && o?.questionID) {
            result.push({
              Question_ID: o.questionID,
              User_Answers: answer,
            });
          }
        }
      }

      result = getUnique(result, "Question_ID");
      /* Edit Mode*/
      let filterData;
      if (quizState.previousSession?.result === undefined || quizState.previousSession?.result.length === 0) {
        filterData = result;
      }
      if (quizState.previousSession?.result !== undefined && quizState.previousSession?.result.length > 0) {
        // Removing unanswered q/a in current result
        result = result.filter(
          (elem, index, self) =>
            self.findIndex((t) => {
              return t.Question_ID === elem.Question_ID && t.User_Answers !== undefined && t.User_Answers.length !== 0;
            }) === index
        );
        //Comparing current session and previous session Q/A's
        let valuesA = quizState.previousSession.result.reduce((a, c) => {
          a[c.Question_ID] = c.Question_ID;
          return a;
        }, {});
        let valuesB = result.reduce((a, c) => {
          a[c.Question_ID] = c.Question_ID;
          return a;
        }, {});
        filterData = quizState.previousSession.result
          .filter((c) => {
            return !valuesB[c.Question_ID];
          })
          .concat(
            result.filter((c) => {
              return !valuesA[c.Question_ID];
            })
          );
        if (filterData[0] === "") {
          filterData.shift();
        }
        //Removing unAnswer q/a and slected
        filterData = filterData.filter(
          (elem, index, self) =>
            self.findIndex((t) => {
              return t.Question_ID === elem.Question_ID && t.User_Answers !== undefined && t.User_Answers.length !== 0;
            }) === index
        );
      }
      let data = [...result, ...filterData];
      //Remove duplicate records
      data = data.filter((v, i, a) => a.findIndex((v2) => v2.Question_ID === v.Question_ID) === i);
      /* Edit Mode*/
      let payload = {
        code: quizState.sessionByLevel,
        email: roleState?.candidateDashboardDetails
          ? roleState?.candidateDashboardDetails?.candidate?.user?.email
          : "notregistered",
        result: data,
        //result: result,
        //inviteId:roleState?.candidateDashboardDetails?.uid
      };
      let payload1 = {
        data: payload,
        inviteId: roleState?.candidateDashboardDetails?.uid,
      };
      dispatch(quiz.submitTestResults(payload1));
      dispatch(quiz.submitAnswers(false));

      if (userDetails.role !== "Candidate") {
        history(generatePath("/invitecandidateview/:id", { id: roleState?.candidateDashboardDetails?.uid }));
      }
    }
  };

  useEffect(() => {
    if (quizState.submitAnswers) {
      if (quizState.quizSummery.totalQuestions - quizState.quizSummery.answeredQuestions > 0) {
        dispatch(quiz.submitAnswers(false));
        let contentText = `You have ${
          quizState.quizSummery.totalQuestions - quizState.quizSummery.answeredQuestions
        } unanswered question${
          unansweredQuestions == 1 ? "" : "s"
        }. You cannot attempt this section again if you submit it now. Are you sure you want to submit?`;
        setSubmitDialogContent(contentText);
        setShowSubmitDialog(true);
        return;
      } else {
        let contentText =
          category === "Technical"
            ? `You cannot attempt this section again if you submit it now. Are you sure you want to submit?`
            : `Are you sure you want to submit?`;
        setSubmitDialogContent(contentText);
        setShowSubmitDialog(true);
        return;
      }
    }
  }, [quizState.submitAnswers]);

  useEffect(() => {
    if (timeoutFlag == true && !isSubmit) {
      props.isSubmitted(true);
      // console.log("userAns", userAns);
      setPageChange(false);
      if (timerMin) {
        dispatch(quiz.setTimer(timerMin));
      }
      handleCalcQuizSummery();
      handleTrackingApiReq("timeout");

      dispatch(quiz.timerPlaying(false));
      setExitopen(false);
      setTimeout(() => {
        setShowTimeoutDialog(true);
        setTimeoutFlag(false);
      }, 1000);
    }
  }, [timeoutFlag]);

  useEffect(() => {
    if (isShowTimeoutDialog === true) {
      setTimeout(() => {
        onSubmitWhileTimout();
      }, 3000);
    }
  }, [isShowTimeoutDialog]);

  useEffect(() => {
    // console.log('\n\n\n\nqus track',qusEvent,props.onJumpQus,qIndex)
    if (quesRes && quesRes.length >= qIndex && qTrackRes && isPageChange == true) {
      //
      setTrackingLoading(false);
      if (category) {
        dispatch(quiz.updatePrevCategory(category));
      }

      if (qusEvent == "next" && quesRes.length > qIndex) {
        history(`/QuizQuestions/${authObj.level}/${qIndex + 1}`);
      }

      if (qusEvent == "prev") {
        if (quesRes && qIndex == 2) {
          history({
            pathname: `/QuizQuestions/${authObj.level}/${qIndex - 1}`,
            state: {
              timerReset: false,
            },
          });
        } else if (quesRes && qIndex > 1) {
          history({
            pathname: `/QuizQuestions/${authObj.level}/${qIndex - 1}`,
            state: {
              timerReset: true,
            },
          });
        }
      }

      if (qusEvent == "jump") {
        history({
          pathname: `/QuizQuestions/${authObj.level}/${props.onJumpQus}`,
          state: {
            timerReset: true,
          },
        });
      }
      // else {
      //   history(generatePath("/invitecandidateview/:id", { id:roleState?.candidateDashboardDetails?.uid }))
      // }

      counterRef.current.setDefaultIndex();
    }

    // return () => console.log("Will Unmounted"); // FK cleanup console july 2023
    return () => {
      setTrackingLoading(false);
      console.log("Will Unmounted");
    };
  }, [qTrackRes]);

  const calcTimeTaken = () => {
    let timeRemainingSec = quizState?.prevTime;
    if (timerMin) {
      let currentTimeArray = timerMin.split(".");
      timeRemainingSec = Number(currentTimeArray[0]) * 60 + Number(currentTimeArray[1]);
    }
    let timeTakenSec = quizState?.prevTime - timeRemainingSec;
    let timeTaken = timeTakenSec / 60;
    dispatch(quiz.setPrevTime(timeRemainingSec));
    return timeTaken;
  };

  const handleTrackingApiReq = (eventType = "") => {
    //
    setTrackingLoading(true);
    let timeTaken = calcTimeTaken();
    if (quizProp.quesObject.Question_Type == "Single-Select") {
      let qTrackReq = {
        Question_ID: quizProp.quesObject.Question_ID,
        code: `${AppConfig.code}`,
        timeTaken: timeTaken,
        level: authObj.level,
        isAnswered: userAns && userAns.ansObj && userAns.ansObj.ansId ? "Yes" : "No",
        uid: roleState?.candidateDashboardDetails ? roleState?.candidateDashboardDetails?.uid : "notregistered",
        email: roleState?.candidateDashboardDetails
          ? roleState?.candidateDashboardDetails?.candidate?.user?.email
          : "notregistered",
        userAns: userAns && userAns.ansObj && userAns.ansObj.answer ? [userAns.ansObj.answer] : [],
        score: "0",
        inviteId: roleState?.candidateDashboardDetails?.uid,
        eventType: eventType,
      };
      dispatch(quiz.getTrackingRequested(qTrackReq));
    } else {
      let qTrackReq = {
        Question_ID: quizProp.quesObject.Question_ID,
        code: `${AppConfig.code}`,
        timeTaken: timeTaken,
        level: authObj.level,
        isAnswered: userAns && userAns.ansObj && userAns.ansObj.ansId ? "Yes" : "No",
        uid: roleState?.candidateDashboardDetails ? roleState?.candidateDashboardDetails?.uid : "notregistered",
        email: roleState?.candidateDashboardDetails
          ? roleState?.candidateDashboardDetails?.candidate?.user?.email
          : "notregistered",
        userAns:
          userAns && userAns?.ansObj && userAns?.ansObj?.answer?.length != 0
            ? userAns?.ansObj?.answer.map((item) => item.answer)
            : [],
        score: "0",
        inviteId: roleState?.candidateDashboardDetails?.uid,
        eventType: eventType,
      };

      dispatch(quiz.getTrackingRequested(qTrackReq));

      // further func will come
    }
    setTotalTimetaken(totalTimetaken + timeTaken);
  };

  const handleOnClickNextBtn = (eventData) => {
    let val = eventData?.val;
    let e = eventData?.e;
    if (timerMin) {
      dispatch(quiz.setTimer(timerMin));
    }
    if (val == "NextSection") {
      dispatch(quiz.timerPlaying(false));
    }
    setPageChange(true);
    // console.log("timer", userAns);
    handleCalcQuizSummery();

    if (quesRes.length == qIndex) {
      var ansLn = quizState.selectedAnswers.length;
      var result = [];
      for (var i = 0; i < ansLn; i++) {
        var o = quizState.selectedAnswers[i];
        let answer = typeof o.answer == "string" ? [o.answer] : o.answer.map((item) => item.answer);
        if (o?.ansId && o?.questionID) {
          result.push({
            Question_ID: o.questionID,
            User_Answers: answer,
          });
        }
      }
      result = getUnique(result, "Question_ID");

      const tempLevelInfo = JSON.parse(quizState?.sessionReq);
      if (e?.target?.innerText == "Submit" && tempLevelInfo.level == 3 && tempLevelInfo.levelInfo == "presentation") {
        dispatch(quiz.submitAnswers(true));
        return;
      }
      let payload = {
        code: quizState.sessionByLevel,
        email: roleState?.candidateDashboardDetails
          ? roleState?.candidateDashboardDetails?.candidate?.user?.email
          : "notregistered",
        result: result,
        //inviteId:roleState?.candidateDashboardDetails?.uid
      };
      let payload1 = {
        data: payload,
        inviteId: roleState?.candidateDashboardDetails?.uid,
      };

      dispatch(quiz.submitTestResults(payload1));

      if (userDetails.role !== "Candidate") {
        history(generatePath("/invitecandidateview/:id", { id: roleState?.candidateDashboardDetails?.uid }));
      }
    }

    handleTrackingApiReq("next");

    if (quesRes.length == qIndex && quizState.level != AppConfig.LEVEL0) {
      // globalService.resetData();
    }
  };

  const onClickNext = (val, e) => {
    let eventData = {
      val,
      e,
    };
    setQusEvent("next");
    handleOnClickNextBtn(eventData);
  };

  const onClickPrev = () => {
    // if (navigator.onLine) {
    setQusEvent("prev");
    setPageChange(true);
    // let disableTracking = true;
    if (timerMin) {
      dispatch(quiz.setTimer(timerMin));
    }
    handleCalcQuizSummery();

    handleTrackingApiReq("prev");

    // if (quesRes && qIndex == 2) {
    //   history({
    //     pathname: `/QuizQuestions/${authObj.level}/${qIndex - 1}`,
    //     state: {
    //       timerReset: false,
    //     },
    //   });
    // } else if (quesRes && qIndex > 1) {
    //   history({
    //     pathname: `/QuizQuestions/${authObj.level}/${qIndex - 1}`,
    //     state: {
    //       timerReset: true,
    //     },
    //   });
    // }
    // }
    // else {
    //   setOpenSnackBar(true)
    // }
  };

  useEffect(() => {
    if (props.onJumpQus != "") {
      setQusEvent("jump");
      setPageChange(true);
      // let disableTracking = true;
      if (timerMin) {
        dispatch(quiz.setTimer(timerMin));
      }
      handleCalcQuizSummery();

      handleTrackingApiReq("jump");
    }
  }, [props.onJumpQus]);

  const userAnsFn = (ansObj) => {
    setUserAns({ ansObj });
  };

  const handleDialog = (val) => {
    setOpenPopup(val);
  };

  const handleTopicDialog = (event) => {
    setActionInfo(event);
    if (quizProp?.quesObject?.Topic == "Major") {
      let content =
        "You are about to make significant changes to your profile that will reset all your existing scores and account history. Do you wish to continue?";
      setAlertDialogContent(content);
    } else if (quizProp?.quesObject?.Topic == "Minor") {
      let content =
        "You are about to make changes to your profile that may impact your target score and timeline. Do you wish to continue?";
      setAlertDialogContent(content);
    }
    if (quizState.quizSelectionPopupDisable == false) {
      dispatch(quiz.quizSelectionPopupStatus(true));
    }
  };

  const onReviewAndSubmitClick = () => {
    // console.log('\n\n\n\n\n review and submit')
    setPageChange(false);
    handleCalcQuizSummery();
    dispatch(quiz.updateShowSummary(true));
    // handleTrackingApiReq('reviewAndSubmit');
  };

  const handleCloseSnackbar = () => {
    setShowSnackBar(false);
  };

  const handleClose = () => {
    setOpenSnackBar(false);
  };
  const timeValue = (time, minutes, seconds) => {
    if (time) {
      let sec = +seconds;
      let val = `${minutes}.${sec}`;
      setTimerMin(val);
    }
  };

  const handleOnWarning = (remainingTime) => {
    setShowSnackBar(true);
  };

  const handleOnTimeout = () => {
    props.isSubmitted(true);
    setTimeoutFlag(true);
    setTimeoutType("timeout");

    dispatch(
      quiz.updateQuizTimeoutFlag({
        type: "Timeout",
        msg: `The time allocated for the test is over. The ${
          authObj?.level == "L3" ? "Behavioral" : authObj?.level == "L2" ? "Technical" : ""
        } section is submitted automatically.`,
      })
    );
  };

  const onSubmitWhileTimout = () => {
    // if (navigator.onLine) {
    props.isSubmitted(true);
    submitAllAnswers();
    setShowTimeoutDialog(false);
    setShowSubmitDialog(false);
    // }
    // else {
    //   setOpenSnackBar(true)
    // }
  };

  function handleCalcQuizSummery() {
    let userAnswers = (userAns && userAns.ansObj) || { ansId: "", answer: "" };
    userAnswers["questionID"] = questionID && questionID;

    if (quizProp.quesObject.Question_Type == "Single-Select") {
      let payload = {
        level: routeParams.path,
        quesObject: quizProp.quesObject,
        userAns: userAns,
        questionNo: qIndex,
        questions: quesRes,
        categorySequence: quizState.categorySequence,
        quizState: quizState,
      };
      dispatch(quiz.calculateQuizSummery(payload));
      dispatch(quiz.updateAnswers(userAnswers));
      globalService.insertAnswer(userAnswers, quesRes.length);
    } else {
      let myUserObj = { ansObj: {} };
      let multiAnswer = counterRef?.current?.getMultiAnswer();
      myUserObj.ansObj = {
        answer: multiAnswer.storeMultiAns,
        ansId: multiAnswer.storeMultiAnsIds,
      };
      userAnsFn(myUserObj.ansObj);
      let multiSelection = myUserObj.ansObj || { ansId: "", answer: "" };
      multiSelection["questionID"] = questionID && questionID;
      let payload = {
        level: routeParams.path,
        quesObject: quizProp.quesObject,
        userAns: myUserObj,
        questionNo: qIndex,
        questions: quesRes,
        categorySequence: quizState.categorySequence,
        quizState: quizState,
      };
      dispatch(quiz.calculateQuizSummery(payload));

      dispatch(quiz.updateAnswers(multiSelection));
      globalService.insertAnswer(multiSelection, quesRes.length);

      // further func will come
    }
  }

  const handleViewProgress = () => {
    if (navigator.onLine) {
      setPageChange(false);
      if (timerMin) {
        dispatch(quiz.setTimer(timerMin));
      }
      handleCalcQuizSummery();
      // handleTrackingApiReq();
      dispatch(quiz.updateShowSummary(true));
    } else {
      setOpenSnackBar(true);
    }
  };

  const handleExit = () => {
    props.isSubmitted(true);
    handleTrackingApiReq("exit");
    submitAllAnswers();
    setExitopen(false);
  };

  const handleclick = () => {
    setExitopen(true);
  };

  const onClickContinue = (eventData) => {
    dispatch(quiz.quizSelectionPopupStatus(false));
    if (quizProp?.quesObject?.Topic == "Major") {
      dispatch(quiz.quizSelectionPopupDisable(true));
    }
    dispatch(quiz.quizSelectionTopic(quizProp?.quesObject?.Topic));
    counterRef.current.callBackOnClickAns(eventData);
  };

  const cancelConfirmDialog = () => {
    const tempLevelInfo = JSON.parse(quizState?.sessionReq);
    if (tempLevelInfo.level == 3 && tempLevelInfo.levelInfo == "presentation") {
      //dispatch(quiz.resetQuizSummery());
      //history.push("/");
      setShowSubmitDialog(false);
      dispatch(quiz.submitAnswers(false));
    } else {
      setShowSubmitDialog(false);
      dispatch(quiz.submitAnswers(false));
    }
  };

  useEffect(() => {
    if (props.onUnexpectedBehaviour === true) {
      props.isSubmitted(true);
      setTimeoutType("focusout");
      setPageChange(false);
      if (timerMin) {
        dispatch(quiz.setTimer(timerMin));
      }
      handleCalcQuizSummery();
      handleTrackingApiReq("focustimeout");

      dispatch(
        quiz.updateQuizTimeoutFlag({
          type: "Focusout",
          msg: `You moved out of the application for more than the permitted time. The ${
            authObj?.level == "L3" ? "Behavioral" : authObj?.level == "L2" ? "Technical" : ""
          } section is submitted automatically.`,
        })
      );

      dispatch(quiz.timerPlaying(false));
      setExitopen(false);

      setTimeout(() => {
        setShowTimeoutDialog(true);
        setTimeoutFlag(false);
      }, 1000);
    }
  }, [props.onUnexpectedBehaviour]);

  // On App Focus Out and Focus In
  useEffect(() => {
    if (!props.onAppFocusOut) {
      setPageChange(false);
      handleTrackingApiReq("focusout");
    } else {
      setPageChange(false);
      handleTrackingApiReq("focusin");
    }
  }, [props.onAppFocusOut]);

  useEffect(() => {
    if (authObj.logoDetails?.logo == "") {
      setDomainDetails({ logo: localStorage.getItem("logo"), displayName: "" });
    }
  }, [localStorage.getItem("logo")]);

  return (
    <div>
      <div className={classes.root}>
        <div className={`${classes.item1}   ${classes.center} `}>
          <Container maxWidth="xl" className={classes.container}>
            <div className={classes.header}>
              <Grid container className="Home-hero">
                <Grid item xs={4} onClick={handleclick} style={{ cursor: "pointer" }}>
                  {/* <img src={BayoneLogo} alt={"Bayone Logo"} /> */}
                  <div>
                    {domainDetails && domainDetails?.logo !== "" ? (
                      <>
                        <img
                          height="33"
                          width="157"
                          src={`${logoUrl}${domainDetails?.logo}`}
                          alt={domainDetails?.displayName}
                        />
                        <br />
                        <img height="17" width="157" src={`${logoUrl}powered.svg`} alt="powered" />
                      </>
                    ) : (
                      <a href="https://techscore.ai" target="_self" rel="noreferrer">
                        <img height="50" width="157" src={`${logoUrl}techscore.svg`} alt="TechScore" />
                      </a>
                    )}
                  </div>
                  {isNotMobileCam && (
                    <div className={classes.webCam} onClick={(e) => e.stopPropagation()}>
                      <SessionTracking
                        inviteId={roleState?.candidateDashboardDetails?.uid}
                        sessionCode={quizState.sessionByLevel}
                      />
                      {/* <ReactWebcam /> */}
                    </div>
                  )}
                </Grid>
                <Grid item xs={4}>
                  <Timer
                    prevQuestion={prevQuestion}
                    currentQuestion={currentQuestion}
                    timeValue={timeValue}
                    location={locationRef}
                    handleOnWarning={() => handleOnWarning()}
                    handleOnTimeout={() => handleOnTimeout()}
                  ></Timer>
                </Grid>
                <Grid item xs={4} className={classes.exitBtn}>
                  <Link
                    onClick={handleclick}
                    data-rt-div-quizexit="quizexit"
                    className={classes.linkUnderline}
                    style={{ cursor: "pointer" }}
                  >
                    <Typography
                      // component={Text}
                      variant2="link2"
                      align="right"
                      className={classes.exit}
                      style={theme.typography.link2}
                    >
                      Exit
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>

        <Container maxWidth="xl" className={`${classes.container} ${classes.containerCls}`}>
          <Grid container>
            {isNotMobile && <Grid item xs={3}></Grid>}
            <Grid item xs={isNotMobile ? 6 : 12}>
              <div className={classes.item3}>
                <div
                  className={`${classes.pageInfo} ${classes.center}`}
                  style={{
                    border: categoryTheme.hasOwnProperty(category)
                      ? `1px solid ${categoryTheme[category].bgColor}`
                      : "",
                  }}
                >
                  <Typography
                    // component={Text}
                    variant2="b2Medium"
                    align="center"
                    style={{
                      color: categoryTheme.hasOwnProperty(category) ? `${categoryTheme[category].bgColor}` : "",
                      margin: "0px 10px",
                    }}
                  >
                    {category === "Presentation" ? "Behavioral" : category} Section
                  </Typography>
                </div>
              </div>
              {isMobileCam && (
                <div className={classes.webCamMobile} onClick={(e) => e.stopPropagation()}>
                  <SessionTracking
                    inviteId={roleState?.candidateDashboardDetails?.uid}
                    sessionCode={quizState.sessionByLevel}
                  />
                  {/* <ReactWebcam /> */}
                </div>
              )}
              <Grid container className={classes.progressArea} gap={1} alignItems="center">
                <Typography
                  // component={Text}
                  variant2="b2Medium"
                  className={`${classes.progess}`}
                  align="left"
                >
                  Question {`${value}`}
                </Typography>
                {quizProp && quizProp.quesObject && quizProp.quesObject.Hint && (
                  <span style={{ marginTop: "3px" }}>
                    <img
                      alt="hintImg"
                      src={`${ImagebaseUrl}assets/questionsInformationIcon.svg`}
                      className={classes.hintIconCls}
                      onClick={() => handleDialog(true)}
                    />
                  </span>
                )}
              </Grid>
            </Grid>
            {isNotMobile && <Grid item xs={3}></Grid>}
          </Grid>

          <Grid container>
            {isNotMobile && <Grid item xs={3}></Grid>}
            <Grid item xs={isNotMobile ? 6 : 12}>
              <div className={classes.item4}>
                <div className={classes.question}>
                  <div className={classes.questionArea}>
                    <div className={classes.qText}>
                      <Typography
                        // component={Text}
                        className={classes.ques_st}
                        variant2="b1Medium"
                        align="left"
                        id="no-copy"
                      >
                        {/* {question}{" "}  */}
                        {/* <div dangerouslySetInnerHTML={{ __html: transformToHTML(question) }}></div> */}
                        <MathJaxContent content={question} />
                        {/* {quizProp && quizProp.quesObject && quizProp.quesObject.Hint && (
                          <span>
                            <img
                              alt="hintImg"
                              src={`${ImagebaseUrl}assets/questionsInformationIcon.svg`}
                              className={classes.hintIconCls}
                              onClick={() => handleDialog(true)}
                            />
                          </span>
                        )} */}
                      </Typography>
                    </div>
                  </div>
                </div>

                <div className={classes.answers}>
                  <Answers
                    answers={answers}
                    userAnsFn={userAnsFn}
                    quesObject={quizProp.quesObject}
                    ref={counterRef}
                    qIndex={qIndex}
                    handleTopicDialog={handleTopicDialog}
                  ></Answers>
                </div>
              </div>
            </Grid>
            {isNotMobile && <Grid item xs={3}></Grid>}
          </Grid>
        </Container>
        <div className={`${classes.item5} ${classes.center} ${classes.stickToBottom}`}>
          <Container maxWidth="xl" className={classes.container}>
            <Grid container>
              {isNotMobile && <Grid item xs={3}></Grid>}
              <Grid item xs={isNotMobile ? 6 : 12}>
                <Grid
                  container
                  justifyContent={
                    qIndex && qIndex > 1
                      ? "space-between"
                      : quizState.level == AppConfig.LEVEL0
                      ? "flex-end"
                      : "space-between"
                  }
                  alignItems="center"
                  style={{ marginBottom: "20px" }}
                >
                  {(quizState.level == AppConfig.LEVEL1 ||
                    quizState.level == AppConfig.LEVEL2 ||
                    quizState.levelIndicator == AppConfig.LEVEL3_PRESENTATION) &&
                    quesRes.length != qIndex && (
                      <>
                        {isFromQuizSummery == true ? (
                          <Button
                            color="btnPrimary1"
                            text="View Summary"
                            width={isNotMobile ? "202px" : "172px"}
                            onClick={trackingLoading ? null : handleViewProgress}
                            data-rt-div-viewsummarybtn="viewsummarybtn"
                            disabled={trackingLoading}
                          ></Button>
                        ) : (
                          <Link data-rt-div-viewsummary="viewsummary" className={classes.linkUnderline}>
                            <Typography
                              // component={Text}
                              variant2={isNotMobile ? "link1" : "link2"}
                              align="center"
                              className={`${classes.previous} ${classes.previousLink} ${classes.previousLink1}`}
                              onClick={trackingLoading ? null : handleViewProgress}
                            >
                              View Summary
                            </Typography>
                          </Link>
                        )}
                      </>
                    )}

                  {quizState.level == AppConfig.LEVEL0 && (
                    <>
                      {qIndex && qIndex > 1 ? (
                        <Link data-rt-div-previousL0="previousL0" className={classes.linkUnderline}>
                          <Typography
                            // component={Text}
                            variant2={isNotMobile ? "link1" : "link2"}
                            align="left"
                            className={`${classes.previous} ${classes.previousLink} ${classes.previousLink1}`}
                            // onClick={() => onClickPrev()}
                            onClick={trackingLoading ? null : onClickPrev}
                            disabled={trackingLoading}
                          >
                            Previous
                          </Typography>
                        </Link>
                      ) : quizState.level == AppConfig.LEVEL3 ? (
                        <Typography />
                      ) : null}
                    </>
                  )}
                  {(quizState.level == AppConfig.LEVEL1 ||
                    quizState.level == AppConfig.LEVEL2 ||
                    quizState.levelIndicator == AppConfig.LEVEL3_PRESENTATION) &&
                    quesRes.length == qIndex && (
                      <>
                        {qIndex && qIndex > 1 ? (
                          <Link data-rt-div-previousbtn="previousbtn" className={classes.linkUnderline}>
                            <Typography
                              // component={Text}
                              variant2={isNotMobile ? "link1" : "link2"}
                              align="left"
                              className={`${classes.previous} ${classes.previousLink} ${classes.previousLink1}`}
                              // onClick={() => onClickPrev()}
                              onClick={trackingLoading ? null : onClickPrev}
                              disabled={trackingLoading}
                            >
                              Previous
                            </Typography>
                          </Link>
                        ) : quizState.level == AppConfig.LEVEL3 ? (
                          <Typography />
                        ) : null}
                      </>
                    )}

                  <Grid
                    justifyContent={
                      qIndex && qIndex > 1
                        ? "space-between"
                        : // : quizState.level == AppConfig.LEVEL0
                          // ? "flex-end"
                          "space-between"
                    }
                    style={{ display: "flex" }}
                    alignItems="center"
                  >
                    {(quizState.level == AppConfig.LEVEL1 ||
                      quizState.level == AppConfig.LEVEL2 ||
                      quizState.levelIndicator == AppConfig.LEVEL3_PRESENTATION) &&
                      quesRes.length != qIndex && (
                        <>
                          {qIndex && qIndex > 1 ? (
                            <Link data-rt-div-previousl3="previousl3" className={classes.linkUnderline}>
                              <Typography
                                // component={Text}
                                variant2={isNotMobile ? "link1" : "link2"}
                                align="left"
                                className={`${classes.previous} ${classes.previousLink} ${classes.previousLink1}`}
                                // onClick={() => onClickPrev()}
                                onClick={trackingLoading ? null : onClickPrev}
                                disabled={trackingLoading}
                              >
                                Previous
                              </Typography>
                            </Link>
                          ) : quizState.level == AppConfig.LEVEL3 ? (
                            <Typography />
                          ) : null}
                        </>
                      )}

                    {!(
                      (quizState.level == AppConfig.LEVEL1 ||
                        quizState.level == AppConfig.LEVEL2 ||
                        quizState.levelIndicator == AppConfig.LEVEL3_PRESENTATION) &&
                      quesRes.length == qIndex
                    ) && (
                      <>
                        {isFromQuizSummery == true ? (
                          <Link data-rt-div-next="nextlink" className={classes.linkUnderline}>
                            <Typography
                              //  component={Text}
                              variant2={isNotMobile ? "link1" : "link2"}
                              align="center"
                              className={`${classes.previous} ${classes.previousLink} ${classes.previousLink1}`}
                              // onClick={() =>
                              //   onClickNext(
                              //     nextQuestion && nextQuestion.Category != category ? "NextSection" : "Next" || "Next"
                              //   )
                              // }
                              onClick={
                                trackingLoading
                                  ? null
                                  : () =>
                                      onClickNext(
                                        nextQuestion && nextQuestion.Category != category
                                          ? "NextSection"
                                          : "Next" || "Next"
                                      )
                              }
                              disabled={trackingLoading}
                            >
                              {nextQuestion && nextQuestion.Category != category ? "Next Section" : "Next" || "Next"}
                            </Typography>
                          </Link>
                        ) : (
                          <Button
                            disabled={
                              (routeParams.path == AppConfig.LEVEL0 &&
                                (!userAns ||
                                  !userAns.ansObj ||
                                  !userAns.ansObj.ansId ||
                                  !userAns.ansObj.answer ||
                                  userAns.ansObj.ansId.length == 0 ||
                                  userAns.ansObj.answer.length == 0)) ||
                              trackingLoading
                                ? true
                                : false
                            }
                            color="btnPrimary1"
                            text={quesRes.length == qIndex ? "Submit" : "Next" || "Next"}
                            width={isNotMobile ? "202px" : "160px"}
                            // onClick={(e) =>
                            //   onClickNext(
                            //     nextQuestion && nextQuestion.Category != category ? "NextSection" : "Next" || "Next",
                            //     e
                            //   )
                            // }
                            onClick={
                              trackingLoading
                                ? null
                                : (e) =>
                                    onClickNext(
                                      nextQuestion && nextQuestion.Category != category
                                        ? "NextSection"
                                        : "Next" || "Next",
                                      e
                                    )
                            }
                            data-rt-div-nextbtn="nextbtn"
                          ></Button>
                        )}
                      </>
                    )}

                    {(quizState.level == AppConfig.LEVEL1 ||
                      quizState.level == AppConfig.LEVEL2 ||
                      quizState.levelIndicator == AppConfig.LEVEL3_PRESENTATION) &&
                      quesRes.length == qIndex && (
                        <Button
                          color="btnPrimary1"
                          text="Review & Submit"
                          width={isNotMobile ? "202px" : "172px"}
                          disabled={
                            (routeParams.path == AppConfig.LEVEL0 &&
                              (!userAns ||
                                !userAns.ansObj ||
                                !userAns.ansObj.ansId ||
                                !userAns.ansObj.answer ||
                                userAns.ansObj.ansId.length == 0 ||
                                userAns.ansObj.answer.length == 0)) ||
                            trackingLoading
                              ? true
                              : false
                          }
                          // onClick={() => onReviewAndSubmitClick()}
                          onClick={trackingLoading ? null : onReviewAndSubmitClick}
                          data-rt-div-Reviewsubmit="Reviewsubmit"
                        ></Button>
                      )}
                  </Grid>
                </Grid>
              </Grid>
              {isNotMobile && <Grid item xs={3}></Grid>}
            </Grid>
          </Container>
        </div>
      </div>

      <Popup openPopup={openPopup} setOpenPopup={setOpenPopup}>
        <LOneExampleContent
          title="Question Hint"
          hint={quizProp.quesObject.Hint}
          showButtonArea={false}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          id="no-copy"
        ></LOneExampleContent>
      </Popup>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={isShowSnackBar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="Your session is about to expire, hurry!"
        action={
          <React.Fragment>
            <ButtonMaterial color="secondary" size="small" onClick={handleCloseSnackbar}></ButtonMaterial>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <TimeoutDialog
        open={isShowTimeoutDialog}
        onClose={() => setShowTimeoutDialog(false)}
        text={timeoutMessage}
        heading={timeoutType == "focusout" ? "Timed Out!!" : timeoutType == "timeout" ? "Timeout!!" : ""}
        onSubmit={() => onSubmitWhileTimout()}
        TimeClose={() => setOpenSnackBar(true)}
      ></TimeoutDialog>

      <SubmitConfirmationDialog
        open={isShowSubmitDialog}
        text={submitDialogContent}
        action=""
        onSubmit={submitAllAnswers}
        onClose={cancelConfirmDialog}
        TimeClose={() => setOpenSnackBar(true)}
      ></SubmitConfirmationDialog>

      <SubmitConfirmationDialog
        open={quizState.quizSelectionPopupStatus}
        text={alertDialogContent}
        action={actionInfo}
        succesBtnLabel="Yes, continue"
        onSubmit={(event) => onClickContinue(actionInfo)}
        onClose={() => dispatch(quiz.quizSelectionPopupStatus(false))}
        TimeClose={() => setOpenSnackBar(true)}
      ></SubmitConfirmationDialog>
      <ExitDialog
        open={exitopen}
        OnExit={handleExit}
        onClose={() => setExitopen(false)}
        category={category}
        TimeClose={() => setOpenSnackBar(true)}
      ></ExitDialog>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={handleClose}
        message={"Please check your internet connection and try again"}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}
export default QuizContainer;
